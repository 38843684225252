import { gql, useMutation } from "@apollo/client";
import { Box, TextField } from "@mui/material";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import CliniSpanLogo from "../../assets/logo.png";
import { useUserContext } from "../../providers/UserContext";

const LOGIN_MUTATION = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(loginInput: { email: $email, password: $password }) {
      _id
      firstName
      email
      token
      role
    }
  }
`;

const CREATE_USER_MUTATION = gql`
  mutation createUserMutation(
    $email: String!
    $password: String!
    $confirm: String!
  ) {
    createUser(email: $email, password: $password, confirm: $confirm) {
      _id
      email
      token
      role
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  width: 500px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Body = styled.div`
  // background-image: url("https://media.istockphoto.com/id/614211848/photo/united-through-their-diversity.jpg?s=612x612&w=0&k=20&c=RZtAIdEyA71ji9dse0W0CmChr6siM9MrPmWane2UZII=");
  // background-repeat: no-repeat;
  // background-size: cover;
`;

const AdminLogin = () => {
  const { setUser } = useUserContext();
  // const { is_authenticated, user, requestSignIn } = useRownd();

  // useEffect(() => {
  //   if (!is_authenticated) {
  //     requestSignIn();
  //   }
  // }, [is_authenticated]);

  // useEffect(() => {
  //   if (user) {
  //     setUser(user);
  //     // localStorage.setItem("token", user.token);
  //     navigate("/partner/study-upload");
  //   }
  // }, [user]);

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Partner LOGIN";
  }, []);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  const [loginUser] = useMutation(LOGIN_MUTATION, {
    variables: {
      email,
      password,
    },
  });
  const [createUser] = useMutation(CREATE_USER_MUTATION, {
    variables: {
      email: "",
      password: "",
      confirm: "",
    },
  });

  const onLogin = async () => {
    try {
      const user = await loginUser().catch((err) => {
        console.error("err", err);
        setLoginError(true);
        toast.error("Invalid Credentials", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      });

      const token = user?.data?.loginUser?.token;

      if (token) {
        setUser(user?.data?.loginUser);
        localStorage.setItem("token", token);
        navigate("/partner/study-upload");
      }
    } catch (error) {
      console.error("error", error);
      setLoginError(true);
      alert("Invalid Credentials");
    }
  };
  return (
    <div className="App">
      <ToastContainer />
      <header className="App-header">
        {/* <h1>DHI/CHI Portal</h1> */}
        <Image src={CliniSpanLogo} alt="Logo" />
        {/* <Image src={require("../../assets/logo.png")} /> */}
      </header>
      <Body className="App-body" style={{ backgroundColor: "#fff", margin: 0 }}>
        <Box>
          <Card>
            <Container>
              <TextField
                style={{ marginTop: 20, marginBottom: 20 }}
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <TextField
                margin="10px"
                label="Password"
                variant="outlined"
                value={password}
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <Button
                onClick={onLogin}
                style={{ marginTop: 20, marginBottom: 10 }}
              >
                Login
              </Button>
              {/* <div>
            <Link onClick={() => navigate("/forgotpassword")}>
              Forgot Password? <a> Click Here</a>
            </Link>
          </div> */}
            </Container>
          </Card>
        </Box>
      </Body>
    </div>
  );
};

export default AdminLogin;
