import React from "react";
import ReactDOM from "react-dom/client";
// import { initializeApp } from "firebase/app";
import "./index.css";
import App from "./App";
import "antd/dist/antd.css";

// import "./firebase.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";

// import * as firebase from "firebase/app";
import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDeAQf0VyMzaFAcuMY-EInjxb-J4egH2mI",
  authDomain: "social-app-5f46e.firebaseapp.com",
  projectId: "social-app-5f46e",
  storageBucket: "social-app-5f46e.appspot.com",
  messagingSenderId: "1042452624891",
  appId: "1:1042452624891:web:137826d3faa2fd550d475e",
};

const app = firebase.initializeApp(firebaseConfig);

// console.log("app", app);

library.add(fab, faCheckSquare, faCoffee);

const root = ReactDOM.createRoot(document.getElementById("root"));
function startApp() {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
