import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CliniSpanLogo from "../assets/logo.png";
import { AdminMenu } from "../components/Menus";

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 200px;
  width: 90%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  display: block;
  margin: 10px auto;
  align-self: center;
  width: 200px;
  height: 30px;
`;
const Button = styled.button`
  background-color: blue;
  border-radius: 10px;
  height: 50px;
  width: 100px;
  margin-top: 50px;
`;
const Text = styled.p`
  color: #fff;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Studies = () => {
  const navigate = useNavigate();
  return (
    <div className="App">
      <header className="App-header">
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            backgroundColor: "#fff",
          }}
        >
          <h1 style={{ color: "#000" }}>Administrator Portal</h1>
          <Image src={CliniSpanLogo} alt="Logo" />
        </div>
      </header>
      <div className="App-body outerRow">
        <div
          style={{
            display: "flex",
            backgroundColor: "#009EC5",
            flexDirection: "column",
            alignSelf: "baseline",
            height: "700px",
            justifyContent: "space-evenly",
            alignItems: "center",
            flex: 1,
          }}
        >
          <AdminMenu />
        </div>
        <div className="container">
          <Container
            background={"orange"}
            onClick={() => navigate("/performance-dashboard")}
          >
            <Text>Performance Dashboard</Text>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Studies;
