import { EditorState } from "draft-js";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Editor } from "react-draft-wysiwyg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PartnerHeader } from "../../components/Menus";

const Text = styled.p`
  color: #000;
  text-align: left;
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

// Dummy data
const studyData = [
  {
    name: "Asthma Trial",
    nctNumber: "12341231",
    participants: 10,
    active: true,
  },
  {
    name: "COVID Trial",
    nctNumber: "12341231",
    participants: 20,
    active: false,
  },
  {
    name: "Diabetes Trial",
    nctNumber: "12341231",
    participants: 30,
    active: true,
  },
];

function Message() {
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [content, setConvertedContent] = useState(null);
  const [name, setName] = useState("Test");
  const [influencers, setInfluencers] = useState([]);
  const [platform, setPlatform] = useState("Facebook");
  const [scheduledDate, setScheduledDate] = useState(null);
  return (
    <div className="App">
      <PartnerHeader />
      <div className="App-body outerRow">
        <div
          style={{
            display: "flex",
            alignSelf: "flex-start",
            width: "inherit",
            flex: 3,
            padding: 20,
            borderTop: "1px solid black",
            overflowY: "scroll",
            maxHeight: "700px",
          }}
        >
          <div className="flexColumn">
            <Text style={{ alignSelf: "center", fontWeight: "bold" }}>
              Outreach
            </Text>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ border: "1px solid black", display: "flex" }}>
                <Text className="centerSelf">
                  To: NCT{studyData[0].nctNumber} Interest
                </Text>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 10,
                  border: "2px solid lightblue",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/partner/outreach/study")}
              >
                <Text>{studyData[0].name}</Text>
                <Text>NCT{studyData[0].nctNumber}</Text>
              </div>
              <Text style={{ color: "red", alignSelf: "center" }}>
                *Must Use IRB Approved Language and Graphics
              </Text>
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                hashtag={{
                  separator: " ",
                  trigger: "#",
                }}
                mention={{
                  separator: " ",
                  trigger: "@",
                  suggestions: [
                    { text: "JavaScript", value: "javascript", url: "js" },
                    { text: "Golang", value: "golang", url: "go" },
                  ],
                }}
              ></Editor>
              <Button
                onClick={async () => {
                  //   const campaign = await createCampaign();
                  //   if (campaign) navigate("/managecampaigns");
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;
