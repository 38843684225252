import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CliniSpanLogo from "../../assets/logo.png";

const CREATE_USER_MUTATION = gql`
  mutation createUserMutation(
    $email: String!
    $password: String!
    $confirm: String!
    $firstName: String!
    $lastName: String!
    $zipCode: String!
    $phoneNumber: String!
  ) {
    createUser(
      userInput: {
        email: $email
        password: $password
        confirm: $confirm
        firstName: $firstName
        lastName: $lastName
        zipCode: $zipCode
        phoneNumber: $phoneNumber
      }
    ) {
      _id
      email
      token
      role
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: grey;
  width: 900px;
  margin-top: 50px;
`;

const Body = styled.div`
  background-image: url(../../assets/patientBackground.png);
  background-repeat: no-repeat;
  background-size: cover;
`;

const Banner = styled.div`
  background-color: white;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  display: block;
  margin: 10px auto;
  align-self: center;
  width: 300px;
  height: 30px;
`;
const Button = styled.button`
  background-color: blue;
  border-radius: 10px;
  height: 50px;
  width: 100px;
  margin: 25px 0px;
`;
const Text = styled.p``;

const Signup = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Patient Signup";
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [platform, setPlatform] = useState();

  const [createUser] = useMutation(CREATE_USER_MUTATION, {
    variables: {
      email,
      password,
      confirm,
      firstName,
      lastName,
      zipCode,
      phoneNumber,
    },
  });

  const onSignup = async () => {
    const user = await createUser();
    const token = user?.data?.createUser?.token;

    if (token) {
      // save token
      localStorage.setItem("token", token);
      navigate("/patientdashboard");
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        {/* <h1>DHI/CHI Portal</h1> */}
        <Image src={CliniSpanLogo} alt="Logo" />
        {/* <Image src={require("../assets/logo.png")} /> */}
      </header>
      <Body className="App-body">
        <Banner>
          <Text style={{ color: "lightblue", margin: "0px auto" }}>
            Patient Signup
          </Text>
        </Banner>
        <Container>
          <InnerContainer>
            <label>First Name</label>
            <Input
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <label>Last Name</label>
            <Input
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <label>Username</label>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <label>Create Password</label>
            <Input
              value={password}
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <label>Re-enter Password</label>
            <Input
              value={confirm}
              type="password"
              onChange={(e) => {
                setConfirm(e.target.value);
              }}
            />
            <label>Zip Code</label>
            <Input
              value={zipCode}
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
            />
            <label>Phone Number</label>
            <Input
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
            <label>
              {"What Social Media Platforms do you use? (Check all that apply)"}
            </label>
            <div>
              <Input
                type="checkbox"
                id="facebook"
                name="facebook"
                value="facebook"
                className="inline"
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
              <label htmlFor="facebook"> Facebook</label>
              <br></br>
              <Input
                type="checkbox"
                id="Instagram"
                name="Instagram"
                value="Instagram"
                className="inline"
              />
              <label htmlFor="Instagram"> Instagram</label>
              <br></br>
              <Input
                type="checkbox"
                id="Twitter"
                name="Twitter"
                value="Twitter"
                style={{ display: "inline-block", marginRight: 33 }}
              />
              <label htmlFor="Twitter"> Twitter</label>
              <br></br>
              <Input
                type="checkbox"
                id="Tiktok"
                name="Tiktok"
                value="Tiktok"
                style={{ display: "inline-block", marginRight: 38 }}
              />
              <label htmlFor="Tiktok"> Tiktok</label>
              <br></br>
            </div>
            <Button onClick={onSignup}>Signup</Button>
          </InnerContainer>
        </Container>
      </Body>
    </div>
  );
};

export default Signup;
