export const Roles = {
  Constituent: "Constituent",
  Partner: "Partner",
  Dhi: "DHI",
  Admin: "Admin",
};

// CliniSpan Health (Dev)
// export const patientGroupId = 'group_vurhxingc1hds6yeoawdidsa';
// export const dhiGroupId = 'group_vlntntsqsmsmol2eyxxtaygk';
// export const partnerGroupId = 'group_a7fmm31f36j2a47w9igi0z8a';
// export const adminGroupId = 'group_y5zoijetoj6rwa2eno7zk2zy';

// CliniSpan Health (Staging)
export const patientGroupId =
  process.env.NODE_ENV === "production"
    ? "group_zngx92lwenehzobm8t5llu66"
    : "group_bfbgj1wr8bhwkbx8fwy7xori";
export const dhiGroupId =
  process.env.NODE_ENV === "production"
    ? "group_rbp9z7si1jtgq9a48hg74gi9"
    : "group_fbdbfhj5wqen7ezo4no8uiqf";
export const partnerGroupId =
  process.env.NODE_ENV === "production"
    ? "group_tk18gf3di458qq9tkhkii8y0"
    : "group_nho39bynsvi9l2l806it0sol";
export const adminGroupId =
  process.env.NODE_ENV === "production"
    ? "group_ezvds596c1lzprrzvdfluy93"
    : "group_dwgr7lz63jj03b37nf11a6va";
