import { gql, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useUserContext } from "../../providers/UserContext";

const FetchCampaign = gql`
  query GetCampaign($id: String!) {
    getCampaign(id: $id) {
      id: _id
      title
      status
      influencer
      platforms
      postingInstructions
      materials {
        url
      }
      campaignId {
        caption
        name
        startDate
        endDate
        therapyAreas {
          name
          active
        }
        description
      }
      studyId {
        title
        condition
      }
    }
  }
`;

const Container = styled.div`
  background-color: white;
  height: 600px;
  width: 80%;
  border-radius: 10px;
  padding: 10px;
`;
const InnerContainer = styled.div`
  justify-content: center;
  background-color: lightblue;
  margin: 10px;
  height: 500px;
  cursor: pointer;
  overflow: scroll;
`;
const Input = styled.input`
  display: block;
  margin: 10px auto;
  align-self: center;
  width: 200px;
  height: 30px;
`;
const Button = styled.button`
  background-color: blue;
  border-radius: 10px;
  height: 50px;
  width: 100px;
  margin-top: 50px;
`;
const Text = styled.p`
  align-self: center;
  color: white;
  text-decoration: underline;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const CampaignDetails = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { id } = useParams();
  const location = useLocation();
  const passedCampaign = location.state.campaign;
  const [campaign, setCampaign] = useState(passedCampaign || null);
  const { data, loading, error } = useQuery(FetchCampaign, {
    variables: { id: id },
    skip: !user || campaign,
  });
  
  useEffect(() => {
    document.title = "DHI Campaign Details";
  }, []);
  useEffect(() => {
    if (data) {
      setCampaign(data.getCampaign);
    }
  }, [data]);

  return (
    <div className="App">
      <div
        className="App-body"
        style={{
          backgroundColor: "lightgrey",
          color: "#000",
          margin: "0px",
        }}
      >
        <Container>
          <Text style={{ color: "#000", fontWeight: "bold" }}>
            Eczema User Acquistion Campaign
          </Text>
          <InnerContainer onClick={() => console.log("clicked")}>
            <Text> Campaign Statistics </Text>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Text> Campaign Start Date</Text>
                <p> {campaign?.campaignId?.startDate}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Text> Campaign End Date</Text>
                <p> {campaign?.campaignId?.endDate}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Text>Link Clicks</Text>
                <p>150</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Text>User Signups</Text>
                <p>31</p>
              </div>
            </div>
            <Text>Performace Metrics </Text>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Text>Instagram</Text>
                <FontAwesomeIcon
                  icon={["fab", "instagram"]}
                  style={{
                    alignSelf: "center",
                    fontSize: "45px",
                    color: "#fff",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text className="text">Likes: 10</Text>
                  <Text className="text">Comments: 20</Text>
                  <Text className="text">Shares:</Text>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Text>Facebook</Text>
                <FontAwesomeIcon
                  icon={["fab", "facebook"]}
                  style={{
                    alignSelf: "center",
                    fontSize: "45px",
                    color: "#fff",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text className="text">Likes: 10</Text>
                  <Text className="text">Comments: 20</Text>
                  <Text className="text">Shares:</Text>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Text>Tiktok</Text>
                <FontAwesomeIcon
                  icon={["fab", "tiktok"]}
                  style={{
                    alignSelf: "center",
                    fontSize: "45px",
                    color: "#fff",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text className="text">Likes: 10</Text>
                  <Text className="text">Comments: 20</Text>
                  <Text className="text">Shares:</Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <Text>Twitter</Text>

                <FontAwesomeIcon
                  icon={["fab", "twitter"]}
                  style={{
                    alignSelf: "center",
                    fontSize: "45px",
                    color: "#fff",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text className="text">Likes: 10</Text>
                  <Text className="text">Comments: 20</Text>
                  <Text className="text">Shares:</Text>
                </div>
              </div>
            </div>
            {/* <Text> Performance Metrics </Text> */}
          </InnerContainer>
        </Container>
      </div>
    </div>
  );
};

export default CampaignDetails;
