import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
// import { Editor } from "@tinymce/tinymce-react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Card } from "antd";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Button, Form } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserContext } from "../providers/UserContext";
import CustomStepper from "./CustomStepper";

import MediaLibrary from "../components/MediaModal";
import InfluencerPortfolio from "../components/Portfolio";
import { formatNumberNoDecimal } from "../utilities/format";

const INFLUENCERS_QUERY = gql`
  query Influencers {
    influencers {
      _id
      firstName
      lastName
      platforms
      avatar
    }
  }
`;

const GET_INFLUENCER_RECOMMENDATIONS = gql`
  query InfluencerRecommendations(
    $sex: [String]!
    $ageRange: [String]!
    $raceEthnicity: [String]!
    $platforms: [Platform]!
  ) {
    influencerRecommendations(
      sex: $sex
      ageRange: $ageRange
      raceEthnicity: $raceEthnicity
      platforms: $platforms
    ) {
      _id
      firstName
      lastName
      platforms
      avatar
      followers
      therapyAreas: interestedConditions {
        name
      }
      location: city
    }
  }
`;

const CAMPAIGN_CREATION_REQUEST = gql`
  mutation CreateCampaignRequest(
    $name: String!
    $description: String
    $postingInstructions: String!
    $sex: [String]!
    $ageRange: [String]!
    $raceEthnicity: [String]!
    $platforms: [Platform]!
    $startDate: String
    $endDate: String
    $IRBApprovedFiles: [FileInput]
    $campaignId: String
    $influencers: [InfluencerInput]
  ) {
    createCampaignRequest(
      campaignRequestInput: {
        name: $name
        description: $description
        postingInstructions: $postingInstructions
        platforms: $platforms
        startDate: $startDate
        endDate: $endDate
        IRBApprovedFiles: $IRBApprovedFiles
        campaignId: $campaignId
        influencers: $influencers
      }
      targetAudienceInput: {
        sex: $sex
        ageRange: $ageRange
        raceEthnicity: $raceEthnicity
      }
    ) {
      _id
      name
      description
      postingInstructions
    }
  }
`;

const CAMPAIGNS_QUERY = gql`
  query Campaigns($id: String!) {
    campaigns(id: $id) {
      name
      scheduledDate
      startDate
      endDate
      platforms
      active
      influencers {
        firstName
        lastName
      }
      postingInstructions
      description
      targetAudience {
        sex
        ageRange
        raceEthnicity
      }
    }
  }
`;

const CREATE_CAMPAGIN_MUTATION = gql`
  mutation createCampaign(
    $name: String!
    $influencers: [Influencer!]
    $platform: Platform!
    $scheduledDate: String!
    $content: String!
  ) {
    createCampaign(
      campaignInput: {
        name: $name
        influencers: $influencers
        platform: $platform
        scheduledDate: $scheduledDate
        content: $content
      }
    ) {
      name
    }
  }
`;

const APPROVE_CAMPAIGN_MUTATION = gql`
  mutation approveCampaign($id: String!) {
    approveCampaign(id: $id) {
      name
    }
  }
`;

const APPROVE_CAMPAIGN_REQUEST_MUTATION = gql`
  mutation approveCampaignRequest(
    $name: String!
    $description: String
    $postingInstructions: String!
    $sex: [String]!
    $ageRange: [String]!
    $raceEthnicity: [String]!
    $platforms: [Platform]!
    $startDate: String
    $endDate: String
    $IRBApprovedFiles: [FileInput]
    $campaignId: String
    $caption: String
  ) {
    approveCampaignRequest(
      campaignRequestInput: {
        name: $name
        description: $description
        postingInstructions: $postingInstructions
        platforms: $platforms
        startDate: $startDate
        endDate: $endDate
        IRBApprovedFiles: $IRBApprovedFiles
        campaignId: $campaignId
        caption: $caption
      }
      targetAudienceInput: {
        sex: $sex
        ageRange: $ageRange
        raceEthnicity: $raceEthnicity
      }
    ) {
      _id
      name
      description
      postingInstructions
    }
  }
`;

const REJECT_CAMPAIGN_REQUEST_MUTATION = gql`
  mutation rejectCampaignRequest(
    $name: String!
    $description: String
    $postingInstructions: String!
    $sex: [String]!
    $ageRange: [String]!
    $raceEthnicity: [String]!
    $platforms: [Platform]!
    $startDate: String
    $endDate: String
    $IRBApprovedFiles: [FileInput]
    $campaignId: String
    $caption: String
  ) {
    rejectCampaignRequest(
      campaignRequestInput: {
        name: $name
        description: $description
        postingInstructions: $postingInstructions
        platforms: $platforms
        startDate: $startDate
        endDate: $endDate
        IRBApprovedFiles: $IRBApprovedFiles
        campaignId: $campaignId
        caption: $caption
      }
      targetAudienceInput: {
        sex: $sex
        ageRange: $ageRange
        raceEthnicity: $raceEthnicity
      }
    ) {
      _id
      name
      description
      postingInstructions
    }
  }
`;

const REJECT_CAMPAIGN_MUTATION = gql`
  mutation rejectCampaign($id: String!) {
    rejectCampaign(id: $id) {
      name
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  padding: 0px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
  text-align: left;
`;
const Input = styled.input`
  display: block;
  margin: 10px auto;
  align-self: center;
  width: 80%;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   margin-top: 50px;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const displaySocialIcons = (platformName) => {
  switch (platformName) {
    case "Facebook":
      return (
        <FontAwesomeIcon
          icon={["fab", "facebook"]}
          style={{ alignSelf: "center", fontSize: "25px", color: "#3b5998" }}
        />
      );
    case "Instagram":
      return (
        <FontAwesomeIcon
          icon={["fab", "instagram"]}
          style={{ alignSelf: "center", fontSize: "25px", color: "#d6249f" }}
        />
      );
    case "Twitter":
      return (
        <FontAwesomeIcon
          icon={["fab", "twitter"]}
          style={{ alignSelf: "center", fontSize: "25px", color: "#1DA1F2" }}
        />
      );
    case "Tiktok":
      return (
        <FontAwesomeIcon
          icon={["fab", "tiktok"]}
          style={{ alignSelf: "center", fontSize: "25px", color: "#000" }}
        />
      );
    default:
      return (
        <FontAwesomeIcon
          icon={["fab", "facebook"]}
          style={{ alignSelf: "center", fontSize: "25px", color: "#000" }}
        />
      );
  }
};

function CampaignDetails({ review, nextStep, campaignData, setCampaignData }) {
  const handleInputChange = (event) => {
    setCampaignData({
      ...campaignData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setCampaignData({
        ...campaignData,
        platforms: [...campaignData.platforms, event.target.value],
      });
    } else {
      setCampaignData({
        ...campaignData,
        platforms: campaignData.platforms.filter(
          (platform) => platform !== event.target.value
        ),
      });
    }
  };

  const startDateObj = new Date(Number(campaignData.startDate));
  const endDateObj = new Date(Number(campaignData.endDate));

  const startDate = isNaN(startDateObj.getTime())
    ? campaignData.startDate
    : startDateObj.toISOString().slice(0, 10);
  const endDate = isNaN(endDateObj.getTime())
    ? campaignData.endDate
    : endDateObj.toISOString().slice(0, 10);

  // Move the form for campaign details here
  // Add a "Next" button that calls nextStep when clicked
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Campaign Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter campaign name"
          onChange={(e) => handleInputChange(e)}
          name="name"
          value={campaignData?.name}
        />
      </Form.Group>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <Form.Group
          className="mb-3"
          controlId="formStartDate"
          style={{ flex: 1 }}
        >
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            type="date"
            name="startDate"
            value={startDate}
            onChange={(e) => handleInputChange(e)}
          />
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="formEndDate"
          style={{ flex: 1 }}
        >
          <Form.Label>End Date</Form.Label>
          <Form.Control
            type="date"
            name="endDate"
            value={endDate}
            onChange={(e) => handleInputChange(e)}
          />
        </Form.Group>
      </Box>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Campaign Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter campaign description"
          onChange={(e) => handleInputChange(e)}
          name="campaignDescription"
          value={campaignData?.description}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label>Platforms</Form.Label>
        <Box style={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <Form.Check
            type="checkbox"
            label="Instagram"
            value="Instagram"
            checked={campaignData?.platforms.includes("Instagram")}
            onChange={handleCheckboxChange}
          />
          <Form.Check
            type="checkbox"
            label="Twitter"
            value="Twitter"
            checked={campaignData?.platforms.includes("Twitter")}
            onChange={handleCheckboxChange}
          />
          {/* <Form.Check
          type="checkbox"
          label="Facebook"
          value="Facebook"
          checked={campaignData?.platforms.includes("Facebook")}
          onChange={handleCheckboxChange}
        /> */}
          <Form.Check
            type="checkbox"
            label="Tiktok"
            value="Tiktok"
            checked={campaignData?.platforms.includes("Tiktok")}
            onChange={handleCheckboxChange}
          />
        </Box>
      </Form.Group>

      {review && (
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Label>Post Caption</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter posting instructions"
            onChange={(e) => handleInputChange(e)}
            name="caption"
            value={campaignData?.caption}
          />
        </Form.Group>
      )}

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label>Posting Instructions</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter posting instructions"
          onChange={(e) => handleInputChange(e)}
          name="postingInstructions"
          value={campaignData?.postingInstructions}
        />
      </Form.Group>
      <AdditionalQuestions
        campaignData={campaignData}
        setCampaignData={setCampaignData}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          padding: "20px",
        }}
      >
        <Button
          style={{
            backgroundColor: "#009EC5",
            borderColor: "#009EC5",
            color: "#fff",
          }}
          type="submit"
          onClick={nextStep}
        >
          Next
        </Button>
      </Box>
    </Form>
  );
}

function AdditionalQuestions({ campaignData, setCampaignData }) {
  const [sex, setSex] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [raceEthnicity, setRaceEthnicity] = useState([]);

  const handleInputChange = (event) => {
    console.log([event.target.name], event.target.value);
    console.log("data being set", {
      ...campaignData,
      targetAudience: {
        ...campaignData.targetAudience,
        [event.target.name]: event.target.value,
      },
    });

    setCampaignData({
      ...campaignData,
      targetAudience: {
        ...campaignData.targetAudience,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleMultiSelectChange = (event) => {
    setCampaignData({
      ...campaignData,
      targetAudience: {
        ...campaignData.targetAudience,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setCampaignData({
        ...campaignData,
        targetAudience: {
          ...campaignData.targetAudience,
          raceEthnicity: [
            ...campaignData.targetAudience.raceEthnicity,
            event.target.value,
          ],
        },
      });
    } else {
      setCampaignData({
        ...campaignData,
        targetAudience: {
          ...campaignData.targetAudience,
          raceEthnicity: campaignData.targetAudience.raceEthnicity.filter(
            (race) => race !== event.target.value
          ),
        },
      });
    }
  };

  return (
    <Form style={{ display: "flex", flexDirection: "column" }}>
      <FormControl fullWidth>
        <InputLabel shrink id="sex-label">Sex (Select All That Apply)</InputLabel>
        <Select
          labelId="sex-label"
          id="sex"
          multiple
          value={campaignData.targetAudience.sex}
          onChange={handleMultiSelectChange}
          name="sex"
        >
          {["Male", "Female"].map((sex) => (
            <MenuItem key={sex} value={sex}>
              {sex}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ marginTop: 30, marginBottom: 20 }} fullWidth>
        <InputLabel shrink id="age-range-label">Age Range (Select All That Apply)</InputLabel>
        <Select
          labelId="age-range-label"
          id="ageRange"
          multiple
          value={campaignData.targetAudience.ageRange}
          onChange={handleMultiSelectChange}
          name="ageRange"
        >
          {["18-24", "25-34", "35-44", "45-54", "55-64", "65 or older"].map(
            (ageRange) => (
              <MenuItem key={ageRange} value={ageRange}>
                {ageRange}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label>Target Audience Race/Ethnicity</Form.Label>
        <Box style={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <Form.Check
            type="checkbox"
            label="White"
            value="White"
            onChange={handleCheckboxChange}
            checked={campaignData?.targetAudience.raceEthnicity.includes(
              "White"
            )}
          />
          <Form.Check
            type="checkbox"
            label="Hispanic or Latinx"
            value="Hispanic or Latinx"
            onChange={handleCheckboxChange}
            checked={campaignData?.targetAudience.raceEthnicity.includes(
              "Hispanic or Latinx"
            )}
          />
          <Form.Check
            type="checkbox"
            label="Black or African American"
            value="Black or African American"
            onChange={handleCheckboxChange}
            checked={campaignData?.targetAudience.raceEthnicity.includes(
              "Black or African American"
            )}
          />
        </Box>
        <Box style={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <Form.Check
            type="checkbox"
            label="American Indian/Alaska Native"
            value="American Indian/Alaska Native"
            onChange={handleCheckboxChange}
            checked={campaignData?.targetAudience.raceEthnicity.includes(
              "American Indian/Alaska Native"
            )}
          />
          <Form.Check
            type="checkbox"
            label="Asian"
            value="Asian"
            onChange={handleCheckboxChange}
            checked={campaignData?.targetAudience.raceEthnicity.includes(
              "Asian"
            )}
          />
          <Form.Check
            type="checkbox"
            label="Native Hawaiian or other Pacific Islander"
            value="Native Hawaiian or other Pacific Islander"
            onChange={handleCheckboxChange}
            checked={campaignData?.targetAudience.raceEthnicity.includes(
              "Native Hawaiian or other Pacific Islander"
            )}
          />
        </Box>
      </Form.Group>
    </Form>
  );
}

function InfluencerSelection({
  prevStep,
  nextStep,
  campaignData,
  setCampaignData,
}) {
  const { loading, error, data } = useQuery(GET_INFLUENCER_RECOMMENDATIONS, {
    variables: {
      raceEthnicity: campaignData.targetAudience.raceEthnicity,
      sex: campaignData.targetAudience.sex,
      ageRange: campaignData.targetAudience.ageRange,
      platforms: campaignData.platforms,
    },
    skip:
      !campaignData.targetAudience.raceEthnicity &&
      !campaignData.targetAudience.sex &&
      !campaignData.targetAudience.ageRange &&
      !campaignData.platforms,
  });
  const [recommendedInfluencers, setRecommendedInfluencers] = useState([]);
  const [showInfluencerPortfolio, setShowInfluencerPortfolio] = useState(false);
  const [createCampaignRequest] = useMutation(CAMPAIGN_CREATION_REQUEST);
  const navigate = useNavigate();

  console.log("varaiables ", campaignData, {
    raceEthnicity: campaignData.targetAudience.raceEthnicity,
    sex: campaignData.targetAudience.sex,
    ageRange: campaignData.targetAudience.ageRange,
    platforms: campaignData.platforms,
  });

  console.log(
    "should be skipping",
    !campaignData.raceEthnicity &&
      !campaignData.sex &&
      !campaignData.ageRange &&
      !campaignData.platforms
  );
  console.log("recommendations loading & error", loading, error);

  useEffect(() => {
    if (data) {
      console.log("influencerRecommendations", data.influencerRecommendations);
      setRecommendedInfluencers(data.influencerRecommendations);
    }
  }, [data]);

  // Filter influencers based on demographic information
  // const recommendedInfluencers = influencers.filter((influencer) => {
  //   console.log(
  //     "influencer includes platform",
  //     influencer.platforms.some((platform) =>
  //       campaignData.platforms.includes(platform)
  //     ),
  //     campaignData.platforms,
  //     influencer.platforms
  //   );
  //   return (
  //     influencer.sex === campaignData.sex &&
  //     campaignData.raceEthnicity.includes(influencer.raceEthnicity) &&
  //     influencer.platforms.some((platform) =>
  //       campaignData.platforms.includes(platform)
  //     )
  //   );
  // });

  // console.log("recommendedInfluencers", recommendedInfluencers);
  // const recommendedInfluencers = influencers.filter(
  //   (influencer) =>
  //     influencer.sex === campaignData.sex &&
  //     campaignData.raceEthnicity.includes(influencer.raceEthnicity)
  //    ||
  // (influencer.ageRange === campaignData.ageRange &&
  //   influencer.sex === campaignData.sex &&
  //   campaignData.raceEthnicity.includes(influencer.raceEthnicity)) ||
  // (influencer.sex === campaignData.sex &&
  //   campaignData.raceEthnicity.includes(influencer.raceEthnicity) &&
  //   campaignData.platforms.includes(influencer.platforms)
  //   )
  // );

  // Display recommended influencers
  // const displayInfluencers = recommendedInfluencers.map((influencer) => (
  //   <div key={influencer.name}>{influencer.name}</div>
  // ));

  const handleInfluencerSelection = (influencer) => {
    console.log("influencer selected", influencer);
    setCampaignData({
      ...campaignData,
      influencers: [...campaignData.influencers, influencer],
    });
  };

  const handleInfluencerDeselection = (influencer) => {
    console.log("influencer deselected", influencer);
    const selections = campaignData.influencers;
    const influencerIndex = selections.findIndex(
      (inf) => inf._id === influencer._id
    );
    if (influencerIndex !== -1) {
      const newSelections = selections.filter(
        (inf) => inf._id !== influencer._id
      );
      setCampaignData({
        ...campaignData,
        influencers: newSelections,
      });
    }
  };

  // Move the code for influencer selection here
  // Add a "Back" button that calls prevStep when clicked

  return (
    <Form>
      <ToastContainer />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form.Label
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "20px",
            marginBottom: "20px",
            alignSelf: "center",
          }}
        >
          Influencer Selection
        </Form.Label>
      </div>
      <InfluencerPortfolio
        recomendations={recommendedInfluencers}
        selections={campaignData.influencers}
        selectInfluencer={handleInfluencerSelection}
        deselectInfluencer={handleInfluencerDeselection}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: "20px",
        }}
      >
        <Button
          style={{
            backgroundColor: "#fff",
            borderColor: "#009EC5",
            color: "#009EC5",
          }}
          onClick={() => prevStep()}
        >
          Back
        </Button>
        <Button
          style={{
            backgroundColor: "#009EC5",
            borderColor: "#009EC5",
            color: "#fff",
          }}
          type="submit"
          onClick={() => nextStep()}
        >
          Next
        </Button>
      </Box>
    </Form>
  );
}

const MediaSelection = ({
  nextStep,
  prevStep,
  campaignData,
  setCampaignData,
  accountType,
}) => {
  const [show, setShow] = useState(false);

  const saveSelection = async (selectedFiles) => {
    // save the selected media to the campaignData object
    console.log("selectedFiles", selectedFiles);
    setCampaignData({
      ...campaignData,
      media: selectedFiles,
    });
    //close modal once done
    setShow(false);
  };

  return (
    <Box>
      <MediaLibrary
        isOpen={show}
        onClose={() => setShow(false)}
        saveSelection={saveSelection}
        accountType={accountType}
      />
      {/* might want to display media on the page instead of in the modal */}
      <Button
        onClick={() => setShow(true)}
        style={{ backgroundColor: "#009EC5", borderColor: "#009EC5" }}
      >
        {campaignData?.media ? "Change Selection" : "Open Media Library"}
      </Button>
      {/* display  files selected for campaign in a grid  */}
      <Box>
        {campaignData?.media &&
          campaignData?.media?.map((file, index) => (
            <Image
              key={index}
              src={file.url}
              style={{ borderRadius: 10, height: 200, width: 200, margin: 20 }}
            />
          ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: "20px",
          position: "fixed",
          bottom: 0,
          width: "80%",
        }}
      >
        <Button
          style={{
            backgroundColor: "#fff",
            borderColor: "#009EC5",
            color: "#009EC5",
          }}
          onClick={() => prevStep()}
        >
          Back
        </Button>
        <Button
          style={{
            backgroundColor: "#009EC5",
            borderColor: "#009EC5",
            color: "#fff",
          }}
          onClick={() => nextStep()}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

const CampaignSummary = ({
  nextStep,
  deny,
  prevStep,
  campaignData,
  review,
  adminCreate,
}) => {
  console.log("influencers", campaignData.influencers);

  const startDateObj = new Date(Number(campaignData.startDate));
  const endDateObj = new Date(Number(campaignData.endDate));

  const startDate = isNaN(startDateObj.getTime())
    ? campaignData.startDate
    : startDateObj.toDateString();
  const endDate = isNaN(endDateObj.getTime())
    ? campaignData.endDate
    : endDateObj.toDateString();

  return (
    <Card>
      <Box>
        <Text>
          <b> {campaignData.name}</b>
        </Text>
        <Box style={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Text>
              <b>Campaign Start Date</b>
            </Text>
            <Text>{startDate}</Text>
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Text>
              <b>Campaign End Date:</b>
            </Text>
            <Text>{endDate}</Text>
          </Box>
        </Box>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <b> Campaign Description </b>
            <Text>{campaignData.description}</Text>
          </Box>
        </Box>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <b> Posting Instructions</b>
            <Text>{campaignData.postingInstructions}</Text>
          </Box>
        </Box>
        <Box style={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <b> Target Audience </b>
            <Text>
              {`Sex: ${campaignData.targetAudience.sex.filter(
                (val) => val !== ""
              )}`}{" "}
            </Text>
          </Box>

          <Box style={{ display: "flex", flexDirection: "column" }}>
            <b>Age Range </b>
            <Text>
              {` ${campaignData.targetAudience.ageRange.filter(
                (val) => val !== ""
              )}`}{" "}
            </Text>
          </Box>

          <Box style={{ display: "flex", flexDirection: "column" }}>
            <b>Race/Ethnicity</b>
            <Text>
              {` ${campaignData.targetAudience.raceEthnicity.filter(
                (val) => val !== ""
              )}`}
            </Text>
          </Box>
        </Box>

        <Text>
          <b> Platforms: </b>
          {campaignData.platforms.join(", ")}
        </Text>
        <Text>
          <b> Influencers: </b>{" "}
          <div className="study-grid">
            {campaignData.influencers &&
              campaignData.influencers.map((influencer, index) => (
                <div className="study-card" key={index}>
                  <Card className="card-face card-face-front">
                    <Container style={{ backgroundColor: "#fff" }}>
                      {/* <AccountCircleIcon
     style={{
       fontSize: "100px",
       color: "black",
       cursor: "pointer",
     }}
   /> */}
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {influencer?.avatar && (
                          <img
                            src={influencer.avatar}
                            style={{
                              height: "100px",
                              width: "100px",
                            }}
                          />
                        )}
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h4>
                            {influencer?.firstName} {influencer?.lastName}
                          </h4>
                          <p>
                            <strong>Location:</strong>{" "}
                            <span style={{ color: "blue" }}>
                              {/* {`${influencer?.location?.city || ""}, ${
                            influencer?.location?.state ||
                            influencer?.location?.country ||
                            ""
                          }`} */}
                              {influencer?.location || ""},
                            </span>
                          </p>
                        </Box>
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              flexDirection: "row",
                              marginBottom: "10px",
                            }}
                          >
                            {influencer?.platforms.map((platform, index) => (
                              <>{displaySocialIcons(platform)}</>
                            ))}
                          </div>
                        </Box>
                      </Box>
                      {/* <p>Platforms: {displaySocialIcons(influencer.platforms.toString())}</p> */}

                      <p>
                        <strong> Therapy Areas: </strong>{" "}
                        <span style={{ color: "blue" }}>
                          {influencer?.therapyAreas &&
                            influencer?.therapyAreas
                              .map((area) => area.name)
                              .join(", ")}
                        </span>
                      </p>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          gap: 2,
                        }}
                      >
                        <p>
                          <strong> Followers: </strong>{" "}
                          {(influencer?.followers &&
                            formatNumberNoDecimal(influencer.followers)) ||
                            0}
                        </p>
                        <p>
                          <strong> Reach: </strong>{" "}
                          {(influencer?.followers &&
                            formatNumberNoDecimal(
                              influencer.followers * 2000
                            )) ||
                            0}
                        </p>
                      </Box>
                    </Container>
                  </Card>
                </div>
              ))}
          </div>
        </Text>
        <Text>
          <b> Selected Media: </b>
        </Text>
        {campaignData?.media &&
          campaignData?.media.map((file, index) => (
            <Image
              key={index}
              src={file.url}
              style={{ height: 200, width: 200, margin: 20, borderRadius: 10 }}
            />
          ))}

        {/* <Text
        dangerouslySetInnerHTML={createMarkup(
          JSON.stringify(campaignData.content)
        )}
      ></Text> */}
        <Text>{campaignData.active}</Text>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            padding: "20px",
          }}
        >
          {adminCreate ? (
            <>
              <Button
                onClick={() => prevStep()}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#009EC5",
                  color: "#009EC5",
                }}
              >
                Previous
              </Button>
              <Button
                onClick={() => nextStep()}
                style={{
                  backgroundColor: "#009EC5",
                  borderColor: "#009EC5",
                  color: "#fff",
                }}
              >
                Create
              </Button>
            </>
          ) : review ? (
            <>
              <Button
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#009EC5",
                  color: "#009EC5",
                }}
                onClick={() => prevStep()}
              >
                Previous
              </Button>
              <Button variant="danger" onClick={() => deny()}>
                Deny
              </Button>
              <Button
                style={{
                  backgroundColor: "#009EC5",
                  borderColor: "#009EC5",
                  color: "#fff",
                }}
                onClick={() => nextStep()}
              >
                Approve
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#009EC5",
                  color: "#009EC5",
                }}
                onClick={() => prevStep()}
              >
                Previous
              </Button>
              <Button
                style={{
                  backgroundColor: "#009EC5",
                  borderColor: "#009EC5",
                  color: "#fff",
                }}
                onClick={() => nextStep()}
              >
                Create Campaign
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
};

function CampaignBuilder({
  review = false,
  adminData,
  setAdminData,
  adminCreate = false,
}) {
  console.log("adminData", adminData);
  const { user, userPrimaryRole } = useUserContext();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { studyId } = useParams();
  // const { loading, error, data } = useQuery(INFLUENCERS_QUERY);
  // console.log("influencer data", data);
  console.log("campaignId", studyId);
  const {
    loading: campaignLoading,
    error: campaignError,
    data: campaignData,
  } = useQuery(CAMPAIGNS_QUERY, {
    variables: { id: review ? studyId : campaignId },
  });

  const _contentState = ContentState.createFromText("Sample content state");
  const raw = convertToRaw(_contentState); // RawDraftContentState JSON
  const [contentState, setContentState] = useState(raw);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [content, setConvertedContent] = useState(null);
  const [name, setName] = useState("Test");

  const [influencers, setInfluencers] = useState([]);
  const [platform, setPlatform] = useState("Facebook");
  const [scheduledDate, setScheduledDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [step, setStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const steps = [
    "Campaign Details",
    "Influencer Selection",
    "Select Media",
    "Review",
  ];

  const [campaignInfo, setCampaignData] = useState(
    review
      ? adminData
      : {
          targetAudience: {
            sex: [],
            ageRange: [],
            raceEthnicity: [],
          },
          campaignName: "",
          campaignDescription: "",
          platforms: [],
          postingInstructions: "",
          influencers: [],
          startDate: "",
          endDate: "",
          media: [],
        }
  );

  useEffect(() => {
    if (review && adminData) {
      setCampaignData(adminData);
    }
  }, [review, adminData]);

  console.log("adminData", adminData, review, campaignInfo);

  // console.log("campaignData", campaignInfo);

  const nextStep = () => {
    setCompletedSteps([...completedSteps, step]);
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
    setCompletedSteps(completedSteps.filter((step) => step !== step));
  };

  const accountType = userPrimaryRole();

  // const [createCampaign] = useMutation(CREATE_CAMPAGIN_MUTATION, {
  //   variables: {
  //     content,
  //     name,
  //     influencers,
  //     platform,
  //     startDate,
  //     endDate,
  //   },
  // });

  const [createCampaignRequest] = useMutation(CAMPAIGN_CREATION_REQUEST);
  const [approveCampaignRequest] = useMutation(
    APPROVE_CAMPAIGN_REQUEST_MUTATION
  );
  const [denyCampaignRequest] = useMutation(REJECT_CAMPAIGN_REQUEST_MUTATION);

  const onDeny = async () => {
    if (review) {
      const res = await denyCampaignRequest({
        variables: {
          name: campaignInfo.name,
          description: campaignInfo.description,
          sex: campaignInfo.targetAudience.sex,
          ageRange: campaignInfo.targetAudience.ageRange,
          raceEthnicity: campaignInfo.targetAudience.raceEthnicity,
          platforms: campaignInfo.platforms,
          postingInstructions: campaignInfo.postingInstructions,
          influencers: influencers,
          startDate: campaignInfo.startDate,
          endDate: campaignInfo.endDate,
          IRBApprovedFiles: campaignInfo.media,
          campaignId: campaignId,
          caption: campaignInfo.caption,
        },
      });

      console.log("res", res);

      if (res) {
        toast.success("Campaign Denied successfully");
        navigate(-1);
      } else {
        toast.error("Error Approving campaign");
      }
    }
  };

  const onSubmit = async () => {
    // setShowInfluencerPortfolio(true);
    console.log("campaignData", campaignInfo);

    const influencers = campaignInfo.influencers.map((influencer) => ({
      _id: influencer._id,
      firstName: influencer.firstName,
      lastName: influencer.lastName,
      platforms: influencer.platforms,
      avatar: influencer.avatar,
    }));

    if (review) {
      //if admin is approving a request created by partner approve/deny request
      const res = await approveCampaignRequest({
        variables: {
          name: campaignInfo.name,
          description: campaignInfo.description,
          sex: campaignInfo.targetAudience.sex,
          ageRange: campaignInfo.targetAudience.ageRange,
          raceEthnicity: campaignInfo.targetAudience.raceEthnicity,
          platforms: campaignInfo.platforms,
          postingInstructions: campaignInfo.postingInstructions,
          influencers: influencers,
          startDate: campaignInfo.startDate,
          endDate: campaignInfo.endDate,
          IRBApprovedFiles: campaignInfo.media,
          campaignId: campaignId,
          caption: campaignInfo.caption,
        },
      });

      console.log("res", res);

      if (res) {
        toast.success("Campaign Approved successfully");
        navigate(-1);
      } else {
        toast.error("Error Approving campaign");
      }
      return;
    }

    //if admin is viewing a request created by partner approve/deny request

    //if admin is creating a new campaign create campaign

    //if partner create request
    const res = await createCampaignRequest({
      variables: {
        name: campaignInfo.name,
        description: campaignInfo.description,
        sex: campaignInfo.targetAudience.sex,
        ageRange: campaignInfo.targetAudience.ageRange,
        raceEthnicity: campaignInfo.targetAudience.raceEthnicity,
        platforms: campaignInfo.platforms,
        postingInstructions: campaignInfo.postingInstructions,
        influencers: influencers,
        startDate: campaignInfo.startDate,
        endDate: campaignInfo.endDate,
        IRBApprovedFiles: campaignInfo.media,
        campaignId: campaignId,
      },
    });

    console.log("res", res);

    if (res) {
      toast.success("Campaign Requested successfully");
      navigate(-1);
    } else {
      toast.error("Error creating campaign");
    }
  };
  // useEffect(() => {
  //   document.title = "Admin Create Campaign";
  // }, []);

  // useEffect(() => {
  //   if (!loading && data) setInfluencers(data.influencers);
  // }, [loading, data]);

  useEffect(() => {
    if (!campaignLoading && campaignData) {
      console.log("campaignData in use effect", campaignData);
      setCampaignData(campaignData.campaigns[0]);
    }
  }, [campaignLoading, campaignData]);

  return (
    <Box m="20px" p={4}>
      <CustomStepper
        activeStep={step}
        completedSteps={completedSteps}
        steps={steps}
      />
      {step === 0 && (
        <CampaignDetails
          nextStep={nextStep}
          campaignData={campaignInfo}
          setCampaignData={setCampaignData}
          review={review}
        />
      )}
      {step === 1 && (
        <InfluencerSelection
          nextStep={nextStep}
          prevStep={prevStep}
          campaignData={campaignInfo}
          setCampaignData={setCampaignData}
        />
      )}
      {step === 2 && (
        <MediaSelection
          nextStep={nextStep}
          prevStep={prevStep}
          campaignData={campaignInfo}
          setCampaignData={setCampaignData}
          accountType={accountType}
        />
      )}
      {step === 3 && (
        <CampaignSummary
          nextStep={onSubmit}
          deny={onDeny}
          prevStep={prevStep}
          campaignData={campaignInfo}
          review={review}
          adminCreate={adminCreate}
        />
      )}
    </Box>
  );
}

export default CampaignBuilder;
