import { gql, useQuery } from "@apollo/client";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const CAMPAIGNS_QUERY = gql`
  query Campaigns {
    campaigns {
      _id
      name
      scheduledDate
      startDate
      platforms
      active
      status
      organization {
        _id
        name
      }
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "white"};
  height: 200px;
  margin: 10px 0;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  align-self: center;
  border: 2px solid #0ca6af;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  display: block;
  margin: 10px auto;
  align-self: center;
  width: 200px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   margin-top: 50px;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const options = [
  {
    label: <span>Foo</span>,
    value: {
      foo: true,
    },
    selectedBackgroundColor: "#0097e6",
  },
  {
    label: "Bar",
    value: "bar",
    selectedBackgroundColor: "#fbc531",
  },
];

const OnOffSwitch = ({ isOn, setIsOn }) => {
  const handleClick = () => {
    setIsOn(!isOn);
  };

  return (
    <div>
      <div className="on-off-switch" onClick={handleClick}>
        <div className={`switch ${isOn ? "on" : "off"}`} />
      </div>
      {/* <span className="centerSelf">
        {isOn ? "active" : "inactive"}
      </span> */}
    </div>
  );
};

const ManageCampaigns = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(CAMPAIGNS_QUERY); //newly created campaigns are not being loaded
  const [campaigns, setCampaigns] = useState([]);
  const [isOn, setIsOn] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [tabs, setTabs] = useState([
    { name: "Created", link: "/admin/managecampaigns/created" },
    { name: "Pending", link: "/admin/managecampaigns/pending" },
    { name: "Approved", link: "/admin/managecampaigns/approved" },
  ]);

  useEffect(() => {
    if (!loading) setCampaigns(data.campaigns);
  }, [loading]);

  console.log("activeTab", activeTab);

  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <main className="content">
        <Topbar />
        <div className="App-body outerRow" style={{ marginTop: 20 }}>
          <div className="container">
            <div className="flexColumn">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                {/* <div style={{ border: "1px solid black", padding: 10 }}>
                <Text>Active/Inactive</Text>
              </div> */}
                {/* <SwitchSelector
                onChange={(newValue) => {
                  console.log(newValue);
                }}
                options={options}
                // initialSelectedIndex={initialSelectedIndex}
                backgroundColor={"#353b48"}
                fontColor={"#f5f6fa"}
              /> */}
                <OnOffSwitch isOn={isOn} setIsOn={setIsOn} />
                <Text>Campaigns</Text>
                <Button
                  variant="primary"
                  onClick={() => navigate("/admin/createcampaigns")}
                  style={{ width: 40, height: 40 }}
                >
                  +
                </Button>
              </div>
              <Nav justify variant="underline" defaultActiveKey={"/Created"}>
                {tabs &&
                  tabs.map((tab, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link
                        eventKey={"/" + tab.name}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
              </Nav>
              <div
                style={{
                  overflowY: "scroll",
                  minHeight: 500,
                }}
              >
                {activeTab === 0 &&
                  campaigns &&
                  campaigns.map((campaign, index) => {
                    if (isOn && campaign.active)
                      return (
                        <Card
                          key={index}
                          onClick={() => navigate(`view/${campaign._id}`)}
                          style={{ margin: "10px 0", cursor: "pointer" }}
                          title={campaign.name}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignSelf: "baseline",
                            }}
                          >
                            <Text>
                              Scheduled For:{" "}
                              {new Date(
                                Number(campaign.startDate)
                              ).toLocaleString()}
                            </Text>
                            <Text>Platform: {campaign.platform}</Text>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignSelf: "baseline",
                            }}
                          >
                            <Button variant="danger">Delete</Button>
                            <Button variant="primary">Edit</Button>
                            <Button variant="success">Activate</Button>
                          </div>
                        </Card>
                      );
                  })}
                {activeTab === 1 &&
                  campaigns &&
                  campaigns.map((campaign, index) => {
                    if (campaign && campaign.status === "Pending")
                      return (
                        <Card
                          key={index}
                          onClick={() => navigate(`view/${campaign._id}`)}
                          style={{ margin: "10px 0", cursor: "pointer" }}
                          title={campaign.name}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignSelf: "baseline",
                            }}
                          >
                            <Text>
                              Scheduled For:{" "}
                              {new Date(
                                Number(campaign.startDate)
                              ).toLocaleString()}
                            </Text>
                            {campaign.organization && (
                              <Text>
                                Organization: {campaign.organization.name}
                              </Text>
                            )}
                            <Text>Platforms: {campaign.platforms}</Text>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignSelf: "baseline",
                            }}
                          >
                            <Button variant="danger">Delete</Button>
                            <Button variant="primary">Edit</Button>
                            <Button variant="success">Approve</Button>
                          </div>
                        </Card>
                      );
                  })}
                {activeTab === 2 &&
                  campaigns &&
                  campaigns.map((campaign, index) => {
                    if (campaign && campaign.status === "Approved")
                      return (
                        <Card
                          key={index}
                          onClick={() => navigate(`view/${campaign._id}`)}
                          style={{ margin: "10px 0", cursor: "pointer" }}
                          title={campaign.name}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignSelf: "baseline",
                            }}
                          >
                            <Text>
                              Scheduled For:{" "}
                              {new Date(
                                Number(campaign.startDate)
                              ).toLocaleString()}
                            </Text>
                            {campaign.organization && (
                              <Text>
                                Organization: {campaign.organization.name}
                              </Text>
                            )}
                            <Text>Platforms: {campaign.platforms}</Text>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignSelf: "baseline",
                            }}
                          >
                            {/* <Button variant="danger">Delete</Button>
                            <Button variant="primary">Edit</Button>
                            <Button variant="success">Approve</Button> */}
                          </div>
                        </Card>
                      );
                  })}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ManageCampaigns;
