import { gql, useMutation } from "@apollo/client";
import { Box, TextField } from "@mui/material";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import CliniSpanLogo from "../../assets/logo.png";
import { useUserContext } from "../../providers/UserContext";

const LOGIN_MUTATION = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(loginInput: { email: $email, password: $password }) {
      _id
      firstName
      email
      token
      role
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  width: 500px;
  margin-top: 50px;
`;

const Body = styled.div`
  background-image: url(../../assets/patientBackground.png);
  background-repeat: no-repeat;
  background-size: cover;
`;

const Banner = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Text = styled.p`
  font-size: 20px;
`;

const Link = styled.p`
  cursor: pointer;
  text-decoration: underline;
`;

const Login = () => {
  const { setUser } = useUserContext();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Patient LOGIN";
  }, []);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    // Redirect to Patient dashboard for Rownd sign-in
    navigate("/patient/dashboard");
  }, [navigate]);

  const [loginUser] = useMutation(LOGIN_MUTATION, {
    variables: {
      email,
      password,
    },
  });

  const onLogin = async () => {
    try {
      const user = await loginUser().catch((err) => {
        console.error("err", err);
        setLoginError(true);
        toast.error("Invalid Credentials", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      });

      const token = user?.data?.loginUser?.token;

      if (token) {
        setUser(user?.data?.loginUser);
        localStorage.setItem("token", token);
        navigate("/patient/dashboard");
      }
    } catch (error) {
      console.error("error", error);
      setLoginError(true);
      alert("Invalid Credentials");
    }
  };

  return (
    <div className="App">
      <ToastContainer />
      <header className="App-header">
        {/* <h1>DHI/CHI Portal</h1> */}
        <Image src={CliniSpanLogo} alt="Logo" />
        {/* <Image src={require("../assets/logo.png")} /> */}
      </header>
      <Body className="App-body">
        <Box>
          <Card>
            <Banner>
              <Text style={{ color: "lightblue", margin: "0px auto" }}>
                Patient Login
              </Text>
            </Banner>
            <Container>
              <Text> Glad to have you back!</Text>
              <InnerContainer>
                <TextField
                  style={{ marginTop: 20, marginBottom: 20 }}
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <TextField
                  margin="10px"
                  label="Password"
                  variant="outlined"
                  value={password}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <Button onClick={onLogin} style={{ margin: "25px 0" }}>
                  Login
                </Button>
                <div>
                  <Link onClick={() => navigate("/forgotpassword")}>
                    Forgot Password? <a> Click Here</a>
                  </Link>
                  <Link onClick={() => navigate("/patientsignup")}>
                    {" "}
                    Signup
                  </Link>
                </div>
              </InnerContainer>
            </Container>
          </Card>
        </Box>
      </Body>
    </div>
  );
};

export default Login;
