import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PartnerHeader } from "../../components/Menus";

const Text = styled.p`
  color: #000;
  text-align: left;
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

// Dummy data
const studyData = [
  {
    name: "Asthma Trial",
    nctNumber: "12341231",
    participants: 10,
    active: true,
  },
  {
    name: "COVID Trial",
    nctNumber: "12341231",
    participants: 20,
    active: false,
  },
  {
    name: "Diabetes Trial",
    nctNumber: "12341231",
    participants: 30,
    active: true,
  },
];

function Outreach() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <PartnerHeader />
      <div className="App-body outerRow">
        <div className="container">
          <div className="flexColumn">
            <Text style={{ alignSelf: "center", fontWeight: "bold" }}>
              Outreach
            </Text>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                  border: "1px solid black",
                }}
              >
                <Text className="centerSelf">Choose Study Group</Text>
              </div>
              {studyData.map((study, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 10,
                    border: "2px solid lightblue",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/partner/outreach/study")}
                >
                  <Text>{study.name}</Text>
                  <Text>NCT{study.nctNumber}</Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Outreach;
