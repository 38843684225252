import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import exportCSV from "../../utilities/exportCsv";
import { gql, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Sidebar from "../../components/Sidebar";
import TextHeader from "../../components/TextHeader";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const PARTNER_SIGNUP_QUERY = gql`
  query GetPartnerSignups {
    getPartnerSignups {
      data {
        id: _id
        email
        firstName
        studiesUploaded
      }
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Text = styled.p`
  color: #000;
  text-align: left;
`;

const data = [
  {
    id: 1,
    name: "Partner A",
    email: "partnera@example.com",
    upload: "Study A",
  },
  {
    id: 2,
    name: "Partner B",
    email: "partnerb@example.com",
    upload: "Study B",
  },
  {
    id: 3,
    name: "Partner C",
    email: "partnerc@example.com",
    upload: "Study C",
  },
];

const PartnerTable = ({ data }) => {
  const columns = [
    {
      headerName: "Partner Name",
      field: "firstName",
      flex: 1,
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
    },
    {
      headerName: "studies Uploaded",
      field: "studiesUploaded",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Box
        m="40px 0 0 0"
        height="75vh"
        xl={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          // "& .name-column--cell": {
          //   color: colors.greenAccent[300],
          // },
          // "& .MuiDataGrid-columnHeaders": {
          //   backgroundColor: colors.blueAccent[700],
          //   borderBottom: "none",
          // },
          // "& .MuiDataGrid-virtualScroller": {
          //   backgroundColor: colors.primary[400],
          // },
          // "& .MuiDataGrid-footerContainer": {
          //   borderTop: "none",
          //   backgroundColor: colors.blueAccent[700],
          // },
          // "& .MuiCheckbox-root": {
          //   color: `${colors.greenAccent[200]} !important`,
          // },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

const RecruitmentPartners = () => {
  function handleExportClick() {
    // exportCSV(data, "myData.csv");
  }
  const { user } = useUserContext();
  const [activeBar, setActiveBar] = useState(null);
  const [partnerSignups, setPartnerSignups] = useState(null);
  const {
    loading,
    error,
    data: patientSignupData,
  } = useQuery(PARTNER_SIGNUP_QUERY);

  useEffect(() => {
    document.title = "Admin Dashboard";
    if (!loading && patientSignupData) {
      setPartnerSignups(patientSignupData.getPartnerSignups.data);
    }
  }, [loading]);

  const handleBarMouseOver = (data, index) => {
    setActiveBar(data);
  };

  const handleBarMouseLeave = () => {
    setActiveBar(null);
  };

  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <main className="content">
        <Topbar />
        <Box m="20px">
          <TextHeader title="Recruitment Partners" />
          <PartnerTable data={partnerSignups || data} />
        </Box>
      </main>
    </div>
  );
};

export default RecruitmentPartners;
