import { East } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Background from "../../assets/patientMask.png";
import Temp from "../../assets/temp.png";
import Temp2 from "../../assets/temp2.png";
import Temp3 from "../../assets/temp3.png";
import Temp4 from "../../assets/temp4.png";
import Temp5 from "../../assets/temp5.png";
import Temp6 from "../../assets/temp6.png";
import Temp7 from "../../assets/temp7.png";
import { PatientHeader } from "../../components/Menus";
import { Roles } from "../../constants/user";
import { useUserContext } from "../../providers/UserContext";
import { formatNumberNoDecimal } from "../../utilities/format";

const Image = styled.img`
  height: 200px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 400px;
  width: 90%;
  margin: 20px 0;
  border-radius: 10px;
  padding: 5px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   margin-top: 50px;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
  font-size: 20px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
`;

const Dashboard = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const tempCommunities = [
    {
      name: "Diabetes",
      members: 10000,
    },
    {
      name: "Asthma",
      members: 20000,
    },
    {
      name: "HIV",
      members: 50000,
    },
    {
      name: "Mental Health",
      members: 300000,
    },
    {
      name: "Covid-19",
      members: 150000,
    },
  ];

  const [communities, setCommunities] = useState([...tempCommunities]);

  const handleJoin = (id) => {
    setCommunities(
      communities.map((community, index) => {
        if (index === id) {
          return { ...community, members: community.members + 1, joined: true };
        }
        return community;
      })
    );
  };

  useEffect(() => {
    document.title = "Patient Dashboard";
    // const token = localStorage.getItem("token");
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!user?.firstName) {
      return navigate("/patient/manage-profile");
    }
    const roles = user?.roles;
    if (roles && !roles.includes(Roles.Constituent)) {
      // setError("Your role is not a patient");
      // update users role
    }
  }, [navigate, user]);

  const openModal = () => {
    setOpen(true);
  };

  return (
    <div className="App">
      <PatientHeader />
      <div className="PatientApp-body">
        <Container
          background={"#044389"}
          style={{
            display: "flex",
            padding: 20,
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain", // or "100% 100%" or "cover"
            backgroundPosition: "right bottom",
            height: 600,
          }}
        >
          <Modal show={open} onHide={() => setOpen(false)} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Find a Therapy Area Community</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* display a list of communities to join */}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                {" "}
                {communities.map((community, id) => (
                  <Box
                    key={id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 10,
                      border: "1px solid #4FBCD7",
                      backgroundColor: "#19A8CB",
                      margin: 10,
                      padding: 10,
                    }}
                  >
                    <Image src={Temp} />
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 5,
                      }}
                    >
                      <Text style={{ color: "white", fontWeight: "bold" }}>
                        {community.name}
                      </Text>
                      <Text style={{ color: "white" }}>
                        Join a community of patients interested in{" "}
                        {community.name}
                      </Text>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Text style={{ color: "white" }}>Members:</Text>
                        <Text style={{ color: "white", fontWeight: "bold" }}>
                          {formatNumberNoDecimal(community.members)}
                        </Text>
                      </Box>
                    </Box>
                    <Button
                      onClick={() => handleJoin(id)}
                      disabled={community.joined}
                      style={{
                        backgroundColor: community.joined ? "green" : "#fff",
                        borderColor: community.joined ? "green" : "#fff",
                        color: community.joined ? "#fff" : "#009EC5",
                        width: 150,
                      }}
                    >
                      {community.joined ? "Joined" : "Join"}
                    </Button>
                  </Box>
                ))}
              </Box>
            </Modal.Body>
            {/* <Modal.Footer>
              <Button onClick={() => setOpen(false)}>Close</Button>
              <Button onClick={() => setOpen(false)}>Save changes</Button>
            </Modal.Footer> */}
          </Modal>

          <Box
            style={{
              alignItems: "flex-start",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              width: "40%",
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: 14,
                textAlign: "left",
                fontWeight: "600",
              }}
            >
              {"Access Medical Research Opportunities".toUpperCase()}
            </Text>
            <Text
              style={{
                color: "white",
                fontSize: 32,
                textAlign: "left",
                fontWeight: "400",
                 whiteSpace: 'pre-line'
              }}
            >
              {`Find Studies For You, 
              Access New Treatments 
              & Promote Health Equity! `}
            </Text>
            <Text style={{ color: "white", fontSize: 16, textAlign: "left" }}>
            To find research opportunities click below. 
            </Text>
            <Button
              style={{
                backgroundColor: "#009EC5",
                borderColor: "#009EC5",
                color: "#fff",
                width: 250,
              }}
              href="/patient/studies-for-you"
            >
              Find Studies for you
            </Button>
          </Box>
        </Container>
        <Container background={"white"} style={{ height: 550 }}>
          <Box>
            <Text style={{ fontSize: 20, textAlign: "left", color: "#009EC5" }}>
              How We Work
            </Text>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box display={"flex"} flexDirection={"column"}>
                <Text style={{ fontSize: 22, fontWeight: "bold" }}>
                  Simple steps to your health solutions
                </Text>
              </Box>
              <Box display={"flex"} flexDirection={"column"} width={"20%"}>
                <Text style={{ fontSize: 11 }}>
                  {" "}
                  {/* To learn more about how we increase access to & diversity in
                  clinical trials click below! */}
                </Text>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box display={"flex"} flexDirection={"column"} width={"20%"}>
                <Box
                  style={{
                    backgroundColor: "rgba(0, 158, 197, 0.05)",
                    borderRadius: 10,
                  }}
                >
                  <Image src={Temp4} style={{ borderRadius: 10 }} />
                </Box>
                <br />
                <Text style={{ fontWeight: "bold" }}>Join a Community</Text>
                <Text>
                  Find a therapy area community to join and discover new
                  information about clinical trials
                </Text>
              </Box>

              <Box display={"flex"} flexDirection={"column"} width={"20%"}>
                <Box
                  style={{
                    backgroundColor: "rgba(0, 158, 197, 0.05)",
                    borderRadius: 10,
                  }}
                >
                  <Image src={Temp6} style={{ borderRadius: 10 }} />
                </Box>
                <br />
                <Text style={{ fontWeight: "bold" }}>Learn & Engage</Text>
                <Text>
                  View educational content shared in your therapy area community
                  group and engage with community members
                </Text>
              </Box>
              <Box display={"flex"} flexDirection={"column"} width={"20%"}>
                <Box
                  style={{
                    backgroundColor: "rgba(0, 158, 197, 0.05)",
                    borderRadius: 10,
                  }}
                >
                  <Image src={Temp5} />
                </Box>
                <br />
                <Text style={{ fontWeight: "bold" }}>Find a Study</Text>
                <Text>
                  Search through our catalog of available studies or choose one
                  recommended to you
                </Text>
              </Box>
            </Box>
          </Box>
        </Container>

        <Container background={"#009EC5"} style={{ padding: 20 }}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Box style={{ width: "50%" }}>
              <Box
                style={{
                  backgroundColor: "rgba(0, 158, 197, 0.05)",
                  borderRadius: 10,
                }}
              >
                <Image src={Temp7} style={{ width: 400, height: 300 }} />
              </Box>
            </Box>
            <Box style={{ width: "50%", textAlign: "left", padding: 20 }}>
              <Text style={{ fontSize: 14, color: "#fff", textAlign: "left" }}>
                {"Community".toUpperCase()}
              </Text>
              <Text
                style={{
                  fontSize: 28,
                  color: "#fff",
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                {"Want To Join Our Community?"}
              </Text>
              <Text style={{ textAlign: "left", color: "#fff" }}>
                Join a therapy area community to engage with content from our
                influencers in that therapy area, learn about new studies in
                that area, and enroll in studies of interest
              </Text>
              <Button
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#fff",
                  color: "#009EC5",
                  width: 150,
                }}
                // onClick={openModal}
                onClick={() => navigate("/patient/feed")}
              >
                Join
              </Button>
            </Box>
          </Box>
        </Container>

        <Container background={"white"}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Box style={{ width: "50%" }}>
              <Box
                style={{
                  backgroundColor: "rgba(0, 158, 197, 0.05)",
                  borderRadius: 10,
                }}
              >
                <Image src={Temp3} style={{ width: 550, height: 400 }} />
              </Box>
            </Box>
            <Box style={{ width: "50%", textAlign: "left", padding: 20 }}>
              <Text
                style={{ fontSize: 12, color: "#009EC5", textAlign: "left" }}
              >
                {"why choose CliniSpan?".toUpperCase()}
              </Text>
              <Text
                style={{ fontSize: 28, textAlign: "left", fontWeight: "600" }}
              >
                {"Why you should choose CliniSpan Health?"}
              </Text>
              <Text style={{ textAlign: "left" }}>
                CliniSpan Health is a digital health platform that connects
                patients with clinical trials that match their health profile.
                We are committed to increasing diversity in clinical trials and
                providing patients with the opportunity to access cutting-edge
                treatments.
              </Text>
              <Button
                style={{ backgroundColor: "#009EC5", borderColor: "#009EC5" }}
                href="/patient/browse-studies"
              >
                Get started
              </Button>
              <Button
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#009EC5",
                  color: "#009EC5",
                  marginLeft: 20,
                }}
                href="https://www.clinispanhealth.com/our-platform/"
              >
                Learn More
              </Button>
            </Box>
          </Box>
        </Container>

        <Container background={"#009EC5"} style={{ padding: 20 }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ color: "white", fontSize: 24, fontWeight: "bold" }}>
              Find Studies for You
            </Text>
            <Button
              style={{
                backgroundColor: "#fff",
                borderColor: "#fff",
                color: "#009EC5",
                marginLeft: 20,
                height: 40,
              }}
              href="/patient/browse-studies"
            >
              All Studies
            </Button>
          </Box>
          <Text style={{ color: "white", fontSize: 11, textAlign: "left" }}>
            Find clinical studies recommended for you based on your conditions
            and geographic location.
          </Text>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 10,
                border: "1px solid #4FBCD7",
                backgroundColor: "#19A8CB",
                margin: 10,
                padding: 10,
              }}
            >
              <Image src={Temp} />
              <Box
                style={{ display: "flex", flexDirection: "column", padding: 5 }}
              >
                <Text style={{ color: "white", fontWeight: "bold" }}>
                  All Studies
                </Text>
                <Text style={{ color: "white" }}>
                  Search through the entire study catalog and filter by location
                  or therapy area.
                </Text>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ color: "white" }}>{"Read More"}</Text>{" "}
                  <East style={{ color: "#fff", margin: 5 }} />
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 10,
                border: "1px solid #4FBCD7",
                backgroundColor: "#19A8CB",
                margin: 10,
                padding: 10,
              }}
            >
              <Image src={Temp2} />
              <Box
                style={{ display: "flex", flexDirection: "column", padding: 5 }}
              >
                <Text style={{ color: "white", fontWeight: "bold" }}>
                  Studies for You
                </Text>
                <Text style={{ color: "white" }}>
                  See your recommended studies based on your profile and
                  interested health conditions.
                </Text>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ color: "white" }}>{"Read More"}</Text>{" "}
                  <East style={{ color: "#fff", margin: 5 }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Dashboard;
