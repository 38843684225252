import {
  client as braintreeClient,
  usBankAccount as braintreeUsBankAccount,
} from "braintree-web";

let braintreeClientInstance;

// Function to load and initialize the Braintree client with a provided token
export const loadBraintree = (clientToken) => {
  return new Promise((resolve, reject) => {
    braintreeClient.create(
      {
        authorization: clientToken,
      },
      (err, clientInstance) => {
        if (err) {
          reject(err);
          return;
        }
        console.log("clientInstance", clientInstance);
        braintreeClientInstance = clientInstance;
        resolve(clientInstance);
      }
    );
  });
};

// Function to tokenize bank account details
export const tokenizeBankDetails = (bankDetails) => {
  console.log("bankDetails", bankDetails);
  return new Promise((resolve, reject) => {
    braintreeUsBankAccount.create(
      {
        client: braintreeClientInstance,
      },
      (err, usBankAccountInstance) => {
        if (err) {
          reject(err);
          return;
        }

        console.log("usBankAccountInstance", usBankAccountInstance);

        usBankAccountInstance.tokenize(
          {
            bankDetails, // Bank account details
            mandateText:
              'By clicking ["Checkout"], I authorize Braintree, a service of PayPal, on behalf of [your business name here] (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.',
          },
          (err, tokenizeResponse) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(tokenizeResponse.nonce); // The nonce can be used for transactions on the server-side
          }
        );
      }
    );
  });
};
