import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import ClinispanLogo from "../assets/logo.png";
import { Roles } from "../constants/user";
import { useUserContext } from "../providers/UserContext";
import { useRownd } from "@rownd/react";

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
  margin-bottom: 50px;
`;

function Landing() {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { is_authenticated,  requestSignIn } = useRownd();

  useEffect(() => {
    if (user) {
      console.log("user", user, user.roles);
      // if (user.roles.includes(Roles.Admin)) {
      //   console.log("admin", user.roles);
      //   navigate("/admin/dashboard");
      //   return;
      // }
      if (user.roles.includes(Roles.Partner)) {
        console.log("partner");
        navigate("/partner/studies");
        return;
      }
      if (user.roles.includes(Roles.DHI)) {
        navigate("/dhi/dashboard");
        return;
      }

      if (user.roles.includes(Roles.Constituent)) {
        console.log("patient");
        navigate("/patient/dashboard");
        return;
      }
    }
  }, [user, navigate]);

  return (
    <div className="App">
      <div className="container" style={{ flexDirection: "column" }}>
        <div style={{ alignSelf: "center" }}>
          <Image src={ClinispanLogo} />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="jumbotron">
              <h1 className="display-4">Welcome to Clinispan Health</h1>
              <p className="lead">Login Below</p>
              <hr className="my-4" />

           {user && is_authenticated ? (<>
             <p className="lead">
                <Button variant="primary" size="lg" href="/dhi/dashboard">
                  DHI
                </Button>
              </p>
              <p className="lead">
                <Button variant="primary" size="lg" href="/patient/dashboard">
                  Patient
                </Button>
              </p></>  ): (
                <p className="lead">
                <Button variant="primary" onClick={() => requestSignIn({
                  auto_sign_in: false,
                })} >
                  Login
                </Button>
              </p>
              ) 
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
