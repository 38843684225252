import { gql, useMutation, useQuery } from "@apollo/client";
import CommentIcon from "@mui/icons-material/Comment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import GeneralContainer from "../components/GeneralContainer";
import { useUserContext } from "../providers/UserContext";
import { formatTimestamp, htmlFrom } from "../utilities/format";
import VideoComponent from "../components/Video";

const GET_POST = gql`
  query GetPost($id: ID!) {
    post(id: $id) {
      id
      title
      content
      media {
        url
        fileType
      }
      author {
        id: _id
        firstName
      }
      community {
        id
        name
      }
      likes
      likedBy {
        id: _id
      }
      comments {
        id: _id
        content
        author {
          id: _id
          firstName
        }
        replies {
          id: _id
          content
          author {
            id: _id
            firstName
          }
        }
      }
    }
  }
`;

const LIKE_POST = gql`
  mutation LikePost($id: ID!) {
    likeCommunityPost(id: $id) {
      id
      title
      content
      author {
        id: _id
        firstName
      }
      community {
        id
        name
      }
      likes
      createdAt
      comments {
        id: _id
        content
        author {
          id: _id
          firstName
        }
        replies {
          id: _id
          content
          author {
            id: _id
            firstName
          }
        }
      }
    }
  }
`;
const COMMENT_POST = gql`
  mutation CommentPost($postId: ID!, $content: String!) {
    createComment(postId: $postId, content: $content) {
      id: _id
      content
      author {
        id: _id
        firstName
      }
      replies {
        id: _id
        content
        author {
          id: _id
          firstName
        }
      }
    }
  }
`;

const PostDetails = () => {
  const { user } = useUserContext();
  const { id: postId, portalPrefix } = useParams();
  const location = useLocation();
  const post = location.state?.post;
  const [comment, setComment] = useState("");
  const { loading, error, data } = useQuery(GET_POST, {
    variables: { id: postId || post.id },
    skip: !user,
  });
  const [postData, setPostData] = useState(post || data.post);
  const [likePost] = useMutation(LIKE_POST, {
    variables: { id: postId },
    refetchQueries: [{ query: GET_POST, variables: { id: postId } }],
  });
  const [commentPost] = useMutation(COMMENT_POST, {
    variables: { postId, content: comment },
    refetchQueries: [{ query: GET_POST, variables: { id: postId } }],
  });

  // if (loading || !user) return <p>Loading...</p>;
  // if (error) return <p>Error :</p>;

  useEffect(() => {
    if (data) {
      setPostData(data.post);
    }
  }, [data]);

  const handleLike = () => {
    // Implement the logic to handle the like/upvote action
    console.log(`Liked post with id: ${post.id}`);
    likePost();
  };

  const handleComment = async () => {
    // Implement the logic to handle the comment action
    if (comment !== "") {
      console.log("testing comment", comment, postId);
      console.log(`Commented on post with id: ${post.id}`, comment);
      const response = await commentPost({
        variables: { postId: post.id, content: comment },
      });
      if (response) {
        setComment("");
      }
    }
  };


  const likedByUser = (likedBy) => {
    return likedBy && likedBy.some((like) => like.id === user._id);
  };

  console.log("POSTID", postId);

  return (
    <GeneralContainer userRole={user?.roles} portal={portalPrefix}>
      <Grid container spacing={10} sx={{ height: "100vh", padding: 12 }}>
        {/* Main content */}
        <Grid item xs={12} sx={{ padding: 2 }}>
          <Paper sx={{ marginBottom: 2, padding: 2 }}>
            <Typography variant="h4">{postData.title}</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: 50,
                  width: 50,
                  borderRadius: 50,
                  marginRight: 2,
                }}
              >
                <img
                  src={
                    postData.author.image || "https://via.placeholder.com/50"
                  }
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 50,
                    objectFit: "cover",
                  }}
                />
              </Box>

              <Typography variant="body2" color="textSecondary">
                by {postData.author.firstName} in {postData.community.name}{" "}
                {postData.createdAt &&
                  " • " + formatTimestamp(postData.createdAt)}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              {htmlFrom(postData.content)}
            </Typography>
            {postData.media && postData.media.fileType === "image" && (
              <Box
                sx={{
                  height: 600,
                  width: "100%",
                  overflow: "hidden",
                  objectFit: "cover",
                }}
              >
                <img
                  src={postData.media.url}
                  alt="Post media"
                  style={{
                    width: "100%",
                    height: "100%",
                    marginTop: 2,
                    borderRadius: 4,
                  }}
                />
              </Box>
            )}
            {postData.media && postData.media.fileType === "video" && (
              <Box
                sx={{
                  height: 600,
                  width: "100%",
                  overflow: "hidden",
                  objectFit: "cover",
                }}
              >
                <VideoComponent
                  media={postData.media}
                  style={{
                    width: "100%",
                    height: "100%",
                    marginTop: 2,
                    borderRadius: 4,
                  }}
                  muted={false}
                />
              </Box>
            )}
          </Paper>

          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <IconButton onClick={handleLike} sx={{ marginLeft: 1,
              color: likedByUser(postData.likedBy) && "dodgerblue"
             }}>
              <ThumbUpIcon />
            </IconButton>
            <Typography variant="body2" color="textSecondary">
              {postData.likes}
            </Typography>
           
            <IconButton onClick={null} sx={{ marginLeft: 1 }}>
              <CommentIcon />
            </IconButton>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginLeft: 2 }}
            >
              {postData.comments.length}
            </Typography>
          </Box>

          <Box sx={{ marginBottom: 2 }}>
            <TextField
              label="Add a comment"
              variant="outlined"
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 1 }}
              onClick={handleComment}
            >
              Comment
            </Button>
          </Box>
          <Box sx={{ marginBottom: 2 }} id="comments">
            {postData.comments.map((comment) => (
              <Paper key={comment.id} sx={{ marginBottom: 2, padding: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: 50,
                      width: 50,
                      borderRadius: 50,
                      marginRight: 2,
                    }}
                  >
                    <img
                      src={
                        comment.author.image || "https://via.placeholder.com/50"
                      }
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 50,
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {comment.author.firstName}
                  </Typography>
                </Box>

                <Typography variant="body1">{comment.content}</Typography>

                {comment.replies.map((reply) => (
                  <Box key={reply.id} sx={{ marginLeft: 2, marginTop: 1 }}>
                    <Typography variant="body2" color="textSecondary">
                      {reply.author.firstName}
                    </Typography>
                    <Typography variant="body1">{reply.content}</Typography>
                  </Box>
                ))}
              </Paper>
            ))}
          </Box>
        </Grid>
      </Grid>
    </GeneralContainer>
  );
};

export default PostDetails;
