import { gql, useMutation, useQuery } from "@apollo/client";
import { Card } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import MediaTabs from "../../components/MediaTabs";
import { useUserContext } from "../../providers/UserContext";
import { Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const SCHEDULE_POST_MUTATION = gql`
  mutation schedulePost(
    $post: String
    $originalDate: String
    $date: String
    $timezoneOffset: Int
    $userTimezone: String
    $platform: Platform
    $campaignId: String
    $file: [FileUpload]
    $commentDisabled: Boolean
    $duetDisabled: Boolean
    $stitchDisabled: Boolean
    $postVisibility: String
  ) {
    schedulePost(
      postInput: {
        post: $post
        originalDate: $originalDate
        date: $date
        timezoneOffset: $timezoneOffset
        userTimezone: $userTimezone
        platform: $platform
        campaignId: $campaignId
        file: $file
        commentDisabled: $commentDisabled
        duetDisabled: $duetDisabled
        stitchDisabled: $stitchDisabled
        postVisibility: $postVisibility
      }
    ) {
      response
    }
  }
`;

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      _id
      name
      url
    }
  }
`;

const GET_FILES_QUERY = gql`
  query GetFiles($portalType: String!) {
    getFiles(portalType: $portalType) {
      _id
      name
      url
      fileType
    }
  }
`;

const CREATOR_INFO = gql`
  query creatorInfo {
    creatorInfo {
      creator_username
      creator_nickname
      creator_avatar_url
      privacy_level_options
      max_video_post_duration_sec
      comment_disabled
      duet_disabled
      stitch_disabled
    }
  }
`;

const Text = styled.p`
  color: #000;
`;

function ActiveCampaigns() {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const item = location.state.item;
  const fileInput = useRef(null);

  //need to discuss asap
  const handleCopyClick = () => {
    const nctNumber = item?.nctNumber;
    //need to modify link for each platform
    const link = `${window.location.origin}/patient/browse-studies/study/${nctNumber}?referral=${user?._id}&campaign=${item?._id}&utm_source=sourceName&utm_medium=mediumName&utm_campaign=campaignName`;
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    // Here you can handle the file upload
  };

  console.log("item", item);

  const openModal = () => {
    // if (user?.dhiOnboardingComplete) {
    //   setOpen(true);
    // } else {
    //   setOpenWarning(true);
    // }
    setOpen(true);
  };

  function WarningModal({ show, handleClose }) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You must complete the onboarding process before scheduling a post.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function SchedulePostModal({ show, handleClose }) {
    const { user, userPrimaryRole, facebookUserAccessToken } = useUserContext();
    const accountType = userPrimaryRole();
    const [page, setPage] = useState(1);
    const [post, setPost] = useState("");
    const [date, setDate] = useState("");
    const [platform, setPlatform] = useState("");
    const [file, setFile] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [openMediaModal, setOpenMediaModal] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [tiktokUserInfo, setTiktokUserInfo] = useState(null);
    const [postVisibility, setPostVisibility] = useState(tiktokUserInfo?.privacy_level_options[0]);
    const [commentDisabled, setCommentDisabled] = useState(false);
    const [duetDisabled, setDuetDisabled] = useState(false);
    const [stitchDisabled, setStitchDisabled] = useState(false);
    const [isDisclosureEnabled, setDisclosureEnabled] = useState(false);
    const [isBrand, setIsBrand] = useState(false);
    const [isBrandedContent, setIsBrandedContent] = useState(false);

    const { data, loading, error, refetch } = useQuery(GET_FILES_QUERY, {
      variables: {
        portalType: accountType,
      },
    });
    const { data: creatorInfoData } = useQuery(CREATOR_INFO, {
      skip: !user || platform !== "Tiktok",
    });

    console.log("file data", data);
    console.log("item", item?.campaignId?.caption);
    console.log("selectedFiles", selectedFiles);
    const fileInputRef = useRef(null);
    const [schedulePost] = useMutation(SCHEDULE_POST_MUTATION, {
      variables: {
        post: item?.campaignId?.caption,
        date,
        platform,
        campaignId: item?._id,
        file: selectedFiles,
        commentDisabled,
        duetDisabled,
        stitchDisabled,
      },
    });

    const getFacebookPages = () => {
      return new Promise((resolve) => {
        window.FB.api(
          "me/accounts",
          { access_token: facebookUserAccessToken },
          (response) => {
            resolve(response.data);
          }
        );
      });
    };

    const getInstagramAccountId = (facebookPageId) => {
      return new Promise((resolve) => {
        window.FB.api(
          facebookPageId,
          {
            access_token: facebookUserAccessToken,
            fields: "instagram_business_account",
          },
          (response) => {
            resolve(response.instagram_business_account.id);
          }
        );
      });
    };

    const createMediaObjectContainer = (instagramAccountId) => {
      return new Promise((resolve) => {
        window.FB.api(
          `${instagramAccountId}/media`,
          "POST",
          {
            access_token: facebookUserAccessToken,
            image_url: selectedFiles[0].url,
            caption: item?.campaignId?.caption,
          },
          (response) => {
            resolve(response.id);
          }
        );
      });
    };

    const publishMediaObjectContainer = (
      instagramAccountId,
      mediaObjectContainerId
    ) => {
      return new Promise((resolve) => {
        window.FB.api(
          `${instagramAccountId}/media_publish`,
          "POST",
          {
            access_token: facebookUserAccessToken,
            creation_id: mediaObjectContainerId,
          },
          (response) => {
            resolve(response.id);
          }
        );
      });
    };

    const shareInstagramPost = async () => {
      // setIsSharingPost(true);
      try {
        const facebookPages = await getFacebookPages();
        const instagramAccountId = await getInstagramAccountId(
          facebookPages[0].id
        );
        const mediaObjectContainerId = await createMediaObjectContainer(
          instagramAccountId
        );

        console.log("fb pages", facebookPages);
        console.log("instagram account id", instagramAccountId);
        console.log("media object container id", mediaObjectContainerId);

        await publishMediaObjectContainer(
          instagramAccountId,
          mediaObjectContainerId
        );
      } catch (error) {
        console.error("Error sharing Instagram post:", error);
      }

      // setIsSharingPost(false);

      // // Reset the form state
      // setImageUrl("");
      // setPostCaption("");
    };

    useEffect(() => {
      if (openMediaModal) {
        refetch();
      }
    }, [openMediaModal, refetch]);
    useEffect(() => {
      if (openMediaModal && selectedTab === 1) {
        refetch();
      }
    }, [openMediaModal, refetch, selectedTab]);

    useEffect(() => {
      let files;
      if(data?.getFiles) {
        if(platform === "Instagram") {
          files = data.getFiles.filter(file => file.fileType === "image" || file.fileType === "video");
        } else if(platform === "Twitter") {
          files = data.getFiles.filter(file => file.fileType === "image" );
        }  else if(platform === "Tiktok") {
          files = data.getFiles.filter(file => file.fileType === "video");
        } else if (platform === "Facebook") {
          files = data.getFiles.filter(file => file.fileType === "image" || file.fileType === "video");
        }
        else {
          files = data.getFiles;
        }
        setFiles({getFiles: files});
      }
    }, [data, platform]);

    useEffect(() => {
      if (creatorInfoData) {
        setTiktokUserInfo(creatorInfoData.creatorInfo);
      }
    }, [creatorInfoData]);
          

    const handleFileCheck = (file) => {
      if (platform === "Tiktok") {
        setSelectedFiles([file]);
        return;
      }
      setSelectedFiles((prev) => {
        console.log("prev", prev); //prev is an object with the following structure, need to have the file upload response return the file model data instead to fix

        // {lastModified
        //   :
        //   1705441420842
        //   lastModifiedDate
        //   :
        //   Tue Jan 16 2024 15:43:40 GMT-0600 (Central Standard Time) {}
        //   name
        //   :
        //   "39305ef3d750.jpg"
        //   size
        //   :
        //   140035
        //   type
        //   :
        //   "image/jpeg"
        //   webkitRelativePath
        //   :
        //   ""}
        prev = prev || []; // Use an empty array if prev is undefined
        return prev.includes(file)
          ? prev.filter((selectedFile) => selectedFile._id !== file._id)
          : [...prev, file];
      });
    };

    const onDrop = (files) => {
      // setSelectedFiles(files);
      // Implement upload logic
    };

    const openFileDialog = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    console.log("user", user, accountType);

    const onFilesAdded = async (evt) => {
      console.log("onFilesAdded");
      const files = evt.target.files;
      console.log("files", files);
      // setSelectedFiles(files);

      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("accountType", accountType);
      formData.append("userId", user?._id);

      console.log("formData", formData);

      try {
        const response = await axios.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("File uploaded successfully:", response.data);

        //fetch latest files and switch tabs
        refetch(); //TODO: check what is returned in the refetch and how it is added to the list of files
        console.log("switching tabs");
        setSelectedTab("select");
        console.log("switched tabs");
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };

    const saveSelection = async (selectedFiles) => {
      // save the selected media to the campaignData object
      console.log("selectedFiles", selectedFiles);
      setFile({
        media: selectedFiles,
      });
      //close modal once done
      setOpenMediaModal(false);
    };

    const handleSubmit = async () => {
      // console.log("Post:", post);
      console.log("Date:", date);
      console.log("Platform:", platform);
      // console.log("File:", file);
      // saveSelection(selectedFiles);
      const localDate = new Date(date);
      const utcDate = new Date(
        localDate.getTime() - localDate.getTimezoneOffset() * 60000
      );
      const utcDateString = utcDate.toISOString();
      console.log("utcDateString", utcDateString);

      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      console.log("userTimezone", userTimezone);

      // if (facebookUserAccessToken && platform === "Instagram") {
      //   shareInstagramPost();
      //   toast.success("Post scheduled successfully");
      //   handleClose();
      //   return;
      // }

      console.log("item before scheduling post", item);

      const res = await schedulePost({
        variables: {
          post: platform === 'Tiktok' && post ? post : item?.campaignId?.caption,
          originalDate: date,
          date: utcDateString,
          timezoneOffset: localDate.getTimezoneOffset(),
          userTimezone,
          platform,
          campaignId: item?.campaignId?.id,
          file: selectedFiles,
          commentDisabled,
          duetDisabled,
          stitchDisabled,
          postVisibility,
        },
      }); //need to check response and display success message or error message
      console.log("res", res);
      if (res.data.schedulePost.response === "Success") {
        toast.success("Post scheduled successfully");
      } else {
        toast.error("Error scheduling post");
      }

      handleClose();
    };

    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
    };

    const handleNextPage = () => {
      setPage(page + 1);
    };

    const handlePreviousPage = () => {
      setPage(page - 1);
    };

    const handleDisclosureToggle = () => {
      setDisclosureEnabled(!isDisclosureEnabled);
    };
  
    const handleBrandToggle = () => {
      setIsBrand(!isBrand);
    };
  
    const handleBrandedContentToggle = () => {
      setIsBrandedContent(!isBrandedContent);
    };

    return (
      <Modal show={show} onHide={handleClose} style={{
        maxHeight: 'calc(100vh - 50px)', // Adjust the height as needed
      }} >
        <Modal.Header closeButton>
          {page && page === 1 && (
            <Modal.Title>Schedule a Social Media Post</Modal.Title>
          )}
          {page && page === 2 && <Modal.Title>Upload Post Media</Modal.Title>}
        </Modal.Header>
        <Modal.Body style={{
               display: 'flex',
               flexDirection: 'column',
               maxHeight: 'calc(80vh - 100px)', // Adjust the height as needed
               overflowY: 'auto', // Enable vertical scrolling
      }}>
          {page && page === 2 && (
            // display the influencer media Library

            <MediaTabs
              onDrop={onDrop}
              openFileDialog={openFileDialog}
              fileInputRef={fileInputRef}
              onFilesAdded={onFilesAdded}
              loading={loading}
              error={error}
              data={files}
              handleFileCheck={handleFileCheck}
              selectedFiles={selectedFiles}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              style={{ width: "100%", marginTop: 2, marginBottom: 2 }}
              platform={platform}
            />
          )}

          {page && page === 1 && (
            <Form>
              {/* <Form.Group>
                <Form.Label>Post</Form.Label>
                <Form.Control
                  type="text"
                  as={"textarea"}
                  placeholder="Enter your post"
                  value={post}
                  onChange={(e) => setPost(e.target.value)}
                />
              </Form.Group> */}
              <Form.Group>
                <Form.Label>Date and Time</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Platform</Form.Label>
                <Form.Control
                  as="select"
                  value={platform}
                  onChange={(e) => setPlatform(e.target.value)}
                >
                  <option value="">Select a platform</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Twitter">Twitter</option>
                  <option value="Tiktok">Tiktok</option>
                </Form.Control>
              </Form.Group>
              {platform === "Tiktok" && (
                <div>
                {/* // display the tiktok user's username so they know which account they are posting to */}
                <p> Posting to {tiktokUserInfo?.creator_username}</p>
                {/* // add the warning message with the link to the page: By posting, you agree to TikTok's Music Usage Confirmation https://www.tiktok.com/legal/page/global/music-usage-confirmation/en */}

                <p>By posting, you agree to TikTok&apos;s <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a></p>

                {/* //provide an input for them to specify the post title */}
                <Form.Group>
                  <Form.Label>Post Title</Form.Label>
                  <Form.Control 
                    type="text"
                    placeholder="Enter your post title"
                    value={post}
                    onChange={(e) => setPost(e.target.value)}
                  />
                </Form.Group>
                {/* // provide a dropdown for the user to specify the privacy status of the post */}
                <Form.Group>
                  <Form.Label>Privacy Status</Form.Label>
                  <Form.Control as="select" 
                    value={postVisibility}
                    onChange={(e) => setPostVisibility(e.target.value)}
                  >
                    {tiktokUserInfo?.privacy_level_options.map((option, index) => (
                      <option key={index}  value={option}>{option}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {/* // add switches to allow the user to toggle the comments, duet, and stitch options */}
                {/* // if one of the options is disabled, display the toggle as disabled */}
                <div>
                  <Form.Check
                    type="switch"
                    id="comment-switch"
                    label="Disable Comments"
                    disabled={tiktokUserInfo?.comment_disabled}
                    onChange={(e) => setCommentDisabled(e.target.checked)}
                    checked={commentDisabled || tiktokUserInfo?.comment_disabled}
                  />
                  <Form.Check
                    type="switch"
                    id="duet-switch"
                    label="Disable Duet"
                    disabled={tiktokUserInfo?.duet_disabled}
                    onChange={(e) => setDuetDisabled(e.target.checked)}
                    checked={duetDisabled || tiktokUserInfo?.duet_disabled}
                  />
                  <Form.Check
                    type="switch"
                    id="stitch-switch"
                    label="Disable Stitch"
                    disabled={tiktokUserInfo?.stitch_disabled}
                    onChange={(e) => setStitchDisabled(e.target.checked)}
                    checked={stitchDisabled || tiktokUserInfo?.stitch_disabled}
                  />

                    {/* add switch for branded content. When only "Your Brand" is checked, the declaration should be the same as mentioned above: "By posting, you agree to TikTok's Music Usage Confirmation."
  When only "Branded Content" is checked, the declaration should be changed to: "By posting, you agree to TikTok's Branded Content Policy and Music Usage Confirmation."
  Additionally, when both options are selected, the declaration should be: "By posting, you agree to TikTok's Branded Content Policy and Music Usage Confirmation."  */}

<h5>Disclose video content</h5>
      
      <Form.Group controlId="disclosureToggle">
        <Form.Check 
          type="switch"
          label="Your video will be labeled 'Promotional content'. This cannot be changed once your video is posted."
          checked={isDisclosureEnabled}
          onChange={handleDisclosureToggle}
        />
      </Form.Group>

      {isDisclosureEnabled && (
        <>
          <p className="text-muted">
            Turn on to disclose that this video promotes goods or services in exchange for something of value. Your video could promote yourself, a third party, or both.
          </p>

          <Form.Group controlId="brandToggle">
            <Form.Check 
              type="checkbox"
              label="Your brand"
              checked={isBrand}
              onChange={handleBrandToggle}
            />
            <Form.Text className="text-muted">
              You are promoting yourself or your own business. This video will be classified as Brand Organic.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="brandedContentToggle">
            <Form.Check 
              type="checkbox"
              label="Branded content"
              checked={isBrandedContent}
              onChange={handleBrandedContentToggle}
            />
            <Form.Text className="text-muted">
              You are promoting another brand or a third party. This video will be classified as Branded Content.
            </Form.Text>
          </Form.Group>

          <Form.Text className="text-muted">
            By posting, you agree to our <a href="https://www.example.com">Music Usage Confirmation</a>.
          </Form.Text>
        </>
      )}
                </div>
                
                </div>
              )}
              {/* <Form.Group>
                <Form.Label>Upload Image/Video</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group> */}
            </Form>
          )}

          {page && page === 3 && (
            <div>
              <h2>Review Post</h2>
              {/* Add a disclaimer that once published it may take a few minutes for content to process and be visible on their profile  */}
              <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#e0f7fa', // Light blue background
        borderRadius: '8px', // Rounded corners
        padding: '8px', // Padding inside the box
        marginTop: '16px', // Margin top for spacing
      }}
    >
      <InfoIcon sx={{ marginRight: '8px', color: '#00796b' }} /> {/* Info icon with margin and color */}
      <Typography variant="body2" color="textSecondary">
        Once published, it may take a few minutes for content to process and be visible on your profile.
      </Typography>
    </Box>
              <p>Post: {post}</p>
              <p>Date: {date}</p>
              <p>Platform: {platform}</p>
             {/* display the file to be used */}
              <p>File: {selectedFiles[0].name}</p>
              <video controls src={selectedFiles[0].url} alt={selectedFiles[0].name} style={{width: '100%', height: 'auto'}} />

            </div>
          )}

        </Modal.Body>
        <Modal.Footer
         style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          {page && page === 1 && (
            <>
              <Button variant="primary" onClick={handleNextPage}>
                Next
              </Button>
            </>
          )}
          {page && page === 2 && (
            <>
              <Button variant="secondary" onClick={handlePreviousPage}>
                Back
              </Button>
            {platform === 'Tiktok' ? (
              <Button variant="primary" onClick={handleNextPage}>
                Next
              </Button>
            ) :(  <Button variant="primary" onClick={handleSubmit}>
                Schedule Post
              </Button>)}
            </>
          )}
          {
            page && page === 3 && (
            <>
              <Button variant="secondary" onClick={handlePreviousPage}>
              Back
            </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Schedule Post
              </Button>
              </>
            )
          }
        </Modal.Footer>
      </Modal>
    );
  }

  const downloadCampaignMaterials = () => {
    // download the campaign materials
    //iterate through the materials array and download the files from each url
    for (let i = 0; i < item.materials.length; i++) {
      const url = item.materials[i].url;
      window.open(url);
    }
  };

  return (
    <div className="App">
      <ToastContainer />
      <SchedulePostModal show={open} handleClose={() => setOpen(false)} />
      <WarningModal
        show={openWarning}
        handleClose={() => setOpenWarning(false)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
        }}
      >
        {/* <h2>{item}</h2> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: 20,
            width: "90%",
          }}
        >
          <Button onClick={downloadCampaignMaterials}>
            Download Campaign Materials
          </Button>
          <Button variant="success" onClick={handleCopyClick}>
            Copy referral link
          </Button>
          <input
            type="file"
            ref={fileInput}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <Button
            onClick={() => {
              openModal();
            }}
          >
            +
          </Button>
        </div>
        <Card style={{ width: "90%" }}>
          <p>
            <b>Campaign Name:</b> {item?.title}
          </p>
          {/* <p>{item.status}</p> */}
          <p>
            <b>Description:</b> {item?.campaignId?.description}
          </p>
          <Text>
            <b>Start Date:</b>
            {item.campaignId?.startDate &&
              new Date(Number(item.campaignId?.startDate))
                .toISOString()
                .slice(0, 10)}
          </Text>
          <Text>
            <b>End Date:</b>
            {item.campaignId?.endDate &&
              new Date(Number(item.campaignId?.endDate))
                .toISOString()
                .slice(0, 10)}
          </Text>
          {/* <p>{item.influencer}</p>
        <p>{item.influencerEmail}</p> */}
          <p>
            <b>Platform:</b> {item?.platforms.join(', ')}
          </p>
          <p>
            <b>Therapy Area:</b> {item?.studyId?.condition.join(', ')}
          </p>
          <p>
            <b>Posting Instructions:</b> {item?.postingInstructions}
          </p>
        </Card>
      </div>
    </div>
  );
}

export default ActiveCampaigns;
