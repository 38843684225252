import { gql, useMutation, useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FormBuilderDropZone from "../../components/FormBuilder";

const SAVE_FORM_MUTATION = gql`
  mutation saveForm($form: FormInput!) {
    saveForm(formInput: $form) {
      name
      createdAt
      updatedAt
      fields {
        label
        type
        required
        options
      }
    }
  }
`;

const UPDATE_FORM_MUTATION = gql`
  mutation updateForm($formId: String, $form: FormInput!) {
    updateForm(formInput: $form, formId: $formId) {
      name
      createdAt
      updatedAt
      fields {
        label
        type
        required
        options
        correctAnswers
      }
    }
  }
`;

const FETCH_FORM_QUERY = gql`
  query getForm($formId: String!) {
    getForm(formId: $formId) {
      name
      createdAt
      updatedAt
      fields {
        label
        type
        required
        options
        correctAnswers
      }
    }
  }
`;

const FormBuilder = () => {
  const { id: studyId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { formId } = location.state;
  const [formFields, setFormFields] = useState([]);
  const [formName, setFormName] = useState("Test Form");
  const [label, setLabel] = useState("");
  const [type, setType] = useState("text");
  const [options, setOptions] = useState([]);
  const [required, setRequired] = useState(false);
  const [form, setForm] = useState({
    name: "",
    fields: [],
  });

  const [saveFormMutation] = useMutation(SAVE_FORM_MUTATION);
  const [updateFormMutation] = useMutation(UPDATE_FORM_MUTATION);
  const { data, loading, error, refetch } = useQuery(FETCH_FORM_QUERY, {
    variables: {
      formId: formId,
    },
  });

  useEffect(() => {
    if (data) {
      const { getForm } = data;
      console.log("getForm", getForm);
      setFormName(getForm.name);
      const fields = getForm.fields.map((field, index) => {
        return {
 id: `field-${index}`,
          label: field.label,
          type: field.type,
          required: field.required,
          options: field.options,
          correctAnswers: field.correctAnswers,
        };
      }
      );
      setFormFields(fields);
    }
  }, [data]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch]);

  const handleAddField = () => {
    setFormFields([
      ...formFields,
      {
        label,
        type,
        required,
        options,
      },
    ]);
    setOptions([]);
    setLabel("");
    setType("text");
    setRequired(false);
  };

  const saveForm = async () => {
    const formFieldsPayload = formFields.map((field) => {
      console.log("field", field);
      return {
        correctAnswers: field.correctAnswers,
        label: field.label,
        type: field.type,
        required: field.required,
        options: field.options,
      };
    });
    if (formId) {
      // update form
      try {
        console.log("formFieldsPayload", formFieldsPayload);
        const form = await updateFormMutation({
          variables: {
            formId,
            form: {
              studyId,
              name: formName,
              fields: formFieldsPayload,
            },
          },
        });

        toast.success("Form updated successfully");
      } catch (error) {
        console.error("error", error);
        toast.error("Error updating form");
      }
    } else {
      // save form to database
      try {
        const form = await saveFormMutation({
          variables: {
            form: {
              studyId,
              name: formName,
              fields: formFieldsPayload,
            },
          },
        });
        toast.success("Form saved successfully");
      } catch (error) {
        console.error("error", error);
        toast.error("Error saving form");
      }
    }
  };

  console.log("formFields", formFields);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowBackIcon
            onClick={goBack}
            style={{ cursor: "pointer", marginRight: "20px" }}
          />
          <h5>Form Builder</h5>
        </div>
        <Button style={{ backgroundColor: "#009EC5" }} onClick={saveForm}>
          Save Form
        </Button>
      </div>
      <FormBuilderDropZone
        formFields={formFields}
        setFormFields={setFormFields}
      />
    </div>
  );
};

export default FormBuilder;
