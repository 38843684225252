import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { PatientHeader } from "../../components/Menus";

const faqData = [
  {
    question: "Why are clinical trials needed?",
    answer:
      "Clinical trials are necessary for the advancement of medical knowledge and patient care. They are designed to evaluate the safety, efficacy, and optimal usage of new medical interventions, which can include drugs, medical devices, surgical procedures, and more. Without clinical trials, we would have no scientifically validated way to determine whether new treatments are safe, what their side effects might be, or how effective they are compared to existing treatments.",
  },
  {
    question: "Who can participate in clinical trials?",
    answer:
      "Eligibility for clinical trials varies based on the specific study. Each trial has inclusion and exclusion criteria that can involve age, gender, the type and stage of a disease, previous treatment history, and other medical conditions. Some studies seek participants with specific illnesses or conditions to be studied, while others require healthy participants. It's always important to discuss with your doctor or healthcare provider to understand if a clinical trial is appropriate for your condition and health status.",
  },
  {
    question:
      "When will I get a chance to go over the details of the clinical study to decide if it's right for me?",
    answer:
      "Before you participate in a clinical trial, you'll take part in a process known as informed consent. During this time, the research team will provide all details about the study, including its purpose, duration, required procedures, and who to contact for further information. They will also explain the risks and potential benefits. You then decide whether or not to sign the consent form based on the information provided. You have the right to take your time, ask questions, and discuss the trial with anyone you choose, including your family and your healthcare provider.",
  },
  {
    question: "How are participants protected?",
    answer:
      "Clinical trial participants are protected through stringent ethical guidelines and regulations that govern these studies. First, informed consent ensures participants are fully aware of the risks and benefits before they agree to participate. Further, an independent ethics committee or institutional review board reviews and monitors the trial to ensure it is ethical and the rights and welfare of participants are protected. Any research involving human subjects must adhere to these guidelines and standards.",
  },
  {
    question: "Can a participant leave a clinical trial after it has started?",
    answer:
      "Yes, a participant can leave a clinical trial at any time and for any reason. If you decide to withdraw, you should inform the study team of your decision and the reasons for leaving. Depending on the study, you may be asked to undergo a final examination or tests for your safety. Also, the decision to leave a study will not affect your access to other treatments and healthcare.",
  },
  {
    question: "What is a placebo?",
    answer:
      "A placebo is a substance that has no therapeutic effect, used as a control in testing new drugs. It is often used in clinical trials to compare the effects of a new treatment against no treatment. Placebos are typically made to look exactly like the treatment drug and can be in the form of a pill, a liquid, or even an injection. The use of placebos allows researchers to see the true effectiveness of a new treatment by separating the actual effects from psychological effects where a participant might feel better simply because they believe they have received a treatment. However, it's important to note that not all clinical trials use placebos.",
  },
];

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="App">
      <PatientHeader title={"Frequently Asked Questions (FAQs)"} />
      <div
        className="App-body"
        style={{
          backgroundColor: "#fff",
          color: "#000",
          margin: "0px",
          display: "block",
        }}
      >
        <div className="faq-page">
          <div style={{ marginLeft: 150, marginRight: 150, padding: 20 }}>
            <p>
              If you have never been in a clinical study, here is some
              additional information. If you have questions not listed, email
              them to{" "}
              <a style={{ color: "#009EC5" }}>info@clinispanhealth.com</a>
            </p>

            <p>
              There are a lot of important things to think about before deciding
              to participate in a trial. The informed consent process will
              address some of the questions, but there may be other items to
              consider. Read on to learn more about what to consider before
              participating in a clinical trial.
            </p>
            <br></br>
            <h3>What is a clinical trial?</h3>
            <p>
              A clinical trial is a research study that is done to find out if
              medical treatments can improve people&apos;s health. A medical
              treatment can be a drug, medical device, medical procedure, or a
              change in a person&apos;s behavior (such as diet or exercise).
            </p>
            <br></br>
            <h3> Will I be compensated or will it cost me money? </h3>
            <p>
              There is NEVER any cost to you in a clinical trial. The study will
              fund doctor visits, labs, medications, and other necessary costs.
              The study also usually pays you directly for time, travel, etc.
            </p>
            <br></br>
            <h3>Why is diversity in clinical trials important?</h3>
            <p>
              Different people may have different reactions to the same
              treatment, based on their age, gender, weight, race, ethnicity,
              and other factors. Clinical trials rely on volunteers to take
              part, and it&apos;s vital that these people come from diverse
              backgrounds. By including people from diverse backgrounds,
              clinical trials can show if the treatments are safe and work well
              for people from different communities.
            </p>
          </div>
          <ul style={{ listStyleType: "none", marginTop: 50 }}>
            {faqData.map((item, index) => (
              <div className="faq-question" key={index}>
                <li>
                  <div
                    style={{
                      padding: 20,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    onClick={() => handleClick(index)}
                  >
                    <span>{item.question}</span>
                    {activeIndex === index ? (
                      <FontAwesomeIcon
                        icon={faChevronUp}
                        style={{
                          alignSelf: "center",
                          fontSize: "20px",
                          color: "#009EC5",
                          marginLeft: 10,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{
                          alignSelf: "center",
                          fontSize: "20px",
                          color: "#009EC5",
                          marginLeft: 10,
                        }}
                      />
                    )}
                  </div>
                  {activeIndex === index && (
                    <div className="faq-answer">{item.answer}</div>
                  )}
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
