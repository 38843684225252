import { gql, useMutation, useQuery } from "@apollo/client";
import { useRownd } from "@rownd/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Roles,
  adminGroupId,
  dhiGroupId,
  partnerGroupId,
  patientGroupId,
} from "../constants/user";

const GET_USER = gql`
  query getUser {
    getMyProfile {
      _id
      firstName
      lastName
      phoneNumber
      email
      zipCode
      platforms
      communities {
        id
      }
      interestedConditions {
        id
        name
      }
      dateOfBirth
      city
      displayName
      userName
      howDidYouHear
      gender
      raceEthnicity
      medications
      rowndId
      dhiAccountType
      avatar
      role
      socialAuth {
        platform
        expiresAt
        verified
        followers
        following
        username
      }
      dhiOnboardingComplete
      onboardingProgress {
        videoWatched
        termsAgreed
        quizPassed
      }
      organizationId {
        _id
        name
        subscriptionStatus
        interactionCount
        subscription {
          tier
          status
        }
      }
    }
  }
`;

//use fragment here? look up downside of fragment use
const UPDATE_USER = gql`
  mutation updateProfile(
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $zipCode: String
    $platforms: [Platform]
    $interestedConditions: [ConditionInput]
    $dateOfBirth: String
    $city: String
    $displayName: String
    $userName: String
    $howDidYouHear: String
    $gender: String
    $raceEthnicity: [String]
    $medications: String
  ) {
    updateProfile(
      userInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        zipCode: $zipCode
        phoneNumber: $phoneNumber
        platforms: $platforms
        interestedConditions: $interestedConditions
        dateOfBirth: $dateOfBirth
        city: $city
        displayName: $displayName
        userName: $userName
        raceEthnicity: $raceEthnicity
        gender: $gender
        medications: $medications
        howDidYouHear: $howDidYouHear
      }
    ) {
      _id
      firstName
      lastName
      phoneNumber
      email
      zipCode
      platforms
      communities {
        id
      }
      interestedConditions {
        id
        name
      }
      dateOfBirth
      city
      displayName
      userName
      howDidYouHear
      gender
      raceEthnicity
      medications
      rowndId
      dhiAccountType
      avatar
      role
      socialAuth {
        platform
        expiresAt
        verified
        followers
        following
        username
      }
      dhiOnboardingComplete
      onboardingProgress {
        videoWatched
        termsAgreed
        quizPassed
      }
      organizationId {
        _id
        name
        subscriptionStatus
      }
    }
  }
`;

const UPDATE_USER_ONBOARDING_PROGRESS = gql`
  mutation UpdateUserOnboardingProgress(
    $videoWatched: Boolean
    $termsAgreed: Boolean
    $quizAnswers: [quizOptions]
  ) {
    updateOnboardingProgress(
      input: {
        onboardingProgress: {
          videoWatched: $videoWatched
          termsAgreed: $termsAgreed
          quizAnswers: $quizAnswers
        }
      }
    ) {
      _id
      dhiOnboardingComplete
      onboardingProgress {
        videoWatched
        termsAgreed
        quizPassed
      }
    }
  }
`;

const JOIN_COMMUNITY = gql`
  mutation joinCommunity($communityId: ID!) {
    joinCommunity(communityId: $communityId) {
      _id
      communities 
    }
  }
`;


export const UserContext = createContext();

export const useUserContext = () => {
  const context = useContext(UserContext);

  console.log("context", context);

  if (context === undefined) {
    throw new Error("useUserContext must be used within UserProvider");
  }

  return context;
};

function rowndGroupsToRoles(groups) {
  if (!groups) {
    return [];
  }

  console.log("groups", groups);

  return groups.reduce(
    (roles, group) => {
      switch (group.group.id) {
        case adminGroupId: {
          roles.push(Roles.Admin);
          break;
        }
        case patientGroupId: {
          roles.push(Roles.Constituent);
          break;
        }
        case dhiGroupId: {
          roles.push(Roles.Dhi);
          break;
        }
        case partnerGroupId: {
          roles.push(Roles.Partner);
          break;
        }
        default: {
          break;
        }
      }
      return roles;
    },
    ["Constituent"]
  );
}

export const UserProvider = ({ children, props }) => {
  const {
    is_authenticated,
    signOut,
    is_initializing,
    user: rowndUser,
  } = useRownd();
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const [instaProfileData, setInstaProfileData] = useState(null);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState(null);

  const { data } = useQuery(GET_USER, {
    skip: !is_authenticated,
  });

  const [saveUser] = useMutation(UPDATE_USER, {
    refetchQueries: [{ query: GET_USER }],
  });

  const [joinCommunity] = useMutation(JOIN_COMMUNITY, {
    refetchQueries: [{ query: GET_USER }],
  });

  const [updateUserOnboardingProgress] = useMutation(
    UPDATE_USER_ONBOARDING_PROGRESS,
    {
      refetchQueries: [{ query: GET_USER }],
    }
  );

  const userPrimaryRole = () => {
    if (!user) {
      return null;
    }
    if (user.roles.includes(Roles.Admin)) {
      return Roles.Admin;
    }
    if (user.roles.includes(Roles.Partner)) {
      return Roles.Partner;
    }
    if (user.roles.includes(Roles.Dhi)) {
      return Roles.Dhi;
    }
    if (user.roles.includes(Roles.Constituent)) {
      return Roles.Constituent;
    }
  };

  console.log("props", props);

  useEffect(() => {
    if (data?.getMyProfile) {
      setUser({
        ...data?.getMyProfile,
        // user.roles can be used client-side for non-critical roles logic. Any roles enforcement
        // that is critical must be done server-side using the authetnicated user's access token
        roles: data?.getMyProfile?.role || rowndGroupsToRoles(rowndUser.groups),
      });
      setUserLoading(false);
    }
  }, [data, rowndUser.groups]);

  useEffect(() => {
    // Sign out user when Rownd is not authenticated
    if (!is_authenticated && !is_initializing) {
      setUser(null);
      setUserLoading(false);
    }
  }, [is_authenticated, is_initializing]);

const SubscriptionLimits = {
    Bronze : { interactions: 15, maxViews: 100000 },
    Silver: { interactions: 25, maxViews: 250000 },
    Gold: { interactions: 40, maxViews: 500000 },
  };

  const validateSubscription = (limits, interactionCount) => {
    if (!limits) {
      return false;
    }

    const { interactions, maxViews } = limits;

    if (interactionCount >= interactions) {
      return false;
    }

    return true;
  }

  const validateSubscriptionLimits = (subscription) => {
    if (!subscription) {
      return false;
    }

    const { tier, status, interactionCount } = subscription;

    if (status === "active") {
      //
      switch(tier) {
        case tier.includes("Bronze"): {
          return validateSubscription(SubscriptionLimits.Bronze, interactionCount);
        }
        case tier.includes("Silver"): {
          return validateSubscription(SubscriptionLimits.Silver, interactionCount);
        }
        case tier.includes("Gold"): {
          return validateSubscription(SubscriptionLimits.Gold, interactionCount);
        }
        default: {
          return false;
        }
      }

  }

  return false;

  }

  const getRemainingInteractions = (subscription = user?.organizationId?.subscription) => {
    console.log("subscription in remaining interactions", subscription);
    if (!subscription) {
      return 0;
    }

    const { tier, status } = subscription;
    const interactionCount = user?.organizationId?.interactionCount;

    console.log("tier", tier, tier.includes("Silver"));

    if (status === "active") {
      //
      if (tier.includes("Bronze")) {
        return SubscriptionLimits.Bronze.interactions - interactionCount;
      } else if (tier.includes("Silver")) {
        console.log("Silver interactionCount", interactionCount);
        return SubscriptionLimits.Silver.interactions - interactionCount;
      } else if (tier.includes("Gold")) {
        return SubscriptionLimits.Gold.interactions - interactionCount;
      } else {
        console.log("default hit");
        return 0;
      }

  }
}
  

  return (
    <UserContext.Provider
      value={{
        user,
        userLoading,
        setUser,
        signOut,
        saveUser,
        userPrimaryRole,
        updateUserOnboardingProgress,
        instaProfileData,
        setInstaProfileData,
        facebookUserAccessToken,
        setFacebookUserAccessToken,
        joinCommunity,
        validateSubscriptionLimits,
        getRemainingInteractions,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
