import React from "react";
import { useUserContext } from "../providers/UserContext";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";

const Layout = ({ children }) => {
  const { user } = useUserContext();

  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <main className="content">
        <Topbar />
        {children}
      </main>
    </div>
  );
};

export default Layout;
