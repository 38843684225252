import { gql, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import StatBox from "../../components/StatBox";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const FETCH_INFLUENCERS_QUERY = gql`
  query GetInfluencers($limit: Int, $offset: Int) {
    influencers(limit: $limit, offset: $offset) {
      id: _id
      firstName
      lastName
      email
      phoneNumber
      followers
    }
  }
`;

function InfluencerPortfolio() {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [influencers, setInfluencers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("firstName");
  const [sortOrder, setSortOrder] = useState("asc");

  const [show, setShow] = useState(false);
  const { loading, error, data, refetch } = useQuery(FETCH_INFLUENCERS_QUERY, {
    variables: {
      limit,
      offset,
      filter: search,
      sortBy,
      sortOrder,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    document.title = "Influencer Portfolio";

    if (data) {
      setInfluencers(data.influencers);
      setTotal(data.influencers.length);
    }
  }, [data]);

  const TempTable = ({ data }) => {
    const columns = [
      {
        headerName: "First Name",
        field: "firstName",
      },
      {
        headerName: "Last Name",
        field: "lastName",
      },
      {
        headerName: "Email",
        field: "email",
      },
      {
        headerName: "Phone",
        field: "phoneNumber",
      },
      {
        headerName: "Followers",
        field: "followers",
      },
    ];
    return (
      <Box>
        <DataGrid
          style={{ height: "100%", width: "95%", margin: "20px" }}
          rows={data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Box>
    );
  };

  return (
    <Box>
      <div className="app">
        <Sidebar
          isSidebar={true}
          name={user?.firstName ? user.firstName : user?.email}
          role={user?.role}
        />
        <main className="content">
          <Topbar />
          <h1 style={{ textAlign: "center" }}>Influencer Portfolio</h1>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="30px"
            marginLeft={20}
          >
            {/* ROW 1 */}
            <Box
              gridColumn="span 3"
              backgroundColor={"#f5f5f5"}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="12,361"
                subtitle="Total Influencers"
                progress="0.75"
                increase="+14%"
                // icon={<EmailIcon sx={{ color: "#3da58a", fontSize: "26px" }} />}
              />
            </Box>
            <Box
              gridColumn="span 3"
              backgroundColor={"#f5f5f5"}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="431,225"
                subtitle="Total Followers"
                progress="0.50"
                increase="+21%"
                // icon={
                //   <PointOfSaleIcon
                //     sx={{ color: "#3da58a", fontSize: "26px" }}
                //   />
                // }
              />
            </Box>
            {/* <Box
              gridColumn="span 3"
              backgroundColor={"#1F2A40"}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="32,441"
                subtitle="New Clients"
                progress="0.30"
                increase="+5%"
                icon={
                  <PersonAddIcon sx={{ color: "#3da58a", fontSize: "26px" }} />
                }
              />
            </Box> */}
            <Box
              gridColumn="span 3"
              backgroundColor={"#f5f5f5"}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="1,325,134"
                subtitle="Total Reach"
                progress="0.80"
                increase="+43%"
                // icon={
                //   <TrafficIcon sx={{ color: "#3da58a", fontSize: "26px" }} />
                // }
              />
            </Box>
          </Box>
          {/* <Button
            variant="primary"
            onClick={() => navigate("/influencer/create-portfolio")}
          >
            Search
          </Button> */}
          <TempTable data={influencers} />
        </main>
      </div>
    </Box>
  );
}

export default InfluencerPortfolio;
