// import { useMutation, gql } from "@apollo/client";
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { PartnerHeader } from "../../components/Menus";
import { useUserContext } from "../../providers/UserContext";
import {
  loadBraintree,
  tokenizeBankDetails,
} from "../../services/braintreeService";

const GET_CLIENT_TOKEN_QUERY = gql`
  query createPaymentToken {
    createPaymentToken
  }
`;

const GET_SUBSCRIPTIONS_QUERY = gql`
  query getSubscriptions {
    fetchSubscriptions {
      id
      name
      price
      planId
      features
      active
    }
  }
`;

const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($tier: String!) {
    createOrder(tier: $tier) {
      id
    }
  }
`;

const PROCESS_SUBSCRIPTION_MUTATION = gql`
  mutation ProcessSubscription(
    $nonce: String!
    $amount: Float
    $planId: String
    $paymentMethod: String
  ) {
    processPayment(
      nonce: $nonce
      amount: $amount
      planId: $planId
      paymentMethod: $paymentMethod
    )
  }
`;

const CHECK_SUBSCRIPTION_QUERY = gql`
  query CheckSubscription {
    verifyOrganizationSubscription
  }
`;

// const subscriptionTiers = [
//   {
//     name: "Bronze",
//     price: "7,500.00",
//     features: [
//       "25 qualified submissions per month",
//       "250,000 impressions per campaign",
//       "Feature 3",
//     ],
//   },
//   {
//     name: "Silver",
//     price: "13,250.00",
//     features: [
//       "50 qualified submissions per month",
//       "500,000 impressions per campaign",
//       "Feature 3",
//       "Feature 4",
//     ],
//   },
//   {
//     name: "Gold",
//     price: "19,500.00",
//     features: [
//       "100 qualified submissions per month",
//       "1,000,000 impressions per campaign",
//       "Feature 3",
//       "Feature 4",
//       "Feature 5",
//     ],
//   },
// ];

const getTierColor = (tierName) => {
  switch (tierName.toLowerCase()) {
    case "bronze":
      return "#a97142";
    case "silver":
      return "#aaa9ad";
    case "gold":
      return "#d4af37";
    default:
      return "#E5E4E2";
  }
};

function AchBillingForm({ bankDetails, setBankDetails }) {
  const [ownershipType, setOwnershipType] = useState("personal");

  const handleChange = (e) => {
    if (e.target.name.includes("billingAddress")) {
      setBankDetails({
        ...bankDetails,
        billingAddress: {
          ...bankDetails.billingAddress,
          [e.target.name.split(".")[1]]: e.target.value,
        },
      });
    } else {
      setBankDetails({
        ...bankDetails,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <form>
      <TextField
        name="routingNumber"
        label="Routing Number"
        variant="outlined"
        fullWidth
        margin="normal"
        value={bankDetails.routingNumber || ""}
        onChange={handleChange}
      />
      <TextField
        name="accountNumber"
        label="Account Number"
        variant="outlined"
        fullWidth
        margin="normal"
        value={bankDetails.accountNumber || ""}
        onChange={handleChange}
      />
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel>Account Type</InputLabel>
        <Select
          name="accountType"
          value={bankDetails.accountType || ""}
          onChange={handleChange}
        >
          <MenuItem value="checking">Checking</MenuItem>
          <MenuItem value="savings">Savings</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel>Ownership Type</InputLabel>
        <Select
          name="ownershipType"
          value={ownershipType}
          onChange={(e) => {
            setOwnershipType(e.target.value);
            handleChange(e);
          }}
        >
          <MenuItem value="personal">Personal</MenuItem>
          <MenuItem value="business">Business</MenuItem>
        </Select>
      </FormControl>
      {ownershipType === "personal" && (
        <>
          <TextField
            name="firstName"
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={bankDetails.firstName || ""}
            onChange={handleChange}
          />
          <TextField
            name="lastName"
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={bankDetails.lastName || ""}
            onChange={handleChange}
          />
        </>
      )}
      {ownershipType === "business" && (
        <TextField
          name="businessName"
          label="Business Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={bankDetails.businessName || ""}
          onChange={handleChange}
        />
      )}
      <TextField
        name="billingAddress.streetAddress"
        label="Street Address"
        variant="outlined"
        fullWidth
        margin="normal"
        value={bankDetails.billingAddress?.streetAddress || ""}
        onChange={handleChange}
      />
      <TextField
        name="billingAddress.extendedAddress"
        label="Extended Address"
        variant="outlined"
        fullWidth
        margin="normal"
        value={bankDetails.billingAddress?.extendedAddress || ""}
        onChange={handleChange}
      />
      <TextField
        name="billingAddress.locality"
        label="City"
        variant="outlined"
        fullWidth
        margin="normal"
        value={bankDetails.billingAddress?.locality || ""}
        onChange={handleChange}
      />
      <TextField
        name="billingAddress.region"
        label="State"
        variant="outlined"
        fullWidth
        margin="normal"
        value={bankDetails.billingAddress?.region || ""}
        onChange={handleChange}
      />
      <TextField
        name="billingAddress.postalCode"
        label="Postal Code"
        variant="outlined"
        fullWidth
        margin="normal"
        value={bankDetails.billingAddress?.postalCode || ""}
        onChange={handleChange}
      />
      {/* <Button type="submit" variant="contained" color="primary" fullWidth>
        Submit
      </Button> */}
    </form>
  );
}

function SubscribedPage({ subscriptionTier, onModify, onCancel }) {
  return (
    <Card style={{ margin: 20 }}>
      <hr />
      <Typography variant="h6" textAlign={"center"}>
        Current Plan
      </Typography>
      <hr />
      <Typography variant="body1" textAlign={"center"}>
        You are now subscribed to our {subscriptionTier}.
      </Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        href="https://billing.stripe.com/p/login/00g4i83usaSLexqaEE"
        style={{ margin: "auto", display: "block", textAlign: "center" }}
      >
        Manage Subscription
      </Button>

      {/* <Button variant="contained" color="primary" onClick={onModify}>
        Modify Subscription
      </Button>
      <Button variant="contained" color="secondary" onClick={onCancel}>
        Cancel Subscription
      </Button> */}
    </Card>
  );
}

const SubscriptionComponent = () => {
  const { user } = useUserContext();

  console.log("user", user);
  const [selectedTier, setSelectedTier] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(""); // 'creditCard' or 'ach'
  const [subscriptionTiers, setSubscriptionTiers] = useState([]);
  const [open, setOpen] = useState(false);
  const [clientToken, setClientToken] = useState(null);
  const [dropInInstance, setDropInInstance] = useState(null);
  const [email, setEmail] = useState(user?.email || "");
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [bankDetails, setBankDetails] = useState({
    routingNumber: "",
    accountNumber: "",
    accountType: "checking",
    ownershipType: "personal",
    firstName: "",
    lastName: "",
    businessName: "",
    billingAddress: {
      streetAddress: "",
      extendedAddress: "",
      locality: "",
      region: "",
      postalCode: "",
    },
  });
  const dropinContainerRef = useRef(null);
  const { data: clientTokenData } = useQuery(GET_CLIENT_TOKEN_QUERY);
  const { data: subscriptionsData } = useQuery(GET_SUBSCRIPTIONS_QUERY);
  const { data: subscriptionStatusData } = useQuery(CHECK_SUBSCRIPTION_QUERY);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [createOrderMutation] = useMutation(PROCESS_SUBSCRIPTION_MUTATION);

  useEffect(() => {
    if (clientTokenData) {
      setClientToken(clientTokenData.createPaymentToken);
      loadBraintree(clientTokenData.createPaymentToken);
    }
  }, [clientTokenData]);

  useEffect(() => {
    if (subscriptionStatusData) {
      if (subscriptionStatusData.verifyOrganizationSubscription) {
        // display subscribed page
        setIsSubscribed(true);
      }
    }
  }, [subscriptionStatusData]);

  useEffect(() => {
    if (subscriptionsData) {
      setSubscriptionTiers(subscriptionsData.fetchSubscriptions);
    }
  }, [subscriptionsData]);

  const createOrder = (nonce, selectedTier) => {
    return createOrderMutation({
      variables: {
        nonce: nonce,
        amount: selectedTier.price,
        planId: selectedTier.planId,
        paymentMethod: paymentMethod,
      },
    }).then((result) => result.data.processPayment);
  };

  const handlePurchase = () => {
    // if (!window.dropinInstance) {
    //   console.error("Drop-in instance is not loaded.");
    //   return;
    // }

    // window.dropinInstance.requestPaymentMethod((error, payload) => {
    //   if (error) {
    //     console.error("Request Payment Method Error:", error);
    //     return;
    //   }
    if (!dropInInstance) {
      console.error("Drop-in instance is not loaded.");
      return;
    }

    // braintree.usBankAccount.create(
    //   {
    //     client: clientToken,
    //   },
    //   function (usBankAccountErr, usBankAccountInstance) {
    //     if (usBankAccountErr) {
    //       console.error(
    //         "There was an error creating the USBankAccount instance."
    //       );
    //       throw usBankAccountErr;
    //     }

    //     var bankDetails = {
    //       accountNumber: $("#account-number").val(),
    //       routingNumber: $("#routing-number").val(),
    //       accountType: $('input[name="account-type"]:checked').val(),
    //       ownershipType: $('input[name="ownership-type"]:checked').val(),
    //       billingAddress: {
    //         streetAddress: $("#billing-street-address").val(),
    //         extendedAddress: $("#billing-extended-address").val(),
    //         locality: $("#billing-locality").val(),
    //         region: $("#billing-region").val(),
    //         postalCode: $("#billing-postal-code").val(),
    //       },
    //     };

    //     if (bankDetails.ownershipType === "personal") {
    //       bankDetails.firstName = $("#first-name").val();
    //       bankDetails.lastName = $("#last-name").val();
    //     } else {
    //       bankDetails.businessName = $("#business-name").val();
    //     }

    //     usBankAccountInstance.tokenize({
    //       bankDetails: bankDetails,
    //       mandateText: 'By clicking ["Checkout"], I authorize Braintree, a service of PayPal, on behalf of [your business name here] (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.'
    //     }, function (tokenizeErr, tokenizedPayload) {
    //       if (tokenizeErr) {
    //         console.error('There was an error tokenizing the bank details.');
    //         throw tokenizeErr;
    //       }
    //     });

    //     // Use the usBankAccountInstance here.
    //     // ...
    //   }
    // );

    dropInInstance.requestPaymentMethod((error, payload) => {
      // Send the nonce to your server
      const { nonce } = payload;

      console.log("nonce", nonce);
      console.log("selectedTier", selectedTier);

      // Send the nonce to your server
      const response = createOrder(nonce, selectedTier);
      console.log("response", response);

      if (response.success) {
        alert("Payment successful!");
      } else {
        alert("Payment failed: " + response.message);
      }
      // fetch("/checkout", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ nonce, amount: "10.00" }), // Example amount
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
      //     if (data.success) {
      //       alert("Payment successful!");
      //     } else {
      //       alert("Payment failed: " + data.message);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error);
      //   });
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBankDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleACHPayment = async (event) => {
    // event.preventDefault();
    try {
      const nonce = await tokenizeBankDetails(bankDetails); // Tokenize bank details
      // Send the nonce to your server for processing
      console.log("nonce", nonce);
      const response = await createOrder(nonce, selectedTier);
      console.log("response", response);

      if (response.success) {
        alert("Payment successful!");
      } else {
        alert("Payment failed: " + response.message);
      }
      // Handle success (e.g., display a success message)
    } catch (error) {
      console.error("Error:", error);
      // Handle errors (e.g., display an error message)
    }
  };

  const selectTier = (tier) => {
    setSelectedTier(tier);
  };

  const handleOpen = () => {
    console.log("open", open);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  console.log("referenceId", user?._id);

  return (
    <div>
      <div>
        <PartnerHeader />
        {/* <Modal show={open} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Subscribe </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField
              label="First Name"
              variant="outlined"
              style={{ marginBottom: 20, marginRight: 20 }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              style={{ marginBottom: 20 }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              label="Email"
              variant="outlined"
              style={{ marginBottom: 20 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <RadioGroup
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <FormControlLabel
                value="creditCard"
                control={<Radio />}
                label="Credit Card"
              />
              <FormControlLabel value="ach" control={<Radio />} label="ACH" />
            </RadioGroup>
            {paymentMethod === "creditCard" && clientToken && (
              <DropIn
                options={{ authorization: clientToken }}
                onInstance={(instance) => {
                  console.log("DropIn instance:", instance);
                  setDropInInstance(instance);
                }}
              />
            )}

            {paymentMethod === "ach" && (
              // Render your ACH form here
              <AchBillingForm
                bankDetails={bankDetails}
                setBankDetails={setBankDetails}
              />
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log("paymentMethod", paymentMethod);
                if (paymentMethod === "creditCard") {
                  handlePurchase();
                }
                if (paymentMethod === "ach") {
                  console.log("handleACHPayment");
                  handleACHPayment();
                }
              }}
            >
              Subscribe
            </Button>
            <Button variant="contained" color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
        {user?.organizationId &&
        user?.organizationId.subscriptionStatus !== "active" ? (
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
            client-reference-id={`${user?._id}`}
          ></stripe-pricing-table>
        ) : user?.organizationId &&
          user?.organizationId.subscriptionStatus === "active" ? (
          <SubscribedPage
            subscriptionTier={user?.organizationId.subscription?.tier}
          />
        ) : null}
        {/* <div className="tier-cards" style={{ marginTop: 20 }}>
          {subscriptionTiers &&
            subscriptionTiers.map((tier, index) => (
              <div
                key={index}
                className={`tier-card ${
                  selectedTier?.name === tier.name ? "selected" : ""
                }`}
                // onClick={() => selectTier(tier)}
                style={{
                  borderColor: getTierColor(tier.name),
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <h3
                  style={{
                    backgroundColor: getTierColor(tier.name),
                  }}
                >
                  {`${tier.name} \n Tier`}
                </h3>
                <hr />
                <p>
                  Price: ${formatNumberNoDecimal(tier.price)}
                  /month
                </p>
                <hr />
                <ul>
                  {tier.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
                <div style={{ marginTop: "auto" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      selectTier(tier);
                      handleOpen();
                    }}
                  >
                    Subscribe
                  </Button>
                </div>
              </div>
            ))}
          <div className="tier-card" style={{ borderColor: "blue" }}>
            <h3 style={{ backgroundColor: "blue" }}>{"Enterprise \n Tier"}</h3>
            <hr />
            <p>Contact us for pricing</p>
            <hr />
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                (window.location.href =
                  "mailto:sales@yourcompany.com?subject=Enterprise Subscription Inquiry")
              }
            >
              Contact Sales
            </Button>
          </div>
        </div> */}

        {/* <PayPalScriptProvider options={{ "client-id": "YOUR_CLIENT_ID" }}>
                <PayPalButtons createOrder={createOrder} onApprove={() => console.log('Payment Successful')} />
            </PayPalScriptProvider> */}
      </div>
    </div>
  );
};

const Billing = () => {
  return (
    <div>
      <SubscriptionComponent />
    </div>
  );
};

export default Billing;
