import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const STUDY_SEARCH_MUTATION = gql`
  mutation GetStudies($nctNumber: String!) {
    studySearch(nctNumber: $nctNumber) {
      title
    }
  }
`;

const STUDY_QUERY = gql`
  query GetStudies($limit: Int, $offset: Int) {
    studies(limit: $limit, offset: $offset) {
      id
      title
      nctNumber
      createdBy {
        _id
        firstName
        lastName
        organizationId {
          _id
          name
        }
      }
    }
  }
`;

const ADD_STUDY_MUTATION = gql`
  mutation AddStudy($nctNumber: String!) {
    addStudy(nctNumber: $nctNumber) {
      title
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px; // Add some margin at the top for spacing
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 60px;
  width: 80%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  margin: 10px auto;
  align-self: center;
  width: 400px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   color: #fff;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Row = styled.div`
  display: flex;
  felx-diretion: row;
  justify-content: center;
`;

const items = [
  {
    name: "Asthma Trial",
    nctNumber: "12341231",
    participants: 10,
    active: true,
  },
  {
    name: "COVID Trial",
    nctNumber: "12341231",
    participants: 20,
    active: false,
  },
  {
    name: "Diabetes Trial",
    nctNumber: "12341231",
    participants: 30,
    active: true,
  },
];

function ListItem({ item }) {
  const navigate = useNavigate();

  return (
    <Card
      title={item.title}
      bordered={true}
      style={{ marginTop: 15, marginBottom: 15, textAlign: "center" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <p>NCT Number: {item.nctNumber}</p>
        <p>Participants: {item.participants || 0}</p>
        <p>Active: {item.active ? "Yes" : "No"}</p>
        {item?.createdBy?.organizationId?.name && (
          <p>
            Organization:{" "}
            {item.createdBy.organizationId.name
              ? item.createdBy.organizationId.name
              : "N/A"}
          </p>
        )}
        <Button variant="info" onClick={() => navigate(`details/${item.id}`)}>
          More Info
        </Button>
      </div>
    </Card>
  );
}

function ConfirmationModal(props) {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleDelete = () => {
    props.handleDelete();
    handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Addition</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this study?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleDelete}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const AdminStudies = () => {
  const { user } = useUserContext();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const navigate = useNavigate();
  const [nctNumber, setNctNumber] = useState("");
  const [searchResult, setSearchResult] = useState();
  const [searchedNctNumber, setSearchedNctNumber] = useState();
  const [show, setShow] = useState(false);
  const [studies, setStudies] = useState([]);
  const [studySearch] = useMutation(STUDY_SEARCH_MUTATION, {
    variables: { nctNumber },
  });

  const [addStudy] = useMutation(ADD_STUDY_MUTATION, {
    variables: { nctNumber: searchedNctNumber },
    refetchQueries: [{ query: STUDY_QUERY }],
  });

  const { loading, error, data } = useQuery(STUDY_QUERY, {
    variables: { limit, offset },
  });

  const onSearchClick = async () => {
    setSearchedNctNumber(nctNumber);
    const searchData = await studySearch();
    setSearchResult(searchData?.data?.studySearch);
  };

  useEffect(() => {
    if (!loading) {
      setStudies(data?.studies);
    }
  }, [offset, limit, loading, data]);

  const handleNextPage = () => {
    setOffset(offset + limit);
  };

  const handlePreviousPage = () => {
    if (offset - limit >= 0) {
      setOffset(offset - limit);
    }
  };

  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <main className="content">
        <Topbar />
        <Box m="20px">
          <div className="App-body outerRow">
            <div className="container">
              <div className="flexColumn">
                <ConfirmationModal
                  handleDelete={() => addStudy().then(() => setSearchResult())}
                  show={show}
                  setShow={setShow}
                />
                <Text className="centerSelf">Study Upload</Text>

                <Container className="centerSelf" background={"lightblue"}>
                  <div
                    className="centerSelf"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignSelf: "center",
                    }}
                  >
                    <Text>NCT# :</Text>
                    <Input
                      type={"text"}
                      value={nctNumber}
                      onChange={(e) => {
                        setNctNumber(e.target.value);
                      }}
                    />
                    <Button
                      title="Search"
                      onClick={onSearchClick}
                      style={{ width: 100 }}
                    >
                      Search
                    </Button>
                  </div>
                </Container>
                <div
                  style={{
                    overflowY: "scroll",
                    minHeight: "500px",
                  }}
                >
                  {!searchResult &&
                    !loading &&
                    studies &&
                    studies.map((item) => (
                      <ListItem key={item.nctNumber} item={item} />
                    ))}

                  {searchResult && (
                    <div
                      style={{
                        border: "1px solid black",
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    >
                      <h2>{searchResult.title}</h2>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <p>NCT Number: {nctNumber}</p>
                        {/* <p>Participants: {item.participants}</p> */}
                        <p>Active: {"No"}</p>
                        <Button
                          variant="success"
                          className="centerSelf"
                          onClick={() => setShow(true)}
                        >
                          Add Study
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <CenteredDiv>
            <Pagination>
              <Pagination.First />
              <Pagination.Prev onClick={handlePreviousPage} />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Ellipsis />

              <Pagination.Item>{20}</Pagination.Item>
              <Pagination.Next onClick={handleNextPage} />
              <Pagination.Last />
            </Pagination>
          </CenteredDiv>
        </Box>
      </main>
    </div>
  );
};

export default AdminStudies;
