import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CampaignBuilder from "../../components/CampaignBuilder";
import { PartnerHeader } from "../../components/Menus";
import InfluencerPortfolio from "../../components/Portfolio";

const GET_CAMPAIGN = gql`
  query GetCampaign($id: String) {
    campaigns(id: $id) {
      _id
      name
      description
      postingInstructions
      targetAudience {
        sex
        ageRange
        raceEthnicity
      }
      startDate
      endDate
    }
  }
`;

const CAMPAIGN_CREATION_REQUEST = gql`
  mutation CreateCampaignRequest(
    $name: String!
    $description: String
    $postingInstructions: String!
    $sex: String!
    $ageRange: String!
    $raceEthnicity: [String]!
    $platforms: [Platform]!
  ) {
    createCampaignRequest(
      campaignRequestInput: {
        name: $name
        description: $description
        postingInstructions: $postingInstructions
        platforms: $platforms
      }
      targetAudienceInput: {
        sex: $sex
        ageRange: $ageRange
        raceEthnicity: $raceEthnicity
      }
    ) {
      _id
      name
      description
      postingInstructions
    }
  }
`;

function ConfigureStudy() {
  const { campaignId } = useParams();
  const { loading, error, data } = useQuery(GET_CAMPAIGN, {
    variables: { id: campaignId },
  });
  const [step, setStep] = useState(1);
  const [campaignData, setCampaignData] = useState({
    sex: [],
    ageRange: [],
    raceEthnicity: [],
    campaignName: "",
    campaignDescription: "",
    platforms: [],
    postingInstructions: "",
    influencers: [],
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    if (data) {
      console.log("campaign data", data);
      setCampaignData({
        ...campaignData,
        campaignName: data.campaigns[0].name,
        campaignDescription: data.campaigns[0].description,
        postingInstructions: data.campaigns[0].postingInstructions,
        startDate: data.campaigns[0].startDate,
        endDate: data.campaigns[0].endDate,
      });
      console.log("data to be set", {
        ...campaignData,
        campaignName: data.campaigns[0].name,
        campaignDescription: data.campaigns[0].description,
        postingInstructions: data.campaigns[0].postingInstructions,
        startDate: data.campaigns[0].startDate,
        endDate: data.campaigns[0].endDate,
      });
    }
  }, [data]);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  return (
    <div>
      <PartnerHeader />
      <CampaignBuilder />
    </div>
  );
}

function CampaignDetails({ nextStep, campaignData, setCampaignData }) {
  const handleInputChange = (event) => {
    setCampaignData({
      ...campaignData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setCampaignData({
        ...campaignData,
        platforms: [...campaignData.platforms, event.target.value],
      });
    } else {
      setCampaignData({
        ...campaignData,
        platforms: campaignData.platforms.filter(
          (platform) => platform !== event.target.value
        ),
      });
    }
  };

  console.log("campaignData", campaignData);
  // Move the form for campaign details here
  // Add a "Next" button that calls nextStep when clicked
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Campaign Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter campaign name"
          onChange={(e) => handleInputChange(e)}
          name="campaignName"
          value={campaignData.campaignName}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Campaign Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter campaign description"
          onChange={(e) => handleInputChange(e)}
          name="campaignDescription"
          value={campaignData.campaignDescription}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label>Platforms</Form.Label>
        <Form.Check
          type="checkbox"
          label="Instagram"
          value="Instagram"
          checked={campaignData.platforms.includes("Instagram")}
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type="checkbox"
          label="Twitter"
          value="Twitter"
          checked={campaignData.platforms.includes("Twitter")}
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type="checkbox"
          label="Facebook"
          value="Facebook"
          checked={campaignData.platforms.includes("Facebook")}
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type="checkbox"
          label="Tiktok"
          value="Tiktok"
          checked={campaignData.platforms.includes("Tiktok")}
          onChange={handleCheckboxChange}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label>Posting Instructions</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter posting instructions"
          onChange={(e) => handleInputChange(e)}
          name="postingInstructions"
          value={campaignData.postingInstructions}
        />
      </Form.Group>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          padding: "20px",
        }}
      >
        <Button variant="primary" type="submit" onClick={nextStep}>
          Next
        </Button>
      </Box>
    </Form>
  );
}

function AdditionalQuestions({
  nextStep,
  prevStep,
  campaignData,
  setCampaignData,
}) {
  const [sex, setSex] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [raceEthnicity, setRaceEthnicity] = useState([]);

  const handleInputChange = (event) => {
    console.log([event.target.name], event.target.value);
    setCampaignData({
      ...campaignData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setCampaignData({
        ...campaignData,
        raceEthnicity: [...campaignData.raceEthnicity, event.target.value],
      });
    } else {
      setCampaignData({
        ...campaignData,
        raceEthnicity: campaignData.raceEthnicity.filter(
          (race) => race !== event.target.value
        ),
      });
    }
  };

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Target Audience Sex</Form.Label>
        <Form.Control
          as="select"
          name="sex"
          value={campaignData.sex}
          onChange={(e) => handleInputChange(e)}
        >
          <option value="">Choose...</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
          <option value="Prefer not to say">Prefer not to say</option>
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Target Audience Age Range</Form.Label>
        <Form.Control
          as="select"
          name="ageRange"
          value={campaignData.ageRange}
          onChange={(e) => handleInputChange(e)}
        >
          <option value="">Choose...</option>
          <option value="Under 18">Under 18</option>
          <option value="18-24">18-24</option>
          <option value="25-34">25-34</option>
          <option value="35-44">35-44</option>
          <option value="45-54">45-54</option>
          <option value="55-64">55-64</option>
          <option value="65 or older">65 or older</option>
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label>Target Audience Race/Ethnicity</Form.Label>
        <Form.Check
          type="checkbox"
          label="White"
          value="White"
          onChange={handleCheckboxChange}
          checked={campaignData.raceEthnicity.includes("White")}
        />
        <Form.Check
          type="checkbox"
          label="Hispanic or Latino"
          value="Hispanic or Latino"
          onChange={handleCheckboxChange}
          checked={campaignData.raceEthnicity.includes("Hispanic or Latino")}
        />
        <Form.Check
          type="checkbox"
          label="Black or African American"
          value="Black or African American"
          onChange={handleCheckboxChange}
          checked={campaignData.raceEthnicity.includes(
            "Black or African American"
          )}
        />
        <Form.Check
          type="checkbox"
          label="Native American or American Indian"
          value="Native American or American Indian"
          onChange={handleCheckboxChange}
          checked={campaignData.raceEthnicity.includes(
            "Native American or American Indian"
          )}
        />
        <Form.Check
          type="checkbox"
          label="Asian / Pacific Islander"
          value="Asian / Pacific Islander"
          onChange={handleCheckboxChange}
          checked={campaignData.raceEthnicity.includes(
            "Asian / Pacific Islander"
          )}
        />
        <Form.Check
          type="checkbox"
          label="Other"
          value="Other"
          onChange={handleCheckboxChange}
          checked={campaignData.raceEthnicity.includes("Other")}
        />
      </Form.Group>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: "20px",
        }}
      >
        <Button variant="secondary" onClick={() => prevStep()}>
          Back
        </Button>
        <Button variant="primary" type="submit" onClick={nextStep}>
          Next
        </Button>
      </Box>
    </Form>
  );
}

function InfluencerSelection({ prevStep, campaignData, setCampaignData }) {
  const [showInfluencerPortfolio, setShowInfluencerPortfolio] = useState(false);
  const [createCampaignRequest] = useMutation(CAMPAIGN_CREATION_REQUEST);
  const navigate = useNavigate();
  // Placeholder array of influencers

  const onSubmit = async (e) => {
    e.preventDefault();
    // setShowInfluencerPortfolio(true);
    console.log("campaignData", campaignData);
    const res = await createCampaignRequest({
      variables: {
        name: campaignData.campaignName,
        description: campaignData.campaignDescription,
        sex: campaignData.sex,
        ageRange: campaignData.ageRange,
        raceEthnicity: campaignData.raceEthnicity,
        platforms: campaignData.platforms,
        postingInstructions: campaignData.postingInstructions,
      },
    });

    console.log("res", res);

    if (res) {
      toast.success("Campaign created successfully");
      navigate(-1);
    } else {
      toast.error("Error creating campaign");
    }
  };

  // Move the code for influencer selection here
  // Add a "Back" button that calls prevStep when clicked

  return (
    <Form>
      <ToastContainer />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form.Label
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "20px",
            marginBottom: "20px",
            alignSelf: "center",
          }}
        >
          Influencer Selection
        </Form.Label>
      </div>
      <InfluencerPortfolio recomendations={[]} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: "20px",
        }}
      >
        <Button variant="secondary" onClick={() => prevStep()}>
          Back
        </Button>
        <Button variant="primary" type="submit" onClick={onSubmit}>
          Submit
        </Button>
      </Box>
    </Form>
  );
}

export default ConfigureStudy;
