import { gql, useQuery, useSubscription } from "@apollo/client";
import { Box } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import StatBox from "../../components/StatBox";
import Topbar from "../../components/Topbar";
import { Roles } from "../../constants/user";
import { useUserContext } from "../../providers/UserContext";

const PATIENT_SIGNUP_QUERY = gql`
  query GetPatientSignups($year: Int!) {
    getPatientSignups(year: $year) {
      data {
        users
        name
        partners
        dhis
      }
      total {
        patients
        partners
        dhis
      }
    }
  }
`;

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription OnNotificationAdded($userId: ID!) {
    notificationAdded(userId: $userId) {
      _id
      title
      message
      read
      createdAt
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 200px;
  width: 90%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  display: block;
  margin: 10px auto;
  align-self: center;
  width: 200px;
  height: 30px;
`;
const Button = styled.button`
  background-color: blue;
  border-radius: 10px;
  height: 50px;
  width: 100px;
  margin-top: 50px;
`;
const Text = styled.p`
  color: #fff;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const StackedBarChart = ({ isDashboard = false, data }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={["users", "partners", "dhis"]}
      indexBy="name"
      margin={{ top: 20, right: 200, bottom: 20, left: 30 }}
      padding={0.5}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "month", // changed
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : "users", // changed
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  );
};

const AdminDashboard = () => {
  const navigate = useNavigate();
  // const { user } = useContext(UserContext);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const { user } = useUserContext();
  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useSubscription(NOTIFICATION_SUBSCRIPTION, {
    variables: { userId: user?._id },
    skip: !user?.id,
  });
  const [textError, setTextError] = useState("");
  const [activeBar, setActiveBar] = useState(null);
  const [patientSignups, setPatientSignups] = useState(null);
  const [userCounts, setUserCounts] = useState(null);
  const {
    loading,
    error,
    data: patientSignupData,
    refetch: refetchPatientSignupData,
  } = useQuery(PATIENT_SIGNUP_QUERY, {
    variables: { year: selectedYear },
  });

  useEffect(() => {
    refetchPatientSignupData({ year: selectedYear });
  }, [selectedYear, refetchPatientSignupData]);

  console.log(
    "subscriptionData",
    subscriptionData,
    subscriptionLoading,
    subscriptionError
  );

  console.log("patientSignupData", patientSignupData, loading);

  useEffect(() => {
    document.title = "Admin Dashboard";
    if (!loading && patientSignupData) {
      console.log("patientSignupData", patientSignupData);
      setPatientSignups(patientSignupData.getPatientSignups.data);
      setUserCounts(patientSignupData.getPatientSignups.total);
    }
  }, [loading]);

  useEffect(() => {
    if (!user) return;
    const roles = user?.roles;
    if (roles && !roles.includes(Roles.Admin)) {
      setTextError("Your role is not an admin");
    }
  }, [user]);

  const handleBarMouseOver = (data, index) => {
    setActiveBar(data);
  };

  const handleBarMouseLeave = () => {
    setActiveBar(null);
  };
  const handleYearChange = (event) => {
    setSelectedYear(Number(event.target.value));
  };

  console.log("selected year", selectedYear);
  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <main className="content">
        <Topbar />
        <Box m="20px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="20px"
          >
            <h1>Dashboard</h1>
            <StyledLink to="/admin/notifications" activeClassName="active">
              Notifications
            </StyledLink>
            <select value={selectedYear} onChange={handleYearChange}>
              <option value={2024}>2024</option>
              <option value={2023}>2023</option>
            </select>
          </Box>
          {textError && (
            <Text style={{ color: "red", textAlign: "center" }}>
              {textError}
            </Text>
          )}
          {patientSignups && (
            <Box height={"50vh"}>
              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="30px"
                marginLeft={20}
              >
                {/* ROW 1 */}
                <Box
                  gridColumn="span 3"
                  backgroundColor={"#f5f5f5"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <StatBox
                    title={userCounts?.dhis}
                    subtitle="Total DHIS"
                    // progress="0.75"
                    // increase="+14%"
                    // icon={<EmailIcon sx={{ color: "#3da58a", fontSize: "26px" }} />}
                  />
                </Box>
                <Box
                  gridColumn="span 3"
                  backgroundColor={"#f5f5f5"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <StatBox
                    title={userCounts?.patients}
                    subtitle="Total Patients"
                    // progress="0.50"
                    // increase="+21%"
                    // icon={
                    //   <PointOfSaleIcon
                    //     sx={{ color: "#3da58a", fontSize: "26px" }}
                    //   />
                    // }
                  />
                </Box>
                {/* <Box
              gridColumn="span 3"
              backgroundColor={"#1F2A40"}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title="32,441"
                subtitle="New Clients"
                progress="0.30"
                increase="+5%"
                icon={
                  <PersonAddIcon sx={{ color: "#3da58a", fontSize: "26px" }} />
                }
              />
            </Box> */}
                <Box
                  gridColumn="span 3"
                  backgroundColor={"#f5f5f5"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <StatBox
                    title={userCounts?.partners}
                    subtitle="Total Partners"
                    // progress="0.80"
                    // increase="+43%"
                    // icon={
                    //   <TrafficIcon sx={{ color: "#3da58a", fontSize: "26px" }} />
                    // }
                  />
                </Box>
              </Box>
              <StackedBarChart data={patientSignups} />
            </Box>
          )}
        </Box>
      </main>
    </div>
  );
};

export default AdminDashboard;
