import React, { useState } from "react";
import { Card } from "react-bootstrap";
import "../styles/FlippableCard.css";

function FlippableCard(props) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`card-container ${isFlipped ? "flipped" : ""}`}
      onClick={props.onClick}
      style={
        props.influencerSelected
          ? {
              border: "2px solid #009EC5",
              borderRadius: 5,
              boxShadow: "0 0 5px #009EC5",
            }
          : {}
      }
    >
      <Card className="card-face card-face-front" style={{ ...props.style }}>
        <Card.Body style={{ padding: 10 }}>
          {/* <Card.Title>Front Title</Card.Title>
          <Card.Text>Front content</Card.Text> */}
          {props.front}
        </Card.Body>
      </Card>
      <Card className="card-face card-face-back" style={{ ...props.style }}>
        <Card.Body style={{ padding: 10 }}>
          {/* <Card.Title>Back Title</Card.Title>
          <Card.Text>Back content</Card.Text> */}
          {props.back}
        </Card.Body>
      </Card>
    </div>
  );
}

export default FlippableCard;
