import { gql, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../providers/UserContext";
// import "react-calendar/dist/Calendar.css";

const MY_CAMPAIGNS_QUERY = gql`
  query GetCampaigns($filter: String) {
    getMyCampaigns(filter: $filter) {
      id: _id
      title
      status
      startDate
      endDate
      description
      influencer
      platforms
      postingInstructions
      materials {
        url
      }
      posts {
        id: _id
        scheduledDate
        content
        platform
        status
      }
    }
  }
`;

// const Box = styled.div`
//   width: 100%;
//   padding: 40px;
// `;

// create a schedule page that allows the user to add events to their calendar
// the user should be able to add events to their calendar
// the user should be able to view their calendar
// the calendar should display the weekly view by default

const Schedule = () => {
  const { user } = useUserContext();
  const [value, onChange] = useState(new Date());
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(MY_CAMPAIGNS_QUERY, {
    variables: { filter: "Active" },
    skip: !user,
  });
  const calendarRef = useRef();
  const [calendarState, setCalendarState] = useState({
    viewType: "Week",
    durationBarVisible: false,
    timeRangeSelectedHandling: "Enabled",
    eventDeleteHandling: "Update",
  });
  const [currentEvents, setCurrentEvents] = useState([]);

  function getRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  }

  function getColorByStatus(status) {
    switch (status) {
      case "Scheduled":
        return "rgb(255, 255, 0)"; // yellow
      case "Published":
        return "rgb(0, 255, 0)"; // green
      case "Archived":
        return "rgb(255, 0, 0)"; // red
      default:
        return "rgb(255, 255, 255)"; // white
    }
  }

  const getPlatformIcon = (platformName) => {
    switch (platformName) {
      case "Facebook":
        return (
          <FontAwesomeIcon
            icon={["fab", "facebook"]}
            style={{ alignSelf: "center", fontSize: "25px", color: "#3b5998" }}
          />
        );
      case "Instagram":
        return (
          <FontAwesomeIcon
            icon={["fab", "instagram"]}
            style={{ alignSelf: "center", fontSize: "25px", color: "#d6249f" }}
          />
        );
      case "Twitter":
        return (
          <FontAwesomeIcon
            icon={["fab", "twitter"]}
            style={{ alignSelf: "center", fontSize: "25px", color: "#1DA1F2" }}
          />
        );
      case "Tiktok":
        return (
          <FontAwesomeIcon
            icon={["fab", "tiktok"]}
            style={{ alignSelf: "center", fontSize: "25px", color: "#000" }}
          />
        );
      default:
        return (
          <FontAwesomeIcon
            icon={["fab", "facebook"]}
            style={{ alignSelf: "center", fontSize: "25px", color: "#000" }}
          />
        );
    }
  };

  useEffect(() => {
    if (!loading && !error) {
      const now = new Date();

      // calendarRef.current.control.update({ today, events: events2 });
      if (data) {
        const events = data.getMyCampaigns.flatMap((campaign) => {
          // Create an event for the campaign
          const campaignEvent = {
            id: campaign.id,
            title: campaign.title,
            date: new Date(Number(campaign.startDate)),
            end: new Date(Number(campaign.endDate)),
            allDay: true,
            editable: false,
          };

          console.log("campaign", campaign);  

          // Create an event for each post in the campaign
          const postEvents = campaign.posts.map((post) => {
            const postDate = new Date(Number(post.scheduledDate));
            const platformIcon = getPlatformIcon(post.platform);
            return {
              id: post.id,
              title: `Post to ${post.platform}`,
              icon: platformIcon,
              date: postDate,
              end: new Date(postDate.getTime() + 15 * 60 * 1000), // Add 5 minutes to the post date
              allDay: false,
              color: getColorByStatus(post.status),
              editable: false,
            };
          });

          return [campaignEvent, ...postEvents];
        });

        console.log("events", events);

        setCurrentEvents(events);
      }
    }
  }, [loading, error, data]);

  const handleDateClick = (selected) => {
    return;
    // const title = prompt("Please enter a new title for your event");
    // const calendarApi = selected.view.calendar;
    // calendarApi.unselect();

    // if (title) {
    //   calendarApi.addEvent({
    //     id: `${selected.dateStr}-${title}`,
    //     title,
    //     start: selected.startStr,
    //     end: selected.endStr,
    //     allDay: selected.allDay,
    //   });
    // }
  };

  const addTestEvents = () => {
    const events = [
      {
        id: "1",
        title: "All-day event",
        start: "2021-09-14",
        end: "2021-09-15",
      },
      {
        id: "2",
        title: "Timed event",
        start: "2021-09-28",
        end: "2021-09-29",
      },
    ];
    calendarRef.current.control.update({ events });
  };

  const handleEventClick = (selected) => {
    console.log("selected", selected);
    //if clicking on a post, display post details to user on calendar
    if (selected.event.title.includes("Post to")) {
      console.log("selected", selected);
    }

    //if clicking on a campaign, navigate to the campaign details page
    navigate(
      `/dhi/performance-dashboard`
    );

    // navigate(
    //   `/dhi/performance-dashboard/campaign-details/${selected.event.id}`
    // );

    // if (
    //   window.confirm(
    //     `Are you sure you want to delete the event '${selected.event.title}'`
    //   )
    // ) {
    //   selected.event.remove();
    // }
  };

  console.log("currentEvents", currentEvents);

  return (
    <div className="App">
      <div
        className="App-body"
        style={{
          display: "flex",
          backgroundColor: "#fff",
          color: "#000",
          margin: "0px",
          flexDirection: "row",
        }}
      >
        {/* <div
          style={{
            marginTop: 10,
            width: 150,
            paddingRight: 10,
            marginRight: 40,
            marginLeft: 20,
          }}
        >
          <DayPilotNavigator
            selectMode={"week"}
            showMonths={2}
            skipMonths={1}
            onTimeRangeSelected={(args) => {
              calendarRef.current.control.update({
                startDate: args.day,
              });
            }}
          />
        </div>
        <Box>
          <DayPilotCalendar
            ref={calendarRef}
            {...calendarState}
            onEventClick={null}
            onEventMoved={null}
            onBeforeEventRender={(args) => {
              args.data.moveDisabled = true;
              args.data.resizeDisabled = true;
            }}
          />
        </Box> */}
        <Box flex="1 1 100%" ml="15px" mr="15px" mt={10} padding={5}>
          <FullCalendar
            height="100vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable={false}
            selectable={false}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            // eventsSet={(events) => setCurrentEvents(events)}
            events={currentEvents}
            // initialEvents={[
            //   {
            //     id: "1",
            //     title: "Eczema Study Campaign ",
            //     date: "2023-12-14",
            //     end: "2023-12-25",
            //   },
            //   {
            //     id: "2",
            //     title: "Asthma Study Campaign",
            //     date: "2023-12-28",
            //     end: "2023-12-31",
            //   },
            // ]}
          />
          {/* <FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" /> */}
        </Box>
      </div>
    </div>
  );
};

export default Schedule;
