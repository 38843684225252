import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../../App.css";
import studyImg from "../../assets/study.png";
import { PatientHeader } from "../../components/Menus";
import { useUserContext } from "../../providers/UserContext";
import { truncateText } from "../../utilities/format";
import { Box } from "@mui/material";

const Container = styled.div`
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
    display: flex;
  flex-direction: row;
`;

const GET_MY_STUDIES = gql`
  query GetMyStudies($filter: String, $sortBy: String) {
    studiesForYou(filter: $filter, sortBy: $sortBy) {
      title
      formId {
        id
        name
      }
      nctNumber
      condition
      intervention
      location {
        facility
        city
        state
        country
      }
      description
      startDate
      studyType
      eligibilityCriteria
    }
  }
`;
// Dummy data
const studyData = [
  { name: "Study 1", location: "Location 1", imgSrc: studyImg },
  { name: "Study 2", location: "Location 2", imgSrc: studyImg },
  { name: "Study 3", location: "Location 1", imgSrc: studyImg },
  // add more studies...
];

function StudiesForYou() {
  const { user } = useUserContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [studies, setStudies] = useState([]);
  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState("");

  const { loading, error, data } = useQuery(GET_MY_STUDIES, {
    variables: { filter, sortBy },
    skip: !user,
  });

  const filteredStudies = studyData.filter((study) =>
    study.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      setStudies(data?.studiesForYou);
    }
  }, [loading]);

  console.log(studies);

  return (
    <div className="App">
      <PatientHeader title={"Studies For You"} />
      <div
        style={{
          backgroundColor: "#fff",
          minHeight: "90vh",
          paddingTop: 10,
          marginRight: 50,
          marginLeft: 50,
        }}
      >
        <FloatingLabel
          controlId="floatingInput"
          label="Filter by location"
          className="mb-3"
          style={{ width: "70%", margin: "10px auto" }}
          onChange={(e) => setFilter(e.target.value)}
        >
          <Form.Control placeholder="" />
        </FloatingLabel>

        {/* <div className="search-bar">
        <input
          type="text"
          placeholder="Filter by location..."
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}
        <div className="study-grid">
          {studies &&
            studies.map((study, index) => (
              <Container
                key={index}
                className="study-card"
                onClick={() => {
                  navigate("study/" + study.nctNumber, {
                    state: { study: study },
                  });
                }}
              >
                 <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                }}
              >
                <img src={studyImg} alt={study.title} />
                </Box>
                <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                  padding: 10,
                  justifyContent: "center",
                  textAlign: "left",
                  
                }}
              >
                <h6 style={{color: "#032E5E"}}>{study.title}</h6>
                <p style={{color: "#032E5E"}}>{`${study?.location?.city || ""}, ${
                  study?.location?.state || study?.location?.country || ""
                }`}</p>
                <p style={{color: "#032E5E"}}>{truncateText(study.description, 250)}</p>
                {/* <p>{"Learn More ->"}</p> */}
              </Box>
              </Container>
            ))}
          {studies && !studies[0] && (
            <>
              <p>Study Match Failed</p>
              <a onClick={() => navigate("/patient/manage-profile")}>
                <p>Go to your profile to update your preferences</p>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default StudiesForYou;
