import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const CONDITIONS_QUERY = gql`
  query GetConditions(
    $limit: Int
    $offset: Int
    $filter: String
    $sortBy: String
  ) {
    conditions(
      filter: $filter
      sortBy: $sortBy
      limit: $limit
      offset: $offset
    ) {
      name
      active
      id
    }
  }
`;

const SAVE_CONDITION_MUTATION = gql`
  mutation SaveCondition($name: String!) {
    saveCondition(name: $name) {
      id
      name
    }
  }
`;

const UPDATE_CONDITION_MUTATION = gql`
  mutation UpdateCondition($id: ID!, $active: Boolean!) {
    updateCondition(id: $id, active: $active) {
      id
      name
    }
  }
`;

const DELETE_CONDITION_MUTATION = gql`
  mutation DeleteCondition($id: ID!) {
    deleteCondition(id: $id)
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px; // Add some margin at the top for spacing
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 60px;
  width: 80%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  margin: 10px auto;
  align-self: center;
  width: 400px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   color: #fff;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Row = styled.div`
  display: flex;
  felx-diretion: row;
  justify-content: center;
`;

const items = [
  {
    name: "Asthma Trial",
    nctNumber: "12341231",
    participants: 10,
    active: true,
  },
  {
    name: "COVID Trial",
    nctNumber: "12341231",
    participants: 20,
    active: false,
  },
  {
    name: "Diabetes Trial",
    nctNumber: "12341231",
    participants: 30,
    active: true,
  },
];

function ListItem({
  item,
  updateCondition,
  deleteCondition,
  setActive,
  setId,
}) {
  const navigate = useNavigate();

  return (
    <Card
      title={item.name}
      bordered={true}
      style={{ marginTop: 15, marginBottom: 15, textAlign: "center" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          variant="secondary"
          style={{ alignSelf: "center", marginBottom: 10 }}
          onClick={() => {
            setId(item.id);
            setActive(!item.active);
            updateCondition();
          }}
        >
          {item?.active ? "Enabled" : "Disabled"}
        </Button>
        <Button
          variant="danger"
          style={{ alignSelf: "center", marginBottom: 10, marginLeft: 20 }}
          onClick={() => {
            setId(item.id);
            deleteCondition();
          }}
        >
          {"Delete"}
        </Button>
      </div>
    </Card>
  );
}

function DeleteConfirmationModal(props) {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleDelete = () => {
    props.handleDelete();
    handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this data?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Conditions = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [active, setActive] = useState(false);
  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);

  const { loading, error, data } = useQuery(CONDITIONS_QUERY, {
    variables: { filter, sortBy, limit, offset },
  });

  const [conditions, setConditions] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (data) {
      setConditions(data?.conditions);
    }
  }, [data, loading]);

  const [addCondition] = useMutation(SAVE_CONDITION_MUTATION, {
    refetchQueries: [{ query: CONDITIONS_QUERY }],
    variables: { name },
  });

  const [updateCondition] = useMutation(UPDATE_CONDITION_MUTATION, {
    refetchQueries: [{ query: CONDITIONS_QUERY }],
    variables: { id, active },
  });

  const [deleteCondition] = useMutation(DELETE_CONDITION_MUTATION, {
    refetchQueries: [{ query: CONDITIONS_QUERY }],
    variables: { id },
  });

  const onSearchClick = async () => {
    const result = await addCondition();
  };

  const onFilterClick = async () => {};

  const onDeleteClick = async () => {
    const result = await deleteCondition();
  };

  const handleNextPage = () => {
    setOffset(offset + limit);
  };

  const handlePreviousPage = () => {
    if (offset - limit >= 0) {
      setOffset(offset - limit);
    }
  };

  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <main className="content">
        <Topbar />
        <Box m="20px">
          <div className="App-body outerRow">
            <div className="container">
              <div className="flexColumn">
                <Text className="centerSelf">Conditions</Text>
                <Container className="centerSelf" background={"lightblue"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignSelf: "center",
                    }}
                  >
                    <Text>Filter :</Text>
                    <Input
                      type={"text"}
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                    />
                    <Button
                      title="Search"
                      variant="primary"
                      onClick={onFilterClick}
                      style={{ width: 100 }}
                    >
                      Search
                    </Button>
                  </div>
                </Container>
                <Container className="centerSelf" background={"lightblue"}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignSelf: "center",
                    }}
                  >
                    <Text>Create :</Text>
                    <Input
                      type={"text"}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                    <Button
                      title="Search"
                      onClick={onSearchClick}
                      style={{ width: 100 }}
                    >
                      Add
                    </Button>
                  </div>
                </Container>

                <DeleteConfirmationModal
                  handleDelete={deleteCondition}
                  show={show}
                  setShow={setShow}
                />
                <div
                  style={{
                    overflowY: "scroll",
                    minHeight: 500,
                  }}
                >
                  {conditions &&
                    conditions.map((item) => (
                      <ListItem
                        key={item.number}
                        item={item}
                        setActive={setActive}
                        setId={setId}
                        updateCondition={updateCondition}
                        deleteCondition={() => setShow(true)}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          <CenteredDiv>
            <Pagination>
              <Pagination.First />
              <Pagination.Prev onClick={handlePreviousPage} />
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Ellipsis />

              <Pagination.Item>{20}</Pagination.Item>
              <Pagination.Next onClick={handleNextPage} />
              <Pagination.Last />
            </Pagination>
          </CenteredDiv>
        </Box>
      </main>
    </div>
  );
};

export default Conditions;
