import React, { useRef, useEffect } from 'react';

const VideoComponent = ({ media, muted = true }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (videoRef.current) {
            videoRef.current.play();
            }
          } else {
            if (videoRef.current) {
            videoRef.current.pause();
            }
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <video
    controlsList="nodownload noplaybackrate noremoteplayback" 
    disablePictureInPicture
      ref={videoRef}
      src={media.url}
      controls
      style={{
        width: "100%",
        height: "100%",
        marginTop: 2,
        borderRadius: 4,
      }}
      muted={muted}
      onContextMenu={(e) => e.preventDefault()} // Disable right-click menu
    />
  );
};

export default VideoComponent;