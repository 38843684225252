import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Skeleton } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import CliniSpanLogo from "../assets/logo.png";
import { Roles } from "../constants/user";
import { useUserContext } from "../providers/UserContext";

const StyledAdminLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;

  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &.active {
    color: black;
    font-weight: bold;
  }
  &:hover {
    color: lightblue;
  }
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;

  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &.active {
    color: grey;
    font-weight: bold;
  }
  &:hover {
    color: lightblue;
  }
`;

const StyledPatientLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #000;
  text-decoration: none;
  font-weight: bold;

  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &.active {
    color: #009ec5;
    position: relative;
  }
  &.active::after {
    content: "";
    display: block;
    width: 50%; /* Adjust the width as needed */
    height: 2px; /* Adjust the height as needed */
    background-color: #009ec5;
    position: absolute;
    bottom: -2px; /* Adjust the position as needed */
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    color: grey;
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

export const AdminMenu = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledPatientLink
        to={"/admin/dashboard"}
        className={"navLink"}
        style={({ isActive }) => {
          return {
            color: isActive ? "orange" : "green",
          };
        }}
        activeclassname="linkActive"
        activestyle={{
          color: "orange",
        }}
      >
        {"Platform Signup Data"}
      </StyledPatientLink>
      <StyledPatientLink className={"navLink"} to={"/admin/volunteer-data"}>
        {"Volunteer Data"}
      </StyledPatientLink>
      <StyledPatientLink className={"navLink"} to={"/admin/feed"}>
        {"TA Communities"}
      </StyledPatientLink>
      {/* <StyledLink to={"/database-search"}>{"Database Search"}</StyledLink> */}
      <StyledPatientLink
        className={"navLink"}
        to={"/admin/recruitment-partners"}
      >
        {"Recruitment Partners"}
      </StyledPatientLink>
      <StyledPatientLink className={"navLink"} to={"/admin/current-studies"}>
        {"Current Studies"}
      </StyledPatientLink>
      <StyledPatientLink className={"navLink"} to={"/admin/conditions"}>
        {"Conditions"}
      </StyledPatientLink>
      <StyledPatientLink className={"navLink"} to={"/admin/add-users"}>
        {"Add Users"}
      </StyledPatientLink>

      {/* <StyledLink to={"/campaigndashboard"}>{"Campaign Dashboard"}</StyledLink> */}
      <StyledPatientLink className={"navLink"} to={"/admin/managecampaigns"}>
        {"Manage Campaigns"}
      </StyledPatientLink>
    </>
  );
};

export const PartnerMenu = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <StyledPatientLink className={"navLink"} to={"/partner/study-upload"}>
        {"Study Upload"}
      </StyledPatientLink> */}
      <StyledPatientLink className={"navLink"} to={"/partner/studies"}>
        {"Studies"}
      </StyledPatientLink>
      <StyledPatientLink className={"navLink"} to={"/partner/feed"}>
        {"Communities"}
      </StyledPatientLink>
      {/* <StyledPatientLink
        className={"navLink"}
        to={"/partner/influencer-portfolio"}
      >
        {"Influencer Portfolio"}
      </StyledPatientLink> */}
      <StyledPatientLink className={"navLink"} to={"/partner/organization"}>
        {"Organization"}
      </StyledPatientLink>
      {/* <StyledPatientLink className={"navLink"} to={"/partner-outreach"}>
        {"Outreach"}
      </StyledPatientLink> */}
    </>
  );
};

export const DHIMenu = () => {
  return (
    <>
      <StyledPatientLink
        to={"/dhi/dashboard"}
        style={({ isActive }) =>
          isActive
            ? {
                color: "blue",
              }
            : {
                color: "blue",
              }
        }
        activeStyle={{
          color: "blue",
        }}
      >
        {"Home"}
      </StyledPatientLink>
      <StyledPatientLink to={"/dhi/feed"}>{"Communities"}</StyledPatientLink>
      <StyledPatientLink to={"/dhi/mycampaigns"}>
        {"My Campaigns"}
      </StyledPatientLink>
      <StyledPatientLink to={"/dhi/performance-dashboard"}>
        {"Performance Dashboard"}
      </StyledPatientLink>
      <StyledPatientLink to={"/dhi/schedule"}>
        {"Content Schedule"}
      </StyledPatientLink>
      <StyledPatientLink to={"/dhi/resources"}>
        {"DHI Resources"}
      </StyledPatientLink>
    </>
  );
};

export const PatientMenu = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledPatientLink
        to={"/patient/dashboard"}
        style={({ isActive }) =>
          isActive
            ? {
                color: "#009EC5",
              }
            : {
                color: "#009EC5",
              }
        }
        activeStyle={{
          color: "#009EC5",
        }}
      >
        {"Home"}
      </StyledPatientLink>
      <StyledPatientLink to={"/patient/feed"}>
        {"Communities"}
      </StyledPatientLink>
      <StyledPatientLink to={"/patient/studies-for-you"}>
        {"Studies For You"}
      </StyledPatientLink>
      <StyledPatientLink to={"/patient/browse-studies"}>
        {"Browse Studies"}
      </StyledPatientLink>
      <StyledPatientLink to={"/patient/faqs"}>{"FAQ"}</StyledPatientLink>
    </>
  );
};

export const TopbarSkeleton = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      borderBottom={2}
      borderColor={"#e0e0e0"}
    >
      <Box display="flex" borderRadius="3px">
        <Image src={CliniSpanLogo} alt="Logo" />
      </Box>

      <Box display="flex">
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="circle" width={40} height={40} />
      </Box>
    </Box>
  );
};

export const SidebarSkeleton = () => {
  return (
    <Box
      sx={{
        width: 240,
        height: "100vh",
        backgroundColor: "#009EC5",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Skeleton variant="circle" width={40} height={40} />
      <Skeleton
        variant="rectangular"
        width={100}
        height={100}
        style={{ borderRadius: 50, alignSelf: "center" }}
      />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
      <Skeleton variant="text" width={210} />
    </Box>
  );
};

export const SkeletonHeader = () => {
  return (
    <>
      <header className="App-header">
        <Image src={CliniSpanLogo} alt="Logo" />
      </header>
      <nav className="nav-header">
        <StyledLink to={"/"}>{"Home"}</StyledLink>
        <StyledLink to={"/about"}>{"About"}</StyledLink>
        <StyledLink to={"/contact"}>{"Contact"}</StyledLink>
      </nav>
    </>
  );
};

export const PartnerHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, signOut, userPrimaryRole } = useUserContext();
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <nav className="nav-header" style={{ marginBottom: 20 }}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" marginLeft={5}>
            <Image
              src={CliniSpanLogo}
              alt="Logo"
              style={{ height: 50, width: isMobile ? 100 : 150 }}
            />
          </Box>
          <Box display="flex" marginRight={5} alignItems={"center"}>
            {isMobile ? (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleClick}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : (
              <>
                <PartnerMenu />
                <AccountCircleIcon
                  className="icon"
                  onClick={handleClick}
                  style={{ color: "#009EC5" }}
                />
              </>
            )}
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem focusVisibleClassName="test">
                {user?.firstName ? user.firstName : user?.email}
                {`(${userPrimaryRole()})`}
              </MenuItem>
              {user?.roles &&
                user?.roles?.length > 1 &&
                user.roles.map((role) => (
                  <MenuItem
                    key={role}
                    onClick={() => {
                      navigate(
                        role.toLowerCase() === "partner"
                          ? "/partner/studies"
                          : role.toLowerCase() === "constituent"
                          ? "/patient/dashboard"
                          : `/${role.toLowerCase()}/dashboard`
                      );
                      handleClose();
                    }}
                  >
                    Switch to {role === Roles.Constituent ? "Patient" : role}{" "}
                    Portal
                  </MenuItem>
                ))}
              <MenuItem
                onClick={() => {
                  navigate("/partner/billing");
                }}
              >
                Billing
              </MenuItem>
              <MenuItem
                onClick={() => {
                  signOut();
                  navigate("/");
                  handleClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </nav>
    </>
  );
};

export const PatientHeader = ({ title }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, signOut, userPrimaryRole } = useUserContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <nav className="nav-header">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" marginLeft={5}>
            <Image
              src={CliniSpanLogo}
              alt="Logo"
              style={{ height: 50, width: 150 }}
            />
          </Box>
          <Box display="flex" marginRight={5} alignItems={"center"}>
            <PatientMenu />
            <AccountCircleIcon
              className="icon"
              onClick={handleClick}
              style={{ color: "#009EC5" }}
            />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem focusVisibleClassName="test">
                {user?.firstName ? user.firstName : user?.email}
                {`(${userPrimaryRole()})`}
              </MenuItem>
              {user?.roles &&
                user?.roles?.length > 1 &&
                user.roles.map((role) => (
                  <MenuItem
                    key={role}
                    onClick={() => {
                      navigate(
                        role.toLowerCase() === "partner"
                          ? "/partner/studies"
                          : role.toLowerCase() === "constituent"
                          ? "/patient/dashboard"
                          : `/${role.toLowerCase()}/dashboard`
                      );
                      handleClose();
                    }}
                  >
                    Switch to {role === Roles.Constituent ? "Patient" : role}{" "}
                    Portal
                  </MenuItem>
                ))}
              <MenuItem
                onClick={() => {
                  navigate("/patient/manage-profile");
                  handleClose();
                }}
              >
                Manage Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  signOut();
                  navigate("/");
                  handleClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </nav>
      {title && (
        <Box className="pageBanner">
          <h1>{title}</h1>
        </Box>
      )}
    </>
  );
};

export const DHIHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, signOut, userPrimaryRole } = useUserContext();
  const isMobile = useMediaQuery("(max-width:600px)");

  console.log("user", user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <nav className="nav-header">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" marginLeft={5}>
            <Image
              src={CliniSpanLogo}
              alt="Logo"
              style={{ height: 50, width: isMobile ? 100 : 150 }}
            />
          </Box>
          <Box display="flex" marginRight={5} alignItems={"center"}>
            {isMobile ? (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleClick}
                >
                  <MenuIcon />
                </IconButton>
              </>
            ) : (
              <>
                <DHIMenu />
                <AccountCircleIcon
                  className="icon"
                  onClick={handleClick}
                  style={{ color: "#009EC5" }}
                />
              </>
            )}
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem focusVisibleClassName="test">
                {user?.firstName ? user.firstName : user?.email}
                {`(${userPrimaryRole()})`}
              </MenuItem>
              {user?.roles &&
                user?.roles?.length > 1 &&
                user.roles.map((role) => (
                  <MenuItem
                    key={role}
                    onClick={() => {
                      navigate(
                        role.toLowerCase() === "partner"
                          ? "/partner/studies"
                          : role.toLowerCase() === "constituent"
                          ? "/patient/dashboard"
                          : `/${role.toLowerCase()}/dashboard`
                      );
                      handleClose();
                    }}
                  >
                    Switch to {role === Roles.Constituent ? "Patient" : role}{" "}
                    Portal
                  </MenuItem>
                ))}
              {/* <MenuItem
                onClick={() => {
                  navigate("/dhi/billing");
                }}
              >
                Billing
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  navigate("/dhi/profile");
                  handleClose();
                }}
              >
                Manage Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  signOut();
                  navigate("/");
                  handleClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </nav>
    </>
  );
};

export const AdminHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, signOut } = useUserContext();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <header className="App-header">
        <Image src={CliniSpanLogo} alt="Logo" />
      </header>
      <nav className="nav-header">
        <AdminMenu />
        <AccountCircleIcon className="icon" onClick={handleClick} />

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            {user?.firstName ? user.firstName : user?.email}
            <br></br>
            {user?.role}
          </MenuItem>
          <MenuItem
            onClick={() => {
              signOut();
              navigate("/");
              handleClose();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </nav>
    </>
  );
};
