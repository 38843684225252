import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { AdminHeader } from "../../components/Menus";
// import exportCSV from "../../utilities/exportCsv";
import { CSVLink } from "react-csv";

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const DatabaseSearch = () => {
  const [location, setLocation] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // TODO: handle search query using the state values
  };

  function handleExportClick() {
    const data = [
      {
        name: "John Doe",
        age: 42,
      },
    ];
    // exportCSV(data, "myData.csv");
  }

  return (
    <div className="App">
      <AdminHeader />
      <div className="App-body outerRow">
        <div className="container">
          <div className="flexColumn">
            <div>
              <h1>Search Page</h1>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="location">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    value={location}
                    onChange={(event) => setLocation(event.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="age">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    type="number"
                    value={age}
                    onChange={(event) => setAge(event.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="gender">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    as="select"
                    value={gender}
                    onChange={(event) => setGender(event.target.value)}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="ethnicity">
                  <Form.Label>Race/Ethnicity</Form.Label>
                  <Form.Control
                    type="text"
                    value={ethnicity}
                    onChange={(event) => setEthnicity(event.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ marginRight: 20 }}
                >
                  Search
                </Button>
                <Button
                  variant="secondary"
                  type="submit"
                  // onClick={handleExportClick}
                >
                  <CSVLink
                    data={[]}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Export CSV
                  </CSVLink>
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatabaseSearch;
