import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../App.css";
import studyImg from "../../assets/study.png";
import { PatientHeader } from "../../components/Menus";
import { useUserContext } from "../../providers/UserContext";
import FormRenderer from "../partner/FormRenderer";

//TODO: remove not needed
const FETCH_FORM_QUERY = gql`
  query getForm($formId: String!) {
    getForm(formId: $formId) {
      name
      createdAt
      updatedAt
      fields {
        id
        label
        type
        required
        options
      }
    }
  }
`;

const FETCH_FORM_RESPONSE_QUERY = gql`
  query getFormResponse($formId: String!) {
    getFormResponse(formId: $formId) {
      id
      formId{
      id
      }
      fields {
        label
        type
        options {
          label
          value
        }
        required
        value
      }
    }
  }
`;

const SUBMIT_FORM_RESPONSE_MUTATION = gql`
  mutation submitFormResponse(
    $formId: String!
    $formResponse: [FieldResponseInput]!
  ) {
    submitFormResponse(formId: $formId, response: $formResponse) {
      id
      formId {
        name
        }
      fields {
        label
        type
        options {
          label
          value
        }
        required
        value
      }
    }
  }
`;

const STUDY_QUERY = gql`
  query GetStudy($id: String!) {
    study(id: $id) {
      title
      nctNumber
      condition
      intervention
      location {
        facility
        city
        state
        country
      }
      description
      startDate
      studyType
      eligibilityCriteria
      formId {
        name
        id
      }
    }
  }
`;

function StudyDetails(_props) {
  const { user } = useUserContext();
  const { id } = useParams();



  const location = useLocation();
  const [study, setStudy] = useState(location.state?.study || null);
  const { loading, error, data } = useQuery(FETCH_FORM_QUERY, {
    variables: { formId: study?.formId?.id },
    skip: !user || !study,
  });

  const {
    loading: loadingFormResponse,
    error: errorFormResponse,
    data: formResponseData,
  } = useQuery(FETCH_FORM_RESPONSE_QUERY, {
    variables: { formId: study?.formId?.id },
    skip: !user || !study,
  });

  console.log('id', id)
  const { loading: studyLoading, error: studyError, data: studyData } = useQuery(STUDY_QUERY, {
    variables: { id },
    skip: !user || study, // Skip the query if study is already available
  });

  console.log('variabled being sent', study?.formId?.id?.toString())

  const [submitFormResponse] = useMutation(SUBMIT_FORM_RESPONSE_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_FORM_RESPONSE_QUERY,
        variables: { formId: study?.formId?.id?.toString()  },
      },
    ],
  });
  const [formResponse, setFormResponse] = useState({});
  const [formId, setFormId] = useState("");
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  const [formValues, setFormValues] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (data) {
      console.log("data", data);
      setForm(data.getForm);
    }
  }, [data]);

  useEffect(() => {
    if (study?.formId?.id) {
      setFormId(study.formId.id);
    }
  }, [study]);

  useEffect(() => {
    if (studyData && !study) {
      setStudy(studyData.study);
    }
  }, [studyData, study]);

  useEffect(() => {
    if (formResponseData && formResponseData.getFormResponse) {
      setFormResponse(formResponseData.getFormResponse);
    }
  }, [formResponseData]);

  const displayForm = () => {
    if (!loading && !error && form) {
      setOpen(true);
    }
  };

  const submitForm = async () => {
    try {
      //likely want to just submit and display a modal with followup info based on the response. We want the data regardless.
      //if the user is eligible, we can display a modal with the next steps
      //if the user is not eligible, we can display a modal with the next steps

      console.log("formValues", formId);
      const eligible = await submitFormResponse({
        variables: {
          formId: formId.toString(),
          formResponse: Object.values(formValues),
        },
      });

      console.log("eligible", eligible);

      if (eligible) {
        toast.success("You are eligible for the study!");
      } else {
        toast.error("You are not eligible for the study.");
      }
      //close form modal
      setOpen(false);

      // if (!formValues)
      //   return toast.error("Please fill all required fields before submitting");

      // if (form.fields.some((field) => field.required && !formValues[field.label]))
      //   return toast.error("Please fill all required fields before submitting");

      return;
    } catch (err) {
      console.error("err", err);
      toast.error("Error saving form");
    }
  };

  console.log("form", form, form.fields, formResponse, Object.values(formResponse), formResponseData);

  return (
    <div className="App">
      <ToastContainer />
      <PatientHeader />
      <div style={{ padding: 50 }}>
        <h1>Study Details</h1>
        <img src={studyImg}></img>
        <h2>{study?.title}</h2>
        <h5>Locations:</h5>
        <p>
          {study?.location?.city}, {study?.location?.state}{" "}
          {study?.location?.country}
        </p>
        <h5>Summary:</h5>
        <p>{`${study?.description}`}</p>
        <h5>Eligibility:</h5>
        <p>{`${study?.eligibilityCriteria}`}</p>
        <h5>Trial Site:</h5>
        <p> {study?.location?.facility} </p>
        {/* need to replace with a timestamp for when the form was completed and just check if formCompleted is present */}
        {(Object.keys(formResponse).length === 0 || Object.values(formResponse).includes(null)) && study?.formId?.id && form && (
          <Button
            style={{ backgroundColor: "#009EC5", borderColor: "#009EC5" }}
            onClick={displayForm}
          >
           See If You Qualify
          </Button>
        )}

        {form && form?.fields && (
          <Modal show={open} onHide={setOpen}>
            <Modal.Header closeButton>
              <Modal.Title>{form?.name}</Modal.Title>
            </Modal.Header>
            <FormRenderer
              form={form}
              fields={form.fields}
              formError={formError}
              formValues={formValues}
              setFormError={setFormError}
              setFormValues={setFormValues}
              bordered={false}
              onFormSubmit={submitForm}
            />
            <Modal.Footer>
              <Button variant="success" onClick={submitForm}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default StudyDetails;
