import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ExamplePdf from "../../assets/DHI.pdf";
import PDFImage from "../../assets/pdf.png";

const faqData = [
  {
    question: "What Is A Digital Health Influencer (DHI)?",
    answer:
      "A Digital Health Influencer is a culturally competent community member that leverages their social media to help educate and provide access to clinical research for their community.",
  },
  {
    question: "What Social Media Platforms Will DHI’s Be Using?",
    answer:
      "Digital Health Influencers use a variety of platforms including but not limited to: Facebook, Instagram, Twitter, & Tiktok. The specifics of the platform depend on the recruitment campaign.",
  },
  {
    question: "Are DHI’s compensated for their work?",
    answer:
      "All DHI’s are compensated at a monthly rate for their services. Compensation is NOT tied to participant identification, referral, or enrollment.",
  },
  {
    question: "Why do you need access to my social media accounts?",
    answer:
      "We need access to your social media accounts to ensure that you are a real person and that you are using your own social media accounts. We also need access to your social media accounts to post content on your behalf.",
  },
];

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 200px;
  width: 90%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const Resources = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  return (
    <div className="App">
      <div
        className="App-body"
        style={{ backgroundColor: "#fff", color: "#000", margin: "0px" }}
      >
        {/* <Container background={"orange"}>
          <Text>HIPPA Training</Text>
        </Container> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 3,
            marginTop: 3,
            gap: 20,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid #eaeaf0",
              borderRadius: 3,
              alignItems: "center", // Align items vertically in the center
              justifyContent: "center", // Align items horizontally in the center
              padding: 1, // Add some padding
              gap: 10,
            }}
          >
            <img src={PDFImage} alt="HIPAA Training" />
            <Text sx={{ marginLeft: 2 }}>HIPAA Training</Text>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              sx={{ marginLeft: "auto" }} // Push the button to the right
              href={ExamplePdf} // Replace with your file path
              download
            >
              {/* Download */}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              border: "1px solid #eaeaf0",
              borderRadius: 3,
              alignItems: "center", // Align items vertically in the center
              justifyContent: "center", // Align items horizontally in the center
              padding: 1, // Add some padding
              gap: 10,
            }}
          >
            <img src={PDFImage} alt="Digital Health Influencer Handbook" />
            <Text sx={{ marginLeft: 2 }}>
              Digital Health Influencer Handbook{" "}
            </Text>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              sx={{ marginLeft: "auto" }} // Push the button to the right
              href={ExamplePdf} // Replace with your file path
              download
            >
              {/* Download */}
            </Button>
          </Box>
        </Box>

        <div className="faq-page">
          <h1>Frequently Asked Questions</h1>
          <ul style={{ listStyleType: "none", marginTop: 50 }}>
            {faqData.map((item, index) => (
              <li key={index} style={{ marginBottom: "0.5rem" }}>
                <div
                  className="faq-question"
                  style={{
                    border: "0px",
                    borderBottom: "1px solid #ccc",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1rem 0",
                  }}
                  onClick={() => handleClick(index)}
                >
                  <span>{item.question}</span>
                  {activeIndex === index ? (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      style={{
                        alignSelf: "center",
                        fontSize: "20px",
                        color: "#000",
                        marginLeft: 10,
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{
                        alignSelf: "center",
                        fontSize: "20px",
                        color: "#000",
                        marginLeft: 10,
                      }}
                    />
                  )}
                </div>
                {activeIndex === index && (
                  <div className="faq-answer">{item.answer}</div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Resources;
