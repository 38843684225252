import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const STUDY_SEARCH_MUTATION = gql`
  mutation GetStudies($nctNumber: String!) {
    studySearch(nctNumber: $nctNumber) {
      title
    }
  }
`;

const STUDY_QUERY = gql`
  query GetStudies {
    myStudies {
      id
      title
      nctNumber
      campaignIds
    }
  }
`;

const ADD_STUDY_MUTATION = gql`
  mutation AddStudy($nctNumber: String!) {
    addStudy(nctNumber: $nctNumber) {
      title
    }
  }
`;

const FETCH_STUDY_MUTATION = gql`
  mutation FetchStudy($nctNumber: String!) {
    fetchStudy(nctNumber: $nctNumber) {
      title
      nctNumber
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 60px;
  width: 80%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  margin: 10px auto;
  align-self: center;
  width: 400px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   color: #fff;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Row = styled.div`
  display: flex;
  felx-diretion: row;
  justify-content: center;
`;

const items = [
  {
    name: "Asthma Trial",
    nctNumber: "12341231",
    participants: 10,
    active: true,
  },
  {
    name: "COVID Trial",
    nctNumber: "12341231",
    participants: 20,
    active: false,
  },
  {
    name: "Diabetes Trial",
    nctNumber: "12341231",
    participants: 30,
    active: true,
  },
];

const ExampleTable = ({ data }) => {
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Participant Name</th>
          <th>Date Referred</th>
          <th>Email</th>
          <th>Phone Number</th>
          <th>Best Time To Reach</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.name}</td>
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.upload}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const StudyDetailsTable = ({ data }) => {
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Participant Name", flex: 1 },
    { field: "name2", headerName: "Date Referred", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone Number", flex: 1 },
    { field: "upload", headerName: "Best Time To Reach", flex: 1 },
  ];

  return (
    <Box m="20px">
      <Box
        m="40px 0 0 0"
        height="75vh"
        xl={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          // "& .name-column--cell": {
          //   color: colors.greenAccent[300],
          // },
          // "& .MuiDataGrid-columnHeaders": {
          //   backgroundColor: colors.blueAccent[700],
          //   borderBottom: "none",
          // },
          // "& .MuiDataGrid-virtualScroller": {
          //   backgroundColor: colors.primary[400],
          // },
          // "& .MuiDataGrid-footerContainer": {
          //   borderTop: "none",
          //   backgroundColor: colors.blueAccent[700],
          // },
          // "& .MuiCheckbox-root": {
          //   color: `${colors.greenAccent[200]} !important`,
          // },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

function ListItem({ item }) {
  const navigate = useNavigate();

  return (
    <Card
      style={{ marginTop: 15, marginBottom: 15 }}
      bordered={true}
      title={item.title}
      className="custom-card"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <p>NCT Number: {item.nctNumber}</p>
        <p>Participants: {item.participants || 0}</p>
        <p
          style={{ color: item.active ? "red" : "green" }}
          onClick={() => null}
        >
          Active: {item.active ? "Deactivate" : "Activate"}
        </p>
      </div>
    </Card>
  );
}

function ConfirmationModal(props) {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleDelete = () => {
    props.handleDelete();
    handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Addition</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this study?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleDelete}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const StudyDetails = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const [nctNumber, setNctNumber] = useState(id);
  const [searchResult, setSearchResult] = useState();
  const [searchedNctNumber, setSearchedNctNumber] = useState();
  const [show, setShow] = useState(false);
  const [studies, setStudies] = useState([]);
  const [studySearch] = useMutation(STUDY_SEARCH_MUTATION, {
    variables: { nctNumber },
  });
  const [fetchStudy] = useMutation(FETCH_STUDY_MUTATION, {
    variables: { nctNumber },
  });

  const [addStudy] = useMutation(ADD_STUDY_MUTATION, {
    variables: { nctNumber: searchedNctNumber },
    refetchQueries: [{ query: STUDY_QUERY }],
  });

  const { loading, error, data } = useQuery(STUDY_QUERY);

  const onSearchClick = async () => {
    setSearchedNctNumber(nctNumber);
    const searchData = await studySearch();
    setSearchResult(searchData?.data?.studySearch);
  };

  //   useEffect(() => {
  //     if (!loading) {
  //       setStudies(data?.myStudies);
  //     }
  //   }, [loading, data]);

  useEffect(() => {
    if (id) {
      fetchStudy().then((res) => {
        setStudies([res.data.fetchStudy]);
      });
    }
  }, [id]);

  const tempdata = [{ id: 1 }];

  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <main className="content">
        <Topbar />
        <Box m="20px">
          <div
            style={{
              overflowY: "scroll",
              maxHeight: 500,
            }}
          >
            {!searchResult &&
              !loading &&
              studies &&
              studies.map((item) => (
                <ListItem key={item.nctNumber} item={item} />
              ))}
          </div>
          <StudyDetailsTable data={tempdata} />
        </Box>
      </main>
    </div>
  );
};

export default StudyDetails;
