import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 10px;
  border-bottom: 1px solid #f0f0f0;
`;

const Title = styled.h4`
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: bold;
`;

const Message = styled.p`
  margin: 5px 0 0 0;
  color: #666;
  font-size: 14px;
`;

const TimeStamp = styled.div`
  color: #999;
  font-size: 12px;
  margin-top: 5px;
`;

// Helper function to format date
const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(Number(dateString)).toLocaleDateString(undefined, options);
};

const NotificationContent = ({ title, message, createdAt }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <TimeStamp>{formatDate(createdAt)}</TimeStamp>
    </Container>
  );
};

export default NotificationContent;
