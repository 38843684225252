import { gql, useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
// import { GET_FILES_QUERY, UPLOAD_FILE_MUTATION } from "./graphqlQueries";
import { useUserContext } from "../providers/UserContext";
import MediaTabs from "./MediaTabs";

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      _id
      name
      url
    }
  }
`;

const GET_FILES_QUERY = gql`
  query GetFiles($portalType: String!) {
    getFiles(portalType: $portalType) {
      _id
      name
      url
      fileType
    }
  }
`;

const MediaLibrary = ({
  isOpen,
  onClose,
  saveSelection,
  accountType,
  singleSelection,
  primaryActionText,
  engagementsLeft
}) => {
  const { user } = useUserContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [platform, setPlatform] = useState("");
  const [uploadFilesMutation] = useMutation(UPLOAD_FILE_MUTATION);
  const { data, loading, error, refetch } = useQuery(GET_FILES_QUERY, {
    variables: {
      portalType: accountType,
    },
  });
  // console.log("file data", data);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      refetch();
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen, refetch]);

  useEffect(() => {
    if (isOpen && selectedTab === "select") {
      refetch();
    }
  }, [isOpen, refetch, selectedTab]);

  const handleFileCheck = (file) => {
    // if (platform === "Tiktok") {
    //   setSelectedFiles([file]);
    //   return;
    // } TODO: Update this later when improving validation
    console.log("singleSelection", singleSelection);
    if (singleSelection) {
      //need to remove file from array if the same file is clicked on after having been previously selected
      if (selectedFiles.length > 0 && selectedFiles[0]._id === file._id) {
        setSelectedFiles([]);
      } else {
        setSelectedFiles([file]);
      }
    } else {
      setSelectedFiles((prev) => {
        console.log("prev", prev);
        prev = prev || []; // Use an empty array if prev is undefined
        return prev.includes(file)
          ? prev.filter((selectedFile) => selectedFile._id !== file._id)
          : [...prev, file];
      });
    }
  };

  const onDrop = (files) => {
    console.log("onDrop", files);
    setSelectedFiles(files);
    // Implement upload logic
    onFilesAdded({ target: { files } });
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  console.log("user", user);
  
  const onFilesAdded = async (evt) => {
    const files = evt.target.files;
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("accountType", accountType);
    formData.append("userId", user?._id);

    setLoadingUpload(true); // Set loading to true when upload starts

    try {
      const response = await axios.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("File uploaded successfully:", response.data);

      refetch();
      setSelectedTab(1);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoadingUpload(false); // Set loading to false when upload ends
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} size="lg" centered style={{
      maxHeight: 'calc(100vh - 50px)',
    }}>
      <Modal.Header closeButton>
        <Modal.Title>Media Library</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
           display: 'flex',
           flexDirection: 'column',
           maxHeight: 'calc(80vh - 100px)', // Adjust the height as needed
           overflowY: 'auto',
      }}>
        <MediaTabs
          onDrop={onDrop}
          openFileDialog={openFileDialog}
          fileInputRef={fileInputRef}
          onFilesAdded={onFilesAdded}
          loadingUpload={loadingUpload}
          loading={loading}
          error={error}
          data={data}
          handleFileCheck={handleFileCheck}
          selectedFiles={selectedFiles}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          singleSelection={singleSelection}
          engagementsLeft={engagementsLeft}
        />
      </Modal.Body>
      <Modal.Footer
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Button
          style={{
            backgroundColor: "#fff",
            borderColor: "#009EC5",
            color: "#009EC5",
          }}
          onClick={onClose}
        >
          Close
        </Button>
        {selectedTab !== 0 && selectedFiles && (
          <Button
            style={{
              backgroundColor: "#009EC5",
              borderColor: "#009EC5",
              color: "#fff",
            }}
            onClick={() => saveSelection(selectedFiles)}
          >
            {primaryActionText ? primaryActionText : "Save Selection"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default MediaLibrary;
