import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
// import { Editor } from "@tinymce/tinymce-react";
import { gql, useMutation, useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from "react-router-dom";
import CampaignBuilder from "../../components/CampaignBuilder";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const INFLUENCERS_QUERY = gql`
  query Influencers {
    influencers(role: "DHI") {
      _id
      email
      role
      firstName
      lastName
      zipCode
      phoneNumber
      platforms
    }
  }
`;

const CAMPAIGN_CREATION_REQUEST = gql`
  mutation CreateCampaignRequest(
    $name: String!
    $description: String
    $postingInstructions: String!
    $sex: String!
    $ageRange: String!
    $raceEthnicity: [String]!
    $platforms: [Platform]!
  ) {
    createCampaignRequest(
      campaignRequestInput: {
        name: $name
        description: $description
        postingInstructions: $postingInstructions
        platforms: $platforms
      }
      targetAudienceInput: {
        sex: $sex
        ageRange: $ageRange
        raceEthnicity: $raceEthnicity
      }
    ) {
      _id
      name
      description
      postingInstructions
    }
  }
`;

const CAMPAIGNS_QUERY = gql`
  query Campaigns($id: String!) {
    campaigns(id: $id) {
      name
      scheduledDate
      startDate
      endDate
      platforms
      active
      media: IRBApprovedFiles {
        name
        url
      }
      influencers {
        firstName
        lastName
        platforms
        avatar
        _id
      }
      postingInstructions
      description
      targetAudience {
        sex
        ageRange
        raceEthnicity
      }
    }
  }
`;

const CREATE_CAMPAGIN_MUTATION = gql`
  mutation createCampaign(
    $name: String!
    $influencers: [Influencer!]
    $platform: Platform!
    $scheduledDate: String!
    $content: String!
  ) {
    createCampaign(
      campaignInput: {
        name: $name
        influencers: $influencers
        platform: $platform
        scheduledDate: $scheduledDate
        content: $content
      }
    ) {
      name
    }
  }
`;

const APPROVE_CAMPAIGN_MUTATION = gql`
  mutation approveCampaign($id: String!) {
    approveCampaign(id: $id) {
      name
    }
  }
`;

const REJECT_CAMPAIGN_MUTATION = gql`
  mutation rejectCampaign($id: String!) {
    rejectCampaign(id: $id) {
      name
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 200px;
  width: 90%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
  text-align: left;
`;
const Input = styled.input`
  display: block;
  margin: 10px auto;
  align-self: center;
  width: 80%;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   margin-top: 50px;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const platforms = [
  { name: "Facebook", value: "Facebook" },
  { name: "Instagram", value: "Instagram" },
  { name: "Twitter", value: "Twitter" },
  { name: "Tiktok", value: "Tiktok" },
];

const ViewCampaign = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { loading, error, data } = useQuery(INFLUENCERS_QUERY);
  const {
    loading: campaignLoading,
    error: campaignError,
    data: campaignData,
  } = useQuery(CAMPAIGNS_QUERY, {
    variables: { id: campaignId },
  });

  const _contentState = ContentState.createFromText("Sample content state");
  const raw = convertToRaw(_contentState); // RawDraftContentState JSON
  const [contentState, setContentState] = useState(raw);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [content, setConvertedContent] = useState(null);
  const [name, setName] = useState("Test");

  const [influencers, setInfluencers] = useState([]);
  const [platform, setPlatform] = useState("Facebook");
  const [scheduledDate, setScheduledDate] = useState(null);
  const [step, setStep] = useState(1);
  const [campaignInfo, setCampaignData] = useState({
    targetAudience: {
      sex: [],
      ageRange: [],
      raceEthnicity: [],
    },
    campaignName: "",
    campaignDescription: "",
    platforms: [],
    postingInstructions: "",
  });

  console.log("campaignData", campaignData);

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const [createCampaign] = useMutation(CREATE_CAMPAGIN_MUTATION, {
    variables: {
      content,
      name,
      influencers,
      platform,
      scheduledDate,
    },
  });

  useEffect(() => {
    document.title = "Admin Review Campaign";
  }, []);

  useEffect(() => {
    if (!loading && data) setInfluencers(data.influencers);
  }, [loading, data]);

  useEffect(() => {
    if (!campaignLoading && campaignData) {
      setCampaignData(campaignData.campaigns[0]);
    }
  }, [campaignLoading, campaignData]);

  // useEffect(() => {
  //   if (!loading) setInfluencers(data.influencers);
  // }, [loading]);

  // useEffect(() => {
  //   if (!campaignLoading && campaignData) {
  //     setName(campaignData.campaigns[0].name);
  //     setPlatform(campaignData.campaigns[0].platform);
  //     setInfluencers(campaignData.campaigns[0].influencers);
  //     const date = new Date(Number(campaignData.campaigns[0].scheduledDate));
  //     setScheduledDate(
  //       date.toISOString().substring(0, date.toISOString().indexOf("."))
  //     );
  //     const _contentState = ContentState.createFromText(
  //       "campaignData.campaigns[0].content"
  //     );
  //     const raw = convertToRaw(_contentState);
  //     // console.log("raw", raw, _contentState);
  //     setContentState(raw);
  //   }
  // }, [campaignLoading, campaignData]);

  // useEffect(() => {
  //   let html = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(html);
  // }, [editorState]);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  const onSelectedOptionsChange = (e) => {
    // console.log(
    //   "selected",
    //   JSON.parse(e.target.value),
    //   e.target.selectedOptions
    // );
  };

  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <main className="content">
        <Topbar />
        <CampaignBuilder
          review
          adminData={campaignInfo}
          setAdminData={setCampaignData}
        />
      </main>
    </div>
  );
};

export default ViewCampaign;
