import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import gql from "graphql-tag";
import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const fetchOrganizations = gql`
  query GetOrganizations {
    organizations {
      id: _id
      name
      isActive
      subscriptionType
    }
  }
`;

const ManageBilling = () => {
  const { user } = useUserContext();
  // Sample organizations data
  const organizations = [
    {
      id: 1,
      name: "Organization 1",
      isActive: true,
      subscriptionType: "Basic",
    },
    {
      id: 2,
      name: "Organization 2",
      isActive: false,
      subscriptionType: "Premium",
    },
    // Add more organizations as needed
  ];

  const [orgList, setOrgList] = useState(organizations);

  const handleToggleActivation = (id) => {
    setOrgList((prevOrgList) =>
      prevOrgList.map((org) =>
        org.id === id ? { ...org, isActive: !org.isActive } : org
      )
    );
  };

  const handleChangeSubscription = (id, subscriptionType) => {
    setOrgList((prevOrgList) =>
      prevOrgList.map((org) =>
        org.id === id ? { ...org, subscriptionType } : org
      )
    );
  };

  return (
    <Box>
      <div className="app">
        <Sidebar
          isSidebar={true}
          name={user?.firstName ? user.firstName : user?.email}
          role={user?.role}
        />
        <main className="content">
          <Topbar />
          <h1 style={{ textAlign: "center" }}>Manage Billing</h1>
          <TableContainer component={Paper}>
            <Table aria-label="billing table">
              <TableHead>
                <TableRow>
                  <TableCell>Organization</TableCell>
                  <TableCell align="center">Active</TableCell>
                  <TableCell align="center">Subscription Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orgList.map((org) => (
                  <TableRow key={org.id}>
                    <TableCell component="th" scope="row">
                      {org.name}
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        checked={org.isActive}
                        onChange={() => handleToggleActivation(org.id)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FormControl>
                        <Select
                          value={org.subscriptionType}
                          onChange={(e) =>
                            handleChangeSubscription(org.id, e.target.value)
                          }
                        >
                          <MenuItem value="Basic">Basic</MenuItem>
                          <MenuItem value="Premium">Premium</MenuItem>
                          {/* Add more subscription types as needed */}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </main>
      </div>
    </Box>
  );
};

export default ManageBilling;
