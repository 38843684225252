import { gql, useQuery } from "@apollo/client";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FlippableCard from "../../components/FlippableCard";
import MediaLibrary from "../../components/MediaModal";
import { PartnerHeader } from "../../components/Menus";

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;

const INFLUENCER_QUERY = gql`
  query GetInfluencers($filter: String, $sortBy: String) {
    influencers(filter: $filter, sortBy: $sortBy) {
      firstName
      lastName
      location {
        city
        state
        country
      }
    }
  }
`;

const CONDITIONS_QUERY = gql`
  query GetConditions {
    conditions {
      name
    }
  }
`;

function InfluencerPortfolio() {
  const navigate = useNavigate();
  const [influencers, setInfluencers] = useState([
    {
      firstName: "Sean",
      lastName: "Test",
      location: {
        city: "Philadelphia",
        state: "PA",
        country: "USA",
      },
      platforms: ["Instagram", "Twitter"],
      therapyAreas: ["Diabetes", "Cancer"],
      followers: 100000,
    },
    {
      firstName: "Jane",

      lastName: "Johnson",
      location: {
        city: "San Diego",
        state: "CA",
        country: "USA",
      },
      platforms: ["Meta", "Tiktok"],
      therapyAreas: ["Asthma", "Cancer"],
      followers: 250000,
    },
    {
      firstName: "Peter",
      lastName: "Parker",
      location: {
        city: "New York",
        state: "NY",
        country: "USA",
      },
      platforms: ["Tiktok", "Meta"],
      therapyAreas: ["Diabetes", "Cancer"],
      followers: 2000,
    },
  ]);
  const [filterOption, setFilterOption] = useState("1");
  const [sortBy, setSortBy] = useState("followers");
  const [conditions, setConditions] = useState([]);
  const { loading, error, data } = useQuery(CONDITIONS_QUERY);
  const [platforms, setPlatforms] = useState([
    "Facebook",
    "Instagram",
    "Twitter",
    "Tiktok",
  ]);
  const [show, setShow] = useState(false);
  // const { loading, error, data } = useQuery(INFLUENCER_QUERY, {
  //     variables: {
  //         filter: filterOption,
  //         sortBy: "followers"
  //     }
  // });

  return (
    <div className="App">
      <PartnerHeader />
      {/* <TemplateUpload /> */}
      {/* <input
        type="file"
        style={{ display: "block" }}
        onClick={() => console.log("input clicked")}
        onChange={(event) => {
          console.log("onChange triggered");
          const file = event.target.files[0];
          console.log(file);
        }}
      /> */}
      <MediaLibrary isOpen={show} onClose={() => setShow(false)} />
      <Button onClick={() => setShow(true)}>Open Media Library</Button>
      <div style={{ paddingRight: 20, paddingLeft: 20, marginTop: 20 }}>
        <Row className="g-2" xs>
          <Col xs>
            <FloatingLabel controlId="floatingSelectGrid" label="Filter By">
              <Form.Select
                aria-label="Floating label select example"
                onChange={(e) => setFilterOption(e.currentTarget.value)}
              >
                {/* <option>Open this select menu</option> */}
                <option value="1">Location</option>
                <option value="2">Therapy Area</option>
                <option value="3">Platform</option>
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col md>
            <FloatingLabel controlId="floatingInputGrid" label="">
              {filterOption === "1" && (
                <Form.Control type="email" placeholder="name@example.com" />
              )}
              {filterOption === "2" && (
                <Form.Select
                  aria-label="Floating label select example"
                  onChange={(_e) => null}
                >
                  {/* <option>Open this select menu</option> */}
                  {data &&
                    data.conditions.map((condition, index) => (
                      <option key={index} value={condition.name}>
                        {condition.name}
                      </option>
                    ))}
                </Form.Select>
              )}
              {filterOption === "3" && (
                <Form.Select
                  aria-label="Floating label select example"
                  onChange={(_e) => null}
                >
                  {/* <option>Open this select menu</option> */}
                  {platforms &&
                    platforms.map((platform, index) => (
                      <option key={index} value={platform}>
                        {platform}
                      </option>
                    ))}
                </Form.Select>
              )}
            </FloatingLabel>
          </Col>
          <Col
            xs
            style={{
              width: "10%",
              maxWidth: "10%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="primary"
              onClick={() => null}
              style={{ alignSelf: "center" }}
            >
              Filter
            </Button>
          </Col>
        </Row>
      </div>
      <div className="study-grid">
        {influencers &&
          influencers.map((influencer, index) => (
            <FlippableCard
              key={index}
              front={
                <Container
                  key={index}
                  onClick={() => {
                    //   navigate("study/" + study.nctNumber, {
                    //     state: { influencer: influencer },
                    //   });
                  }}
                  style={{ backgroundColor: "#fff" }}
                >
                  <AccountCircleIcon
                    style={{
                      fontSize: "100px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  />
                  <h4>
                    {influencer.firstName} {influencer.lastName}
                  </h4>
                  <p>
                    Location:{" "}
                    {`${influencer?.location?.city || ""}, ${
                      influencer?.location?.state ||
                      influencer?.location?.country ||
                      ""
                    }`}
                  </p>
                  <p>Platforms: {influencer.platforms.toString()}</p>
                  <p> Therapy Areas: {influencer.therapyAreas.toString()} </p>
                </Container>
              }
              back={
                <Container
                  key={index}
                  onClick={() => {
                    //   navigate("study/" + study.nctNumber, {
                    //     state: { influencer: influencer },
                    //   });
                  }}
                  style={{ backgroundColor: "#fff" }}
                >
                  <AccountCircleIcon
                    style={{
                      fontSize: "100px",
                      color: "black",
                      cursor: "pointer",
                    }}
                  />
                  <h4>
                    {influencer.firstName} {influencer.lastName}
                  </h4>
                  <p>Platforms: {influencer.platforms.toString()}</p>
                  <p>Followers: {influencer.followers}</p>
                  <p>Reach: {influencer.followers * 2000}</p>
                </Container>
              }
            />
          ))}
      </div>
    </div>
  );
}

export default InfluencerPortfolio;
