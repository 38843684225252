import React from "react";
import { PatientHeader } from "./Menus";

const PartnerContainer = ({ children }) => {
  return (
    <div className="partner-container">
      <PatientHeader />
      {/* Add your content here */}
      {children}
    </div>
  );
};

export default PartnerContainer;
