import { gql, useQuery, useSubscription } from "@apollo/client";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Box, IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Roles } from "../constants/user";
import { useUserContext } from "../providers/UserContext";
import NotificationContent from "./NotificationContent";

const GET_NOTIFICATIONS = gql`
  query GetNotifications($userId: String!) {
    notifications(userId: $userId) {
      _id
      title
      message
      read
      createdAt
    }
  }
`;

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription OnNotificationAdded($userId: ID!) {
    notificationAdded(userId: $userId) {
      _id
      title
      message
      read
      createdAt
    }
  }
`;

const Image = styled.img`
  height: 80px;
  width: 250px;
  margin-top: 5px;
`;

const Topbar = () => {
  const navigate = useNavigate();
  const { user, signOut } = useUserContext();
  console.log("user", user);
  const { data, loading, error } = useSubscription(NOTIFICATION_SUBSCRIPTION, {
    variables: { userId: user?._id },
  });
  const {
    loading: notificationsLoading,
    error: notificationsError,
    data: notificationsData,
  } = useQuery(GET_NOTIFICATIONS, {
    variables: { userId: user?._id || "123" },
  });
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  // Function to simulate receiving a new notification
  function receiveNotification() {
    setNotificationCount((prevCount) => prevCount + 1);
  }

  function handleNotificationClick(event) {
    // Reset notification count
    setNotificationCount(0);
    setAnchorEl(event.currentTarget);
    // Additional logic to show notifications
  }

  const handleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log("subscription data", data, loading, error);
  console.log(
    "notifications",
    notificationsData,
    notificationsLoading,
    notificationsError
  );

  // Load initial notifications
  useEffect(() => {
    if (!notificationsLoading && notificationsData) {
      console.log("notificationsData", notificationsData);
      setNotifications(notificationsData.notifications);
    }
  }, [notificationsLoading, notificationsData]);

  // Update notifications with new ones from the subscription
  useEffect(() => {
    if (data?.notificationAdded) {
      setNotifications((prevNotifications) => {
        console.log("prevNotifications", prevNotifications);
        return [...prevNotifications, data.notificationAdded].slice(0, 5);
      });
    }
  }, [data]);

  useEffect(() => {
    if (data && data.notificationAdded) {
      receiveNotification();
    }
  }, [data]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      borderBottom={2}
      borderColor={"#e0e0e0"}
    >
      <Box display="flex" borderRadius="3px">
        {/* <Image src={CliniSpanLogo} alt="Logo" /> */}
      </Box>

      <Box display="flex">
        <IconButton>
          {notificationCount > 0 ? (
            <Badge
              badgeContent={notificationCount}
              color="secondary"
              onClick={handleNotificationClick}
            >
              <NotificationsOutlinedIcon />
            </Badge>
          ) : (
            <NotificationsOutlinedIcon onClick={handleNotificationClick} />
          )}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {notifications &&
              notifications.map((notification) => (
                <NotificationContent
                  key={notification.id}
                  title={notification.title}
                  message={notification.message}
                  createdAt={notification.createdAt}
                />
              ))}
          </Menu>
        </IconButton>
        {/* <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}
        <IconButton>
          <PersonOutlinedIcon onClick={handleClick} />
          <Menu
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={() => setAnchorEl2(null)}
          >
            <MenuItem>
              {user?.firstName ? user.firstName : user?.email}
              <br></br>
              {/* {user?.primaryRole} */}
            </MenuItem>
            {user?.roles &&
              user?.roles?.length > 1 &&
              user.roles.map((role) => (
                <MenuItem
                  key={role}
                  onClick={() => {
                    navigate(
                      role.toLowerCase() === "partner"
                        ? "/partner/studies"
                        : role.toLowerCase() === "constituent"
                        ? "/patient/dashboard"
                        : `/${role.toLowerCase()}/dashboard`
                    );
                    handleClose();
                  }}
                >
                  Switch to {role === Roles.Constituent ? "Patient" : role}{" "}
                  Portal
                </MenuItem>
              ))}
            {/* <MenuItem
              onClick={() => {
                navigate("/admin/billing");
              }}
            >
              Billing
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                localStorage.removeItem("token");
                signOut();
                navigate("/admin");
                handleClose();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </IconButton>
        {/* <IconButton>
          <MailOutlineIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default Topbar;
