import { gql, useMutation, useQuery } from "@apollo/client";
import CommentIcon from "@mui/icons-material/Comment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Skeleton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HighlightVideo1 from "../assets/Highlightvid1.mp4";
import HighlightVideo2 from "../assets/Highlightvid2.MOV";
import HighlightVideo3 from "../assets/Highlightvid3.MOV";
import LogoIcon from "../assets/LogoIcon.png";
import Promo from "../assets/Promo.mp4";
import GeneralContainer from "../components/GeneralContainer";
import MediaLibrary from "../components/MediaModal";
import { useUserContext } from "../providers/UserContext";
import { formatTimestamp, htmlFrom } from "../utilities/format";
import {TopbarSkeleton} from "../components/Menus";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactHtmlParser from 'react-html-parser';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import VideoComponent from "../components/Video";
import PostCard from "../components/CommunityPostCard";


const GET_COMMUNITIES = gql`
  query GetCommunities {
    communities {
      id
      name
      description
      createdAt
    }
  }
`;

const GET_FEED = gql`
  query GetFeed {
    feed {
      id
      title
      content
      viewed
      promoted
      author {
        id: _id
        firstName
        avatar
      }
      community {
        id
        name
      }
      likes
      likedBy {
        id: _id
      }
      createdAt
      media {
        url
        fileType
      }
      comments {
        id: _id
        content
        author {
          id: _id
          firstName
        }
        replies {
          id: _id
          content
          author {
            id: _id
            firstName
          }
        }
      }
    }
  }
`;

const CREATE_POST = gql`
  mutation CreatePost(
    $title: String!
    $content: String!
    $communityId: ID!
    $media: FileUpload
    $studyId: ID
  ) {
    createCommunityPost(
      title: $title
      content: $content
      studyId: $studyId
      communityId: $communityId
      media: $media
    ) {
      id
      title
      content
      viewed
      promoted
      author {
        id: _id
        firstName
        avatar
      }
      community {
        id
        name
      }
      likes
      likedBy {
        id: _id
      }
      createdAt
      comments {
        id: _id

        content
        author {
          id: _id
          firstName
        }
        replies {
          id: _id
          content
          author {
            id: _id
            firstName
          }
        }
      }
    }
  }
`;

const CREATE_COMMUNITY = gql`
  mutation CreateCommunity($name: String!, $description: String!) {
    createCommunity(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

const LIKE_POST = gql`
  mutation LikePost($id: ID!) {
    likeCommunityPost(id: $id) {
      id
      title
      content
      author {
        id: _id
        firstName
      }
      community {
        id
        name
      }
      likes
      createdAt
      comments {
        id: _id
        content
        author {
          id: _id
          firstName
        }
        replies {
          id: _id
          content
          author {
            id: _id
            firstName
          }
        }
      }
    }
  }
`;

const DELETE_POST = gql`
  mutation DeletePost($id: ID!) {
    deleteCommunityPost(id: $id) 
  }
`;

const STUDY_QUERY = gql`
  query GetStudies {
    myStudies {
      id
      title
      nctNumber
      active
      approvalStatus
      campaignIds
      formId {
        id
        name
        fields {
          id
          label
          type
          required
          options
        }
      }
      participants
      influencers
    }
  }
`;

const exampleCommunities = [
  {
    id: "1",
    name: "Health & Wellness",
    description:
      "A community focused on sharing tips and advice for maintaining a healthy lifestyle.",
  },
  {
    id: "2",
    name: "Mental Health",
    description:
      "A supportive community for discussing mental health topics and sharing resources.",
  },
  {
    id: "3",
    name: "Obesity/Diabetes",
    description:
      "A community for sharing information, support, and resources related to obesity and diabetes management.",
  },
  {
    id: "4",
    name: "Nervous System Diseases",
    description:
      "A place to discuss and share information about diseases affecting the nervous system.",
  },
];

const exampleFeed = [
  {
    id: "0",
    title: "Why You Should Participate in Clinical Research",
    description:
      "Learn about the benefits of participating in clinical research...",
    author: { id: "1", firstName: "CSH" },
    community: { id: "1", name: "Health & Wellness" },
    likes: 5,
    likedBy: [{ id: "2" }],
    comments: [
      {
        id: "0",
        content: "Interesting read!",
        author: { id: "2", firstName: "Jane" },
        replies: [],
      },
    ],
    media: Promo,
    mediaType: "video",
    promoted: true,
    createdAt: "1726588882741",
  },
  {
    id: "1",
    title: "The Benefits of a Healthy Diet",
    content: "Eating a balanced diet is crucial for maintaining good health...",
    author: { id: "1", firstName: "John" },
    community: { id: "1", name: "Health & Wellness" },
    likes: 10,
    likedBy: [{ id: "2" }, { id: "3" }],
    comments: [
      {
        id: "1",
        content: "Great article!",
        author: { id: "2", firstName: "Jane" },
        replies: [
          {
            id: "1",
            content: "Thanks!",
            author: { id: "1", firstName: "John" },
          },
        ],
      },
    ],
    createdAt: "1726588882741",
  },
  {
    id: "2",
    title: "Managing Stress Effectively",
    content:
      "Stress management techniques can greatly improve mental health...",
    author: { id: "3", firstName: "Alice" },
    community: { id: "2", name: "Mental Health" },
    likes: 20,
    likedBy: [{ id: "1" }, { id: "4" }],
    comments: [
      {
        id: "2",
        content: "Very informative!",
        author: { id: "4", firstName: "Bob" },
        replies: [],
      },
    ],
    createdAt: "1726588882741",
  },
  {
    id: "3",
    title: "Diabetes Management Tips",
    content:
      "Effective tips for managing diabetes and maintaining a healthy lifestyle...",
    author: { id: "4", firstName: "Bob" },
    community: { id: "3", name: "Obesity/Diabetes" },
    likes: 15,
    likedBy: [{ id: "2" }, { id: "3" }],
    comments: [],
    createdAt: "1726588882741",
  },
  {
    id: "4",
    title: "Understanding Multiple Sclerosis",
    content:
      "An in-depth look at multiple sclerosis and how it affects the nervous system...",
    author: { id: "1", firstName: "John" },
    community: { id: "4", name: "Nervous System Diseases" },
    likes: 25,
    likedBy: [{ id: "2" }, { id: "3" }, { id: "4" }],
    comments: [
      {
        id: "3",
        content: "Can't wait to learn more!",
        author: { id: "3", firstName: "Alice" },
        replies: [],
      },
    ],
    createdAt: "1726588882741",
  },
  {
    id: "5",
    title: "Mental Health Awareness",
    content:
      "It's important to take care of your mental health. Here are some tips...",
    author: { id: "2", firstName: "Jane" },
    community: { id: "2", name: "Mental Health" },
    likes: 30,
    likedBy: [{ id: "1" }, { id: "3" }],
    comments: [],
    createdAt: "1726588882741",
  },
  {
    id: "6",
    title: "Healthy Eating for Diabetics",
    content: "Discover healthy eating habits that can help manage diabetes...",
    author: { id: "3", firstName: "Alice" },
    community: { id: "3", name: "Obesity/Diabetes" },
    likes: 40,
    likedBy: [{ id: "1" }, { id: "2" }],
    comments: [],
    createdAt: "1726588882741",
  },
  {
    id: "7",
    title: "Living with Parkinson's Disease",
    content: "Tips and advice for living with Parkinson's disease...",
    author: { id: "4", firstName: "Bob" },
    community: { id: "4", name: "Nervous System Diseases" },
    likes: 35,
    likedBy: [{ id: "2" }, { id: "3" }],
    comments: [],
    createdAt: "1726588882741",
  },
  {
    id: "8",
    title: "Exercise and Mental Health",
    content: "How regular exercise can improve your mental health...",
    author: { id: "1", firstName: "John" },
    community: { id: "1", name: "Health & Wellness" },
    likes: 50,
    likedBy: [{ id: "2" }, { id: "3" }, { id: "4" }],
    comments: [],
    createdAt: "1726588882741",
  },
  {
    id: "9",
    title: "Coping with Anxiety",
    content: "Effective strategies for coping with anxiety...",
    author: { id: "2", firstName: "Jane" },
    community: { id: "2", name: "Mental Health" },
    likes: 45,
    likedBy: [{ id: "1" }, { id: "3" }],
    comments: [],
    createdAt: "1726588882741",
  },
  {
    id: "10",
    title: "Advances in Neurology",
    content:
      "Recent advances in the field of neurology and their implications...",
    author: { id: "3", firstName: "Alice" },
    community: { id: "4", name: "Nervous System Diseases" },
    likes: 60,
    likedBy: [{ id: "1" }, { id: "2" }],
    comments: [],
    createdAt: "1726588882741",
  },
];

const CommunitiesFeed = () => {
  const { user, userPrimaryRole, joinCommunity, getRemainingInteractions } = useUserContext();
  const navigate = useNavigate();
  const [communities, setCommunities] = useState([...exampleCommunities]);
  const [feed, setFeed] = useState([...exampleFeed]);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [openCommunityModal, setOpenCommunityModal] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState("");
  const [selectedStudy, setSelectedStudy] = useState("");
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState(null);
  const [media, setMedia] = useState(null);
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const accountType = userPrimaryRole();
  const { portalPrefix } = useParams();

  const saveSelection = async (selectedFiles) => {
    // save the selected media to the campaignData object
    console.log("selectedFiles", selectedFiles);
    setMedia(selectedFiles[0]);
    //close modal once done
    setMediaModalOpen(false);
    handleCreatePost(media || selectedFiles[0]);
    // clear form and media
    setContent("");
    setTitle("");
    setMedia(null);
  };

  const {
    loading: loadingFeed,
    error: errorFeed,
    data: dataFeed,
  } = useQuery(GET_FEED, {
    skip: !user,
  });
  const {
    loading: loadingCommunities,
    error: errorCommunities,
    data: dataCommunities,
  } = useQuery(GET_COMMUNITIES, {
    skip: !user,
  });

  const { loading: loadingStudies, error: studiesError, data: studiesData, refetch } = useQuery(STUDY_QUERY, {
    variables: {
      filter: {
        // approvalStatus: tabs[activeTab],
      },
      sortBy: "",
    },
    skip: !user || !user.role.includes("Partner") || !user.organizationId || portalPrefix !== "partner",
  });


  const [createPost] = useMutation(CREATE_POST, {
    refetchQueries: [{ query: GET_FEED }],
  });
  const [createCommunity] = useMutation(CREATE_COMMUNITY);
  const [likePost] = useMutation(LIKE_POST, {
    refetchQueries: [{ query: GET_FEED }],
  });
  const [deletePost] = useMutation(DELETE_POST, {
    refetchQueries: [{ query: GET_FEED }],
  });

  useEffect(() => {
    if (dataCommunities && dataCommunities.communities.length === 0) {
      setCommunities([...dataCommunities.communities, ...exampleCommunities]);
    } else if (dataCommunities) {
      setCommunities(dataCommunities.communities);
    }
  }, [dataCommunities]);

  useEffect(() => {
    if (dataFeed && dataFeed.feed.length === 0) {
      setFeed([...dataFeed.feed, ...exampleFeed]);
    } else if (dataFeed) {
      setFeed(dataFeed.feed);
    }
  }, [dataFeed]);

 
  

  const handleLike = (id) => {
    // Implement the logic to handle the like/upvote action
    console.log(`Liked post with id: ${id}`);
    likePost({
      variables: {
        id,
      },
    });
  };

  const handleOpenPostModal = () => {
    setOpenPostModal(true);
  };

  const handleClosePostModal = () => {
    setOpenPostModal(false);
  };

  const handleOpenCommunityModal = () => {
    setOpenCommunityModal(true);
  };

  const handleCloseCommunityModal = () => {
    setOpenCommunityModal(false);
  };

  const handleCreatePost = async (postMedia) => {
    // Implement the logic to create a new post
    console.log("Creating post...", media);
    createPost({
      variables: {
        title: title,
        content: content,
        communityId: selectedCommunity,
        media: postMedia,
      },
    });
  };

  const handleJoinCommunity = (communityId) => {
    // Implement the logic to handle joining the community
    console.log(`Joined community with id: ${communityId}`);
    joinCommunity({
      variables: {
        communityId: communityId.toString(),
      },
    });
  };

  const isMember = (communityId) => {
    // Implement the logic to check if the user is a member of the community
    // For now, let's assume we have a user object with a list of community IDs they are a member of
    console.log("Checking if user is a member of the community...");
    console.log("user", user.communities);
    console.log("communityId", communityId);
    return (
      user.communities &&
      user.communities.some((community) => community.id === communityId)
    );
  };

  const handleCreateCommunity = async () => {
    // Implement the logic to create a new community
    console.log("Creating community...");
    const response = await createCommunity({
      variables: {
        name,
        description,
      },
    });

    if (response) {
      console.log("Community created successfully!");
    }

    // Add the new community to the list of communities
    setCommunities([
      ...communities,
      {
        id: response.data.createCommunity.id,
        name: response.data.createCommunity.name,
        description: response.data.createCommunity.description,
      },
    ]);

    // Close the modal
    setOpenCommunityModal(false);
    setName("");
    setDescription("");
  };
  const handleMediaUpload = (e) => {
    setMedia(e.target.files[0]);
  };

  const handleIconUpload = (e) => {
    setIcon(e.target.files[0]);
  };

  const likedByUser = (likedBy) => {
    return likedBy.some((like) => like.id === user._id);
  };

  const handleDelete = async (id) => {

    const deleted = await deletePost({
      variables: {
        id,
      },
    });

    console.log("deleted", deleted);
  };

//   const htmlFrom = (htmlString) => {
//     const cleanHtmlString = DOMPurify.sanitize(htmlString,
//       { USE_PROFILES: { html: true } });
//     const html = parse(cleanHtmlString);
//     return html;
// }

  if (loadingFeed || loadingCommunities || !user) {
    return (
      <GeneralContainer userRole={user?.roles} portal={portalPrefix}>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
        </Grid>
      </Box>
      </GeneralContainer>
    );
  }
  if (errorFeed || errorCommunities) return <p>Error :</p>;

  return (
    <GeneralContainer userRole={user?.roles} portal={portalPrefix}>
      <MediaLibrary
        isOpen={mediaModalOpen}
        onClose={() => setMediaModalOpen(false)}
        saveSelection={saveSelection}
        accountType={accountType}
        singleSelection
        primaryActionText="Create"
        user={user}
        engagementsLeft={getRemainingInteractions()}
      />
      <Grid container spacing={0}>
        {/* Sticky top row */}
        <Grid
          item
          xs={12}
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              padding: 2,
              display: "flex",
              justifyContent: "flex-end",
              borderBottom: "1px solid #ddd",
            }}
          >
            {
              user && user.role.includes("Partner") && (
                <Box style={{
                  borderRadius: 10,
                  padding: 10,
                  backgroundColor: 'rgba(0,158,197, .1)',
                  marginRight: 10
                }}>
                  <Typography variant="p">Interactions Remaining:</Typography>
                  <Typography variant="p" color={"#009EC5"} style={{fontWeight: 'bold'}}> {getRemainingInteractions()}</Typography>
                  </Box>
              )
            }
            {portalPrefix !== "patient" && (
              <Button
                variant="contained"
                color="primary"
                sx={{ marginRight: 2 }}
                onClick={handleOpenPostModal} // disable the button if the user.organization is out of brand interactions
              >
                Create Post
              </Button>
            )}
            {portalPrefix === "admin" && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenCommunityModal}
              >
                Create Community
              </Button>
            )}
          </Box>
        </Grid>

        {/* Main content */}
        <Grid
          item
          xs={8}
          sx={{
            padding: 2,
          }}
        >
          {feed.map((post) => (
              <PostCard
                key={post.id}
                post={post}
                user={user}
                portalPrefix={portalPrefix}
                handleDelete={handleDelete}
                handleJoinCommunity={handleJoinCommunity}
                handleLike={handleLike}
                likedByUser={likedByUser}
                isMember={isMember}
                htmlFrom={htmlFrom}
                LogoIcon={LogoIcon}
              />
            )
          )}
        </Grid>

        {/* Sticky box for top communities */}
        <Grid item xs={4}>
          <Box
            sx={{
              position: "sticky",
              top: 80, // Adjust based on the height of the top row
              padding: 2,
              height: 'calc(100vh - 80px)', // Adjust height to fit within the viewport
              overflowY: 'auto', // Enable scrolling
              '&::-webkit-scrollbar': {
                display: 'none', // Hide scrollbar for WebKit browsers
              },
              '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
              'scrollbar-width': 'none', // Hide scrollbar for Firefox
            }}
          >
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6">Top Communities</Typography>
              {communities.map(({ id, name, description, createdAt }) => (
                <Box
                  key={id}
                  sx={{ marginTop: 2 }}
                  className="link"
                  onClick={() => navigate(`/${portalPrefix}/feed/${id}`)}
                >
                  <Typography variant="subtitle1">{name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {description}
                  </Typography>
                </Box>
              ))}
            </Paper>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6">Community Highlights</Typography>
              <Box sx={{ flexDirection: "row", display: "flex" }}>
                <Box
                  sx={{
                    marginTop: 2,
                    height: 300,
                    width: 200,
                    marginRight: 5,
                  }}
                >
                  <video width="100%" height="100%" controls   controlsList="nodownload noplaybackrate noremoteplayback" 
    disablePictureInPicture >
                    <source src={HighlightVideo1} type="video/mp4" />
                  </video>
                </Box>
                <Box
                  sx={{
                    marginTop: 2,
                    height: 300,
                    width: 200,
                    marginRight: 5,
                  }}
                >
                  <video width="100%" height="100%" controls   controlsList="nodownload noplaybackrate noremoteplayback" 
    disablePictureInPicture >
                    <source src={HighlightVideo2} type="video/mp4" />
                  </video>
                </Box>
                <Box
                  sx={{
                    marginTop: 2,
                    height: 300,
                    width: 200,
                    marginRight: 5,
                  }}
                >
                  <video width="100%" height="100%" controls   controlsList="nodownload noplaybackrate noremoteplayback" 
    disablePictureInPicture >
                    <source src={HighlightVideo3} type="video/mp4" />
                  </video>
                </Box>
              </Box>
            </Paper>
            <Paper sx={{ padding: 2 }}>
              {/* add a link for patients to view recommended studies */}
              {portalPrefix === "patient" && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h6">Recommended Studies</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2 }}
                    onClick={() => navigate("/patient/browse-studies")}
                  >
                    View Studies
                  </Button>
                </Box>
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>

      {/* Create Post Modal */}
      <Dialog open={openPostModal} onClose={handleClosePostModal}  maxWidth="lg" // Set the maximum width to large
  fullWidth
  PaperProps={{
    style: {
      height: '80vh', // Set the height to 80% of the viewport height
      width: '80vw', // Set the width to 80% of the viewport width
    },
  }}
    >
        <DialogTitle>Create Post</DialogTitle>
        <DialogContent style={{padding: 20}}>
          <TextField
            select
            margin="dense"
            label="Community"
            fullWidth
            variant="outlined"
            required
            value={selectedCommunity}
            onChange={(e) => setSelectedCommunity(e.target.value)}
            style={{ marginBottom: 20 }}
          >
            {communities.map((community) => (
              <MenuItem key={community.id} value={community.id}>
                {community.name}
              </MenuItem>
            ))}
          </TextField>
          {user.roles.includes("Partner") && portalPrefix === "partner" &&  (
            <TextField
            select
            margin="dense"
            label="Campaign"
            fullWidth
            variant="outlined"
            required
            value={selectedStudy}
            onChange={(e) => setSelectedStudy(e.target.value)}
            style={{ marginBottom: 20 }}
          >
            {studiesData?.myStudies.map((community) => (
              <MenuItem key={community.id} value={community.id}>
                {community.title}
              </MenuItem>
            ))}
          </TextField>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ marginBottom: 20 }}
          />
          {/* <TextField
            margin="dense"
            label="Content"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={content}
            required
            onChange={(e) => setContent(e.target.value)}
          /> */}
           <ReactQuill
      value={content}
      onChange={setContent}
      modules={{
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline'],
          ['link'],
          ['clean']
        ],
      }}
      formats={[
        'header', 'font', 'list', 'bullet',
        'bold', 'italic', 'underline',
        'link', 'image'
      ]}
      style={{
      height: 200,
      }}
    />
          {/* <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
            Upload Media
            <input type="file" hidden onChange={handleMediaUpload} />
          </Button> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePostModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClosePostModal();
              setMediaModalOpen(true);
            }}
            color="primary"
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Community Modal */}
      <Dialog open={openCommunityModal} onClose={handleCloseCommunityModal}>
        <DialogTitle>Create Community</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
          {/* <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
            Upload Icon
            <input type="file" hidden onChange={handleIconUpload} />
          </Button>
          {icon && (
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              {icon.name}
            </Typography>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCommunityModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              // handleCloseCommunityModal();
              // setMediaModalOpen(true);
              handleCreateCommunity();
            }}
            color="primary"
          > 
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </GeneralContainer>
  );
};

export default CommunitiesFeed;
