import { gql, useQuery, useMutation } from "@apollo/client";
import CommentIcon from "@mui/icons-material/Comment";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Skeleton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LogoIcon from "../assets/LogoIcon.png";
import GeneralContainer from "../components/GeneralContainer";
import { useUserContext } from "../providers/UserContext";
import { formatLargeNumber, formatTimestamp, htmlFrom } from "../utilities/format";
import VideoComponent from "../components/Video";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MediaLibrary from "../components/MediaModal";
import PostCard from "../components/CommunityPostCard";


const GET_COMMUNITY = gql`
  query GetCommunity($id: ID!) {
    community(id: $id) {
      id
      name
      description
      memberCount
    }
  }
`;

const GET_FEED = gql`
  query GetCommunityFeed($id: ID!) {
    communityFeed(id: $id) {
      id
      title
      content
      viewed
      promoted
      author {
        id: _id
        firstName
        avatar
      }
      community {
        id
        name
      }
      likes
      likedBy {
        id: _id
      }
      createdAt
      media {
        url
        fileType
      }
      comments {
        id: _id
        content
        author {
          id: _id
          firstName
        }
        replies {
          id: _id
          content
          author {
            id: _id
            firstName
          }
        }
      }
    }
  }
`;

const CREATE_POST = gql`
  mutation CreatePost(
    $title: String!
    $content: String!
    $communityId: ID!
    $media: FileUpload
    $studyId: ID
  ) {
    createCommunityPost(
      title: $title
      content: $content
      studyId: $studyId
      communityId: $communityId
      media: $media
    ) {
      id
      title
      content
      viewed
      promoted
      author {
        id: _id
        firstName
        avatar
      }
      community {
        id
        name
      }
      likes
      likedBy {
        id: _id
      }
      createdAt
      comments {
        id: _id

        content
        author {
          id: _id
          firstName
        }
        replies {
          id: _id
          content
          author {
            id: _id
            firstName
          }
        }
      }
    }
  }
`;


const CREATE_COMMUNITY = gql`
  mutation CreateCommunity($name: String!, $description: String!) {
    createCommunity(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

const LIKE_POST = gql`
  mutation LikePost($id: ID!) {
    likeCommunityPost(id: $id) {
      id
      title
      content
      author {
        id: _id
        firstName
      }
      community {
        id
        name
      }
      likes
      createdAt
      comments {
        id: _id
        content
        author {
          id: _id
          firstName
        }
        replies {
          id: _id
          content
          author {
            id: _id
            firstName
          }
        }
      }
    }
  }
`;

const DELETE_POST = gql`
  mutation DeletePost($id: ID!) {
    deleteCommunityPost(id: $id) 
  }
`;

const STUDY_QUERY = gql`
  query GetStudies {
    myStudies {
      id
      title
      nctNumber
      active
      approvalStatus
      campaignIds
      formId {
        id
        name
        fields {
          id
          label
          type
          required
          options
        }
      }
      participants
      influencers
    }
  }
`;



const exampleCommunity = {
  id: "66e9a7d2a8b0fcd27b4e8b52",
  name: "Health & Wellness",
  description:
    "A community focused on sharing tips and advice for maintaining a healthy lifestyle.",
  memberCount: 1230000,
};

const exampleFeed = [
  {
    id: "1",
    title: "The Benefits of a Healthy Diet",
    content: "Eating a balanced diet is crucial for maintaining good health...",
    author: {
      id: "1",
      firstName: "John",
      image:
        "https://authentic.co/wp-content/uploads/authentic-spi-JB20181023-preview.jpg",
    },
    community: { id: "1", name: "Health & Wellness" },
    likes: 10,
    likedBy: [{ id: "2" }, { id: "3" }],
    comments: [
      {
        id: "1",
        content: "Great article!",
        author: { id: "2", firstName: "Jane" },
        replies: [
          {
            id: "1",
            content: "Thanks!",
            author: { id: "1", firstName: "John" },
          },
        ],
      },
    ],
    createdAt: "1726588882741",
  },
  {
    id: "8",
    title: "Exercise and Mental Health",
    content: "How regular exercise can improve your mental health...",
    author: {
      id: "1",
      firstName: "John",
      image:
        "https://authentic.co/wp-content/uploads/authentic-spi-JB20181023-preview.jpg",
    },
    community: { id: "1", name: "Health & Wellness" },
    likes: 50,
    likedBy: [{ id: "2" }, { id: "3" }, { id: "4" }],
    comments: [],
    createdAt: "1726588882741",
  },
  {
    id: "11",
    title: "Healthy Sleep Habits",
    content: "Tips for improving your sleep quality and overall health...",
    media:
      "https://imageio.forbes.com/specials-images/imageserve/608f60ba64833a296fe612fc/Woman-sleeping-in-her-bed-at-home/960x0.jpg?format=jpg&width=1440",
    mediaType: "image",
    author: {
      id: "3",
      firstName: "Alice",
      image:
        "https://imageio.forbes.com/specials-images/imageserve/5f6a779460548326baf6d730//960x0.jpg?format=jpg&width=960",
    },
    community: { id: "1", name: "Health & Wellness" },
    likes: 30,
    likedBy: [{ id: "1" }, { id: "2" }],
    comments: [
      {
        id: "4",
        content: "Very helpful, thanks!",
        author: {
          id: "4",
          firstName: "Bob",
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW_el0K5Ct5T9M0vXJDEXasgd2QfdAVZdC8g&s",
        },
        replies: [],
      },
    ],
    createdAt: "1726588882741",
  },
  {
    id: "12",
    title: "The Importance of Hydration",
    content: "Staying hydrated is essential for maintaining good health...",
    media:
      "https://media.istockphoto.com/id/636083442/photo/maintaining-good-hydration-also-supports-healthy-weight-loss.jpg?s=612x612&w=0&k=20&c=vbXF2tYYdZJICUJ09A5jnfxWJYbp5aqDrDMiHkUvgPA=",
    mediaType: "image",
    author: {
      id: "2",
      firstName: "Jane",
      image:
        "https://campusdata.uark.edu/resources/images/articles/2022-02-15_11-14-36-AMChulyndriaLayeDeansSpotlightheadshot.jpg",
    },
    community: { id: "1", name: "Health & Wellness" },
    likes: 20,
    likedBy: [{ id: "3" }, { id: "4" }],
    comments: [],
    createdAt: "1726588882741",
  },
  {
    id: "13",
    title: "Yoga for Beginners",
    content: "A guide to starting yoga and its benefits for your health...",
    author: {
      id: "4",
      firstName: "Bob",
      image:
        "https://media.istockphoto.com/id/1289461335/photo/portrait-of-a-handsome-black-man.jpg?s=612x612&w=0&k=20&c=gDibbpmkeV04ta3ociwAgpqcjdeU5sI1nnd78wrnz-g=",
    },
    community: { id: "1", name: "Health & Wellness" },
    likes: 40,
    likedBy: [{ id: "1" }, { id: "2" }],
    comments: [],
    createdAt: "1726588882741",
  },
];

const CommunityFeed = () => {
  const { user, userPrimaryRole, joinCommunity, getRemainingInteractions } = useUserContext();
  const navigate = useNavigate();
  const { portalPrefix, id } = useParams();
  const [community, setCommunity] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [feed, setFeed] = useState([]);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [openCommunityModal, setOpenCommunityModal] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState(community?.id);
  const [selectedStudy, setSelectedStudy] = useState("");
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState(null);
  const [media, setMedia] = useState(null);
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const accountType = userPrimaryRole();
  const {
    loading: loadingFeed,
    error: errorFeed,
    data: dataFeed,
  } = useQuery(GET_FEED, {
    skip: !user || !id,
    variables: { id },
  });
  const {
    loading: loadingCommunities,
    error: errorCommunities,
    data: dataCommunities,
  } = useQuery(GET_COMMUNITY, {
    skip: !user || !id,
    variables: { id },
  });

  const { loading: loadingStudies, error: studiesError, data: studiesData, refetch } = useQuery(STUDY_QUERY, {
    variables: {
      filter: {
        // approvalStatus: tabs[activeTab],
      },
      sortBy: "",
    },
    skip: !user || !user.role.includes("Partner") || !user.organizationId || portalPrefix !== "partner",
  });


  const [createPost] = useMutation(CREATE_POST, {
    refetchQueries: [{ query: GET_FEED, variables: { id } }],
  });
  const [createCommunity] = useMutation(CREATE_COMMUNITY);
  const [likePost] = useMutation(LIKE_POST, {
    refetchQueries: [{ query: GET_FEED, variables: { id } }],
  });

  const [deletePost] = useMutation(DELETE_POST, {
    refetchQueries: [{ query: GET_FEED, variables: { id } }],
  });

  useEffect(() => {
    console.log("dataCommunities", dataCommunities, loadingCommunities);
    if (!loadingCommunities && dataCommunities && dataCommunities?.community) {
      setCommunity(dataCommunities.community);
    }
  }, [dataCommunities, loadingCommunities]);

  console.log("dataFeed", dataFeed, loadingFeed);

  useEffect(() => {
    if (!loadingFeed && dataFeed && dataFeed.communityFeed) {
      setFeed(dataFeed.communityFeed);
    } 
  }, [dataFeed, loadingFeed]);

  const saveSelection = async (selectedFiles) => {
    // save the selected media to the campaignData object
    console.log("selectedFiles", selectedFiles);
    setMedia(selectedFiles[0]);
    //close modal once done
    setMediaModalOpen(false);
    handleCreatePost(media || selectedFiles[0]);
    // clear form and media
    setContent("");
    setTitle("");
    setMedia(null);
  };

  
  const handleLike = (id) => {
    // Implement the logic to handle the like/upvote action
    console.log(`Liked post with id: ${id}`);
    likePost({
      variables: {
        id,
      },
    });
  };

  const handleOpenPostModal = () => {
    setOpenPostModal(true);
  };

  const handleClosePostModal = () => {
    setOpenPostModal(false);
  };

  const handleOpenCommunityModal = () => {
    setOpenCommunityModal(true);
  };

  const handleCloseCommunityModal = () => {
    setOpenCommunityModal(false);
  };

  const handleCreatePost = async (postMedia) => {
    // Implement the logic to create a new post
    console.log("Creating post...", media);
    createPost({
      variables: {
        title: title,
        content: content,
        communityId: selectedCommunity || community.id,
        media: postMedia,
      },
    });
  };

  const handleCreateCommunity = async () => {
    // Implement the logic to create a new community
    console.log("Creating community...");
    const response = await createCommunity({
      variables: {
        name,
        description,
      },
    });

    if (response) {
      console.log("Community created successfully!");
    }

    // Add the new community to the list of communities
    setCommunities([
      ...communities,
      {
        id: response.data.createCommunity.id,
        name: response.data.createCommunity.name,
        description: response.data.createCommunity.description,
      },
    ]);

    // Close the modal
    setOpenCommunityModal(false);
    setName("");
    setDescription("");
  };

  const handleJoinCommunity = (communityId) => {
    // Implement the logic to handle joining the community
    console.log(`Joined community with id: ${communityId}`);
    joinCommunity({
      variables: {
        communityId,
      },
    });
  };

  const isMember = (communityId) => {
    // Implement the logic to check if the user is a member of the community
    // For now, let's assume we have a user object with a list of community IDs they are a member of
    return (
      user.communities &&
      user.communities.some((community) => community.id === communityId)
    );
    // return user?.communities?.includes(communityId);
  };

  const likedByUser = (likedBy) => {
    return likedBy.some((like) => like.id === user._id);
  };


  const handleDelete = async (id) => {

    const deleted = await deletePost({
      variables: {
        id,
      },
    });

    console.log("deleted", deleted);
  };


//   const htmlFrom = (htmlString) => {
//     const cleanHtmlString = DOMPurify.sanitize(htmlString,
//       { USE_PROFILES: { html: true } });
//     const html = parse(cleanHtmlString);
//     return html;
// }

  if (loadingFeed || loadingCommunities || !user) {
    return (
      <GeneralContainer userRole={user?.roles} portal={portalPrefix}>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
        </Grid>
      </Box>
      </GeneralContainer>
    );
  }
  if (errorFeed || errorCommunities)
    return (
      <p>
        {errorFeed && "Feed error: " + errorFeed.toString()}{" "}
        {errorCommunities &&
          "communities Error: " + errorCommunities.toString()}
      </p>
    );


  return (
    <GeneralContainer userRole={user?.roles} portal={portalPrefix}>
        <MediaLibrary
        isOpen={mediaModalOpen}
        onClose={() => setMediaModalOpen(false)}
        saveSelection={saveSelection}
        accountType={accountType}
        singleSelection
        primaryActionText="Create"
        user={user}
        engagementsLeft={getRemainingInteractions()}
      />
      <Grid container spacing={0}>
        {/* Sticky top row */}
        <Grid
          item
          xs={12}
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              padding: 2,
              display: "flex",
              justifyContent: "flex-end",
              borderBottom: "1px solid #ddd",
            }}
          >
             {
              user && user.role.includes("Partner") && (
                <Box style={{
                  borderRadius: 10,
                  padding: 10,
                  backgroundColor: 'rgba(0,158,197, .1)',
                  marginRight: 10
                }}>
                  <Typography variant="p">Interactions Remaining:</Typography>
                  <Typography variant="p" color={"#009EC5"} style={{fontWeight: 'bold'}}> {getRemainingInteractions()}</Typography>
                  </Box>
              )
            }
            {portalPrefix !== "patient" && (
              <Button
                variant="contained"
                color="primary"
                sx={{ marginRight: 2 }}
                onClick={handleOpenPostModal} // disable the button if the user.organization is out of brand interactions
              > 
                Create Post
              </Button>
            )}
            {portalPrefix === "admin" && (
              <Button variant="contained" color="secondary">
                Create Community
              </Button>
            )}
          </Box>
        </Grid>

        {/* Main content */}
        <Grid
          item
          xs={8}
          sx={{
            padding: 2,
          }}
        >
           {feed.map((post) => (
              <PostCard
                key={post.id}
                post={post}
                user={user}
                portalPrefix={portalPrefix}
                handleDelete={handleDelete}
                handleJoinCommunity={handleJoinCommunity}
                handleLike={handleLike}
                likedByUser={likedByUser}
                isMember={isMember}
                htmlFrom={htmlFrom}
                LogoIcon={LogoIcon}
              />
            )
          )}
        </Grid>

        {/* Sticky box for top community */}
        <Grid item xs={4}>
          <Box
            sx={{
              position: "sticky",
              top: 80, // Adjust based on the height of the top row
              padding: 2,
            }}
          >
            <Paper sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    height: 50,
                    width: 50,
                    borderRadius: 50,
                    marginRight: 2,
                  }}
                >
                  <img
                    src={LogoIcon || "https://via.placeholder.com/50"}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: 50,
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {!isMember(community.id) && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        marginLeft: 2,
                        borderRadius: "50px",
                        textTransform: "none",
                      }}
                      onClick={() => handleJoinCommunity(community.id)}
                    >
                      Join
                    </Button>
                  )}
                  {isMember(community.id) && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        marginLeft: 2,
                        borderRadius: "50px",
                        textTransform: "none",
                        opacity: 0.8,
                      }}
                      onClick={() => handleJoinCommunity(community.id)}
                    >
                      Leave
                    </Button>
                  )}
                </Box>
              </Box>
              <Typography variant="h6">{community.name}</Typography>
              <Box>
                <Typography variant="subtitle1">
                  {community.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {community.memberCount && formatLargeNumber(community.memberCount)} members
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      {/* Create Post Modal */}
      <Dialog open={openPostModal} onClose={handleClosePostModal}  maxWidth="lg" // Set the maximum width to large
  fullWidth
  PaperProps={{
    style: {
      height: '80vh', // Set the height to 80% of the viewport height
      width: '80vw', // Set the width to 80% of the viewport width
    },
  }}
    >
        <DialogTitle>Create Post</DialogTitle>
        <DialogContent style={{padding: 20}}>
          <TextField
            select
            margin="dense"
            label="Community"
            fullWidth
            variant="outlined"
            required
            value={selectedCommunity || community.id}
            onChange={(e) => setSelectedCommunity(e.target.value)}
            style={{ marginBottom: 20 }}
          >
            {[community].map((community) => (
              <MenuItem key={community.id} value={community.id}>
                {community.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ marginBottom: 20 }}
          />
           {user.roles.includes("Partner") && portalPrefix === "partner" &&  (
            <TextField
            select
            margin="dense"
            label="Campaign"
            fullWidth
            variant="outlined"
            required
            value={selectedStudy}
            onChange={(e) => setSelectedStudy(e.target.value)}
            style={{ marginBottom: 20 }}
          >
            {studiesData?.myStudies.map((community) => (
              <MenuItem key={community.id} value={community.id}>
                {community.title}
              </MenuItem>
            ))}
          </TextField>
          )}
          {/* <TextField
            margin="dense"
            label="Content"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={content}
            required
            onChange={(e) => setContent(e.target.value)}
          /> */}
           <ReactQuill
      value={content}
      onChange={setContent}
      modules={{
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline'],
          ['link'],
          ['clean']
        ],
      }}
      formats={[
        'header', 'font', 'list', 'bullet',
        'bold', 'italic', 'underline',
        'link', 'image'
      ]}
      style={{
      height: 200,
      }}
    />
          {/* <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
            Upload Media
            <input type="file" hidden onChange={handleMediaUpload} />
          </Button> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePostModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClosePostModal();
              setMediaModalOpen(true);
            }}
            color="primary"
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Community Modal */}
      <Dialog open={openCommunityModal} onClose={handleCloseCommunityModal}>
        <DialogTitle>Create Community</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={description}
            required
            onChange={(e) => setDescription(e.target.value)}
          />
          {/* <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
            Upload Icon
            <input type="file" hidden onChange={handleIconUpload} />
          </Button>
          {icon && (
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              {icon.name}
            </Typography>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCommunityModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              // handleCloseCommunityModal();
              // setMediaModalOpen(true);
              handleCreateCommunity();
            }}
            color="primary"
          > 
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </GeneralContainer>
  );
};

export default CommunityFeed;
