import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Error404() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <Row>
        <Col className="text-center">
          <h1 style={{ fontSize: "6em", color: "#ff0000" }}>404</h1>
          <h2>Page Not Found</h2>
          <p>
            The page you are looking for might have been removed, had its name
            changed, or is temporarily unavailable.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Error404;
