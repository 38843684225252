import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const availableFields = [
  // { id: "text-1", content: "Text Field", type: "text" },
  // { id: "checkbox-1", content: "Checkbox", type: "checkbox" },
  {
    id: "radio-1",
    content: "Radio",
    type: "radio",
    options: ["Option 1", "Option 2", "Option 3"],
    correctAnswers: [],
  },
];

// const EditableField = ({ field, onChange, onRemove }) => {
//   console.log("field", field);
//   const [isEditing, setIsEditing] = useState(false);
//   const [localField, setLocalField] = useState({ ...field });
//   const [radioOptions, setRadioOptions] = useState(field.options || []);
//   const [correctAnswers, setCorrectAnswers] = useState(
//     field.correctAnswers || []
//   );

//   const handleLabelChange = (e) => {
//     setLocalField({ ...localField, label: e.target.value });
//   };

//   const handleRequiredChange = (e) => {
//     setLocalField({ ...localField, required: e.target.checked });
//   };

//   const handleQualifyingAnswerChange = (e) => {
//     setLocalField({ ...localField, qualifyingAnswer: e.target.value });
//   };

//   const handleEdit = () => {
//     setIsEditing(true);
//   };

//   const handleRemove = (e) => {
//     console.log("field", field);
//     // const field = e.target.closest(".draggable").id;
//     onRemove(field.id);
//   };

//   const handleOptionChange = (event, index) => {
//     const newOptions = [...radioOptions];
//     newOptions[index] = event.target.value;
//     setRadioOptions(newOptions);
//   };

//   const handleAddOption = () => {
//     setRadioOptions([...radioOptions, ""]);
//   };

//   const handleAddYesNoUnsureOptions = () => {
//     setRadioOptions(["Yes", "No", "Unsure"]);
//   };

//   const handleRemoveOption = (index) => {
//     const newOptions = [...radioOptions];
//     newOptions.splice(index, 1);
//     setRadioOptions(newOptions);
//   };

//   const handleCorrectAnswerChange = (event) => {
//     const { value } = event.target;
//     console.log("value", value, correctAnswers);
//     setCorrectAnswers((prev) =>
//       prev.includes(value)
//         ? prev.filter((answer) => answer !== value)
//         : [...prev, value]
//     );
//   };
//   const handleDone = () => {
//     onChange({ ...localField, options: radioOptions, correctAnswers });
//     setIsEditing(false);
//   };

//   return (
//     <div
//       style={{ border: "1px solid #ccc", padding: "10px", margin: "10px 0" }}
//     >
//       {isEditing ? (
//         <>
//           <TextField
//             label="Label"
//             value={localField.label || ""}
//             onChange={handleLabelChange}
//             fullWidth
//             margin="normal"
//           />
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={localField.required || false}
//                 onChange={handleRequiredChange}
//               />
//             }
//             label="Required"
//           />
//           {localField.type === "text" && (
//             <TextField
//               label="Qualifying Answer"
//               value={localField.qualifyingAnswer || ""}
//               onChange={handleQualifyingAnswerChange}
//               fullWidth
//               margin="normal"
//             />
//           )}
//           {localField.type === "radio" && (
//             <FormControl component="fieldset" margin="normal">
//               <FormLabel component="legend">Radio Options</FormLabel>
//               {radioOptions.map((option, index) => (
//                 <div key={index}>
//                   <TextField
//                     value={option}
//                     onChange={(e) => handleOptionChange(e, index)}
//                     style={{ marginRight: "10px" }}
//                   />
//                   <Checkbox
//                     checked={correctAnswers.includes(option)}
//                     onChange={handleCorrectAnswerChange}
//                     value={option}
//                   />
//                   <Button onClick={() => handleRemoveOption(index)}>
//                     Remove
//                   </Button>
//                 </div>
//               ))}
//               <Button onClick={handleAddOption}>Add Option</Button>
//               <Button onClick={handleAddYesNoUnsureOptions}>
//                 Add Yes, No, Unsure Options
//               </Button>
//             </FormControl>
//           )}
//           <Button
//             variant="contained"
//             onClick={handleDone}
//             style={{ marginTop: "10px" }}
//           >
//             Done
//           </Button>
//         </>
//       ) : (
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-evenly",
//           }}
//         >
//           {localField.type === "text" ? (
//             <TextField
//               label={localField.label || "Text Field"}
//               variant="outlined"
//               margin="normal"
//               style={{ width: "70%" }}
//             />
//           ) : localField.type === "radio" ? (
//             <RadioGroup>
//               <FormLabel component="legend">
//                 {localField.label || "Edit field"}
//               </FormLabel>
//               {radioOptions.map((option, index) => (
//                 <FormControlLabel
//                   key={index}
//                   value={option}
//                   control={<Radio />}
//                   label={option}
//                 />
//               ))}
//             </RadioGroup>
//           ) : (
//             <FormControlLabel
//               control={<Checkbox />}
//               label={localField.label || "Checkbox"}
//             />
//           )}
//           <div
//             style={{
//               display: "flex",
//             }}
//           >
//             <Button
//               variant="outlined"
//               onClick={handleEdit}
//               style={{ marginTop: "10px", marginRight: "10px" }}
//             >
//               Edit
//             </Button>
//             <Button
//               variant="outlined"
//               style={{ marginTop: "10px", backgroundColor: "#f44336" }}
//               onClick={handleRemove}
//             >
//               <DeleteIcon style={{ color: "#fff" }} />
//             </Button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

const EditableField = ({ field, onChange, onRemove }) => {
  console.log("field", field);
  switch (field.type) {
    case "text":
      return (
        <TextField
          label={field.label}
          value={field.value}
          onChange={(e) =>
            onChange(field.id, { ...field, value: e.target.value })
          }
          fullWidth
          margin="normal"
        />
      );
    case "radio":
      return (
        <RadioGroup
          value={field.value || ""}
          onChange={(e) =>
            onChange(field.id, { ...field, value: e.target.value })
          }
        >
          <FormLabel component="legend">{field.label || "Radio"}</FormLabel>
          {field.options &&
            field.options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio />}
                label={option}
              />
            ))}
        </RadioGroup>
      );
    // Add more field types as needed
    default:
      return null;
  }
};

const FormBuilder = ({ formFields: fields, setFormFields: setFields }) => {
  // const [fields, setFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === "availableFields") {
      const fieldToAdd = availableFields.find(
        (field) => field.id === result.draggableId
      );
      setFields([
        ...fields,
        { ...fieldToAdd, id: `field-${fields.length + 1}` },
      ]);
    } else {
      const newFields = Array.from(fields);
      const [removed] = newFields.splice(result.source.index, 1);
      newFields.splice(result.destination.index, 0, removed);
      setFields(newFields);
    }
  };

  const handleFieldChange = (updatedField) => {
    console.log("updatedField", updatedField, 'fields', fields);
    setFields(fields.map((f) => (f?.id === updatedField?.id ? updatedField : f)));
  };

  const removeField = (fieldId) => {
    setFields(fields.filter((f) => f.id !== fieldId));
    setSelectedField(null);
  };

  const handleFieldSelect = (field) => {
    setSelectedField(field);
  };

  const handleFieldUpdate = (updatedField) => {
    setFields(
      fields.map((field) =>
        field.id === updatedField.id ? updatedField : field
      )
    );
    setSelectedField(updatedField);
  };
  const handleAddOption = () => {
    const updatedField = {
      ...selectedField,
      options: [...selectedField.options, ""],
    };
    handleFieldUpdate(updatedField);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedField.options];
    updatedOptions.splice(index, 1);
    const updatedField = { ...selectedField, options: updatedOptions };
    handleFieldUpdate(updatedField);
  };

  const handleOptionChange = (event, index) => {
    const updatedOptions = [...selectedField.options];
    updatedOptions[index] = event.target.value;
    const updatedField = { ...selectedField, options: updatedOptions };
    handleFieldUpdate(updatedField);
  };

  const handleAddYesNoUnsureOptions = () => {
    const updatedField = {
      ...selectedField,
      options: ["Yes", "No", "Unsure"],
    };
    handleFieldUpdate(updatedField);
  };

  const handleDuplicateField = () => {
    const duplicatedField = {
      ...selectedField,
      id: `field-${fields.length + 1}`,
    };
    setFields([...fields, duplicatedField]);
  };

  const handleCorrectAnswerChange = (event, index) => {
    const updatedField = {
      ...selectedField,
      correctAnswers: selectedField.correctAnswers?.includes(index.toString())
        ? selectedField.correctAnswers?.filter((answerIndex) => answerIndex !== index.toString())
        : [...(selectedField.correctAnswers || []), `${index}`],
    };
    handleFieldUpdate(updatedField);
  };

  console.log('fields', fields, fields.length);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Droppable droppableId="availableFields">
            {(provided) => (
              <Paper
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  minHeight: "600px",
                  paddingTop: "10px",
                  borderRadius: "10px",
                  // margin: "10px 0",
                }}
              >
                <h3>Add Fields</h3>
                <hr />
                {availableFields.map((field, index) => (
                  <Draggable
                    key={field.id}
                    draggableId={field.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          marginBottom: "10px",
                          cursor: "pointer",
                          backgroundColor: "#FAFAFA",
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "18px",
                          padding: "10px",
                          margin: "10px",
                        }}
                      >
                        <RadioButtonCheckedIcon
                          style={{
                            marginRight: "10px",
                            color: "#fff",
                            backgroundColor: "#009EC5",
                            borderRadius: 5,
                            padding: 5,
                          }}
                        />
                        {field.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Paper>
            )}
          </Droppable>
        </Grid>
        <Grid item xs={6}>
          <Droppable droppableId="formBuilder">
            {(provided) => (
              <Paper
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  minHeight: "600px",
                  padding: "10px",
                  borderRadius: "10px",
                  overflowY: "scroll",
                }}
              >
                {fields.map((fieldToEdit, index) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style,
                          border: selectedField?.id === fieldToEdit.id && "1px solid #009EC5",
                          borderRadius: "10px",
                          marginBottom: "10px",
                          cursor: "pointer",
                          textAlign: "left",
                          fontWeight: "bold",
                          fontSize: "18px",
                          padding: "20px",
                          margin: "10px",
                         }}
                        onClick={() => handleFieldSelect(fieldToEdit)}
                      >
                        {console.log('Field before sent to editable:', fieldToEdit)} 
                        <EditableField
                          field={fieldToEdit}
                          onChange={handleFieldChange}
                          onRemove={removeField}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Paper>
            )}
          </Droppable>
        </Grid>
        <Grid item xs={3}>
          {selectedField && (
            <Paper style={{ padding: "10px", borderRadius: "10px" }}>
              <h5>Field Properties</h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Button onClick={handleDuplicateField}>Duplicate</Button>
                <Button onClick={() => removeField(selectedField.id)}>
                  Delete
                </Button>
              </div>
              <TextField
                label="Label"
                value={selectedField.label || ""}
                onChange={(e) =>
                  handleFieldUpdate({ ...selectedField, label: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              {selectedField.type !== "radio" && (
                <TextField
                  label="Value"
                  value={selectedField.value || ""}
                  onChange={(e) =>
                    handleFieldUpdate({
                      ...selectedField,
                      value: e.target.value,
                    })
                  }
                  fullWidth
                  margin="normal"
                />
              )}
              {selectedField.type === "radio" && (
  <>
    <FormLabel component="legend">Radio Options</FormLabel>
    <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
      <div style={{ flex: 1, marginRight: "10px" }}>
        <FormLabel>Question Values</FormLabel>
      </div>
      <div style={{ width: "100px", textAlign: "center" }}>
        <FormLabel>Correct Answer</FormLabel>
      </div>
      <div style={{ flex: 1, marginRight: "10px" }}>
        <FormLabel>Remove option</FormLabel>
      </div>
    </div>
    
    {selectedField.options &&
      selectedField.options.map((option, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
        >
          <TextField
            value={option}
            onChange={(e) => handleOptionChange(e, index)}
            style={{ flex: 1, marginRight: "10px" }}
          />
          {console.log('selectedField', selectedField, selectedField.correctAnswers?.includes(`${index}`), index)}
          <Checkbox
            checked={selectedField.correctAnswers?.includes(`${index}`)}
            onChange={(event) => handleCorrectAnswerChange(event, index)}
            value={option}
            style={{ width: "100px", textAlign: "center" }}
          />
          <Button onClick={() => handleRemoveOption(index)}>
            Remove
          </Button>
        </div>
      ))}
    <Button onClick={handleAddOption}>Add Option</Button>
    <Button onClick={handleAddYesNoUnsureOptions}>
      Add Yes, No, Unsure Options
    </Button>
  </>
)}

              <Button
                onClick={() => setSelectedField(null)}
                style={{ marginTop: "10px" }}
              >
                Close
              </Button>
            </Paper>
          )}
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default FormBuilder;
