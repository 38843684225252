import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const faqData = [
  {
    question: "What Is A Digital Health Influencer (DHI)?",
    answer:
      "A Digital Health Influencer is a culturally competent community member that leverages their social media to help educate and provide access to clinical research for their community.",
  },
  {
    question: "What Social Media Platforms Will DHI’s Be Using?",
    answer:
      "Digital Health Influencers use a variety of platforms including but not limited to: Facebook, Instagram, Twitter, & Tiktok. The specifics of the platform depend on the recruitment campaign.",
  },
  {
    question: "Are DHI’s compensated for their work?",
    answer:
      "All DHI’s are compensated at a monthly rate for their services. Compensation is NOT tied to participant identification, referral, or enrollment.",
  },
  {
    question: "Why do you need access to my social media accounts?",
    answer:
      "We need access to your social media accounts to ensure that you are a real person and that you are using your own social media accounts. We also need access to your social media accounts to post content on your behalf.",
  },
];

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="App">
      <div
        className="App-body"
        style={{
          backgroundColor: "#fff",
          color: "#000",
          margin: "0px",
          marginTop: 20,
          display: "block",
        }}
      >
        <div className="faq-page">
          <h1>Frequently Asked Questions</h1>
          <ul style={{ listStyleType: "none", marginTop: 50 }}>
            {faqData.map((item, index) => (
              <li key={index} style={{ marginBottom: "0.5rem" }}>
                <div
                  className="faq-question"
                  style={{
                    border: "0px",
                    borderBottom: "1px solid #ccc",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1rem 0",
                  }}
                  onClick={() => handleClick(index)}
                >
                  <span>{item.question}</span>
                  {activeIndex === index ? (
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      style={{
                        alignSelf: "center",
                        fontSize: "20px",
                        color: "#000",
                        marginLeft: 10,
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={{
                        alignSelf: "center",
                        fontSize: "20px",
                        color: "#000",
                        marginLeft: 10,
                      }}
                    />
                  )}
                </div>
                {activeIndex === index && (
                  <div className="faq-answer">{item.answer}</div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
