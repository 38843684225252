import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PartnerHeader } from "../../components/Menus";
import FormBuilder from "./FormBuilder";

const Text = styled.p`
  color: #000;
  text-align: left;
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

function PreScreenForm() {
  const navigate = useNavigate();
  return (
    <div className="App">
      {/* <header className="App-header">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                backgroundColor: "#000",
              }}
            >
              <h1 style={{ color: "#fff", alignSelf: "center" }}>Partner Portal</h1>
              <Image src={CliniSpanLogo} alt="Logo" />
            </div>
          </header> */}
      <PartnerHeader />
      <div className="App-body outerRow">
        <div className="container">
          <div className="flexColumn">
            <FormBuilder />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreScreenForm;
