import { MailOutlined } from "@mui/icons-material";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined"; // Import the icon for feed
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import CliniSpanLogo from "../assets/logo.png";

const Image = styled.img`
  height: 60px;
  width: 200px;
  margin: 10px;
`;

const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: "white",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const getInitialSelectedItem = () => {
    switch (location.pathname) {
      case "/admin/dashboard":
        return "Dashboard";
      case "/admin/volunteer-data":
        return "Volunteer Data";
      case "/admin/recruitment-partners":
        return "Recruitment Partners";
      case "/admin/current-studies":
        return "Current Studies";
      case "/admin/conditions":
        return "Conditions";
      case "/admin/add-users":
        return "Add Users";
      case "/admin/managecampaigns":
        return "Campaigns";
      case "/admin/influencer-portfolio":
        return "Influencer Portfolio";
      case "/admin/mail":
        return "Email users";
      case "/admin/billing":
        return "Billing";
      case "/admin/feed":
        return "TA Communities";
      default:
        return "Dashboard";
    }
  };

  const [selected, setSelected] = useState(getInitialSelectedItem());

  useEffect(() => {
    setSelected(getInitialSelectedItem());
  }, [location]);

  const MenuItems = props.menuItems;

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `#fff !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
          transition: "none !important", // Disable any transitions on the icon wrapper
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
          transition: "color 0.3s, background-color 0.3s", // Add transition for smooth hover effect
        },
        "& .pro-inner-item:hover": {
          color: "#000 !important",
          backgroundColor: "rgba(0, 158, 195, .05) !important",
        },
        "& .pro-menu-item.active": {
          color: "#009EC5 !important",
          backgroundColor: "rgba(0, 158, 195, .05) !important",
        },
        "& .pro-menu-item": {
          color: "#000 !important",
        },
        borderRight: 2,
        borderColor: "#e0e0e0",
        position: "sticky",
        top: 0,
        height: "100vh",
        overflow: "auto",
        paddingRight: "20px",
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu>
          {/* LOGO AND MENU ICON */}
          <Box display="flex" borderRadius="3px">
            <Image src={CliniSpanLogo} alt="Logo" />
          </Box>
          <MenuItem
            // onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? undefined : undefined}
            style={{
              color: "white",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // ml="15px"
              >
                {/* <Typography variant="h3" color={"white"}>
                  ADMIN
                </Typography> */}
                {/* <IconButton 
                onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  <MenuOutlinedIcon
                    style={{
                      color: "white",
                    }}
                  />
                </IconButton> */}
              </Box>
            )}
          </MenuItem>
          {/* 
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <AccountCircleIcon style={{ fontSize: 100, color: "#000" }} />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={"#000"}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {props.name}
                </Typography>
                <Typography variant="h5" color={"green"}>
                  {props.role}
                </Typography>
              </Box>
            </Box>
          )} */}

          <Box paddingLeft={isCollapsed ? undefined : "5%"}>
            <Typography
              variant="p"
              color={"#5C5E64"}
              sx={{
                m: "15px 0 5px 20px",
                fontSize: "12px",
                fontFamily: "Montserrat",
                fontWeight: "semibold",
              }}
            >
              {"Data".toUpperCase()}
            </Typography>
            <Item
              title="Dashboard"
              to="/admin/dashboard"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Volunteer Data"
              to={"/admin/volunteer-data"}
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Recruitment Partners"
              to={"/admin/recruitment-partners"}
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="p"
              color={"#5C5E64"}
              sx={{
                m: "15px 0 5px 20px",
                fontSize: "12px",
                fontFamily: "Montserrat",
                fontWeight: "semibold",
              }}
            >
              {"Manage".toUpperCase()}
            </Typography>
            <Item
              title={"Billing"}
              to={"/admin/billing"}
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={"Current Studies"}
              to={"/admin/current-studies"}
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={"Conditions"}
              to={"/admin/conditions"}
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={"Add Users"}
              to={"/admin/add-users"}
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={"Email users"}
              to={"/admin/mail"}
              icon={<MailOutlined />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={"Campaigns"}
              to={"/admin/managecampaigns"}
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={"Influencer Portfolio"}
              to={"/admin/influencer-portfolio"}
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={"TA Communities"}
              to={"/admin/feed"}
              icon={<FeedOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
