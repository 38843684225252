import { gql, useMutation, useQuery } from "@apollo/client";
import { Box, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PartnerHeader } from "../../components/Menus";

import { useRownd } from "@rownd/react";
import moment from "moment";

const STUDY_SEARCH_MUTATION = gql`
  mutation GetStudies($nctNumber: String!) {
    studySearch(nctNumber: $nctNumber) {
      title
      nctNumber
    }
  }
`;

const STUDY_QUERY = gql`
  query GetMyStudies {
    myStudies {
      title
      nctNumber
    }
  }
`;

const ADD_STUDY_MUTATION = gql`
  mutation AddStudy(
    $nctNumber: String!
    $name: String!
    $startDate: String!
    $endDate: String!
  ) {
    addStudy(
      nctNumber: $nctNumber
      startDate: $startDate
      endDate: $endDate
      name: $name
    ) {
      title
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 70px;
  width: 80%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  margin: 10px auto;
  align-self: center;
  width: 400px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   color: #fff;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Row = styled.div`
  display: flex;
  felx-diretion: row;
  justify-content: center;
`;

const items = [
  {
    name: "Asthma Trial",
    nctNumber: "12341231",
    participants: 10,
    active: true,
  },
  {
    name: "COVID Trial",
    nctNumber: "12341231",
    participants: 20,
    active: false,
  },
  {
    name: "Diabetes Trial",
    nctNumber: "12341231",
    participants: 30,
    active: true,
  },
];

function ListItem({ item }) {
  const navigate = useNavigate();

  return (
    <div style={{ border: "1px solid black", marginTop: 15, marginBottom: 15 }}>
      <h2>{item.title}</h2>
      <Button
        variant="info"
        style={{ marginLeft: 700 }}
        onClick={() => navigate(`/details/${item.number}`)}
      >
        More Info
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <p>NCT Number: {item.nctNumber}</p>
        <p>Participants: {item.participants}</p>
        <p>Active: {item.active ? "Yes" : "No"}</p>
      </div>
    </div>
  );
}

function ConfirmationModal(props) {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleDelete = () => {
    props.handleDelete();
    handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Addition</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this study?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleDelete}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function StudyDetailsModal(props) {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleSubmit = () => {
    props.handleSubmit();
    handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Provide Study Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box
            display="grid"
            gap="30px"
            // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {/* <TextField
              fullWidth
              variant="filled"
              type="text"
              label="First Name"
              // onBlur={handleBlur}
              // onChange={handleChange}
              value={"test"}
              name="firstName"
              // error={!!touched.firstName && !!errors.firstName}
              // helperText={touched.firstName && errors.firstName}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Last Name"
              // onBlur={handleBlur}
              // onChange={handleChange}
              value={"test2"}
              name="firstName"
              // error={!!touched.firstName && !!errors.firstName}
              // helperText={touched.firstName && errors.firstName}
              sx={{ gridColumn: "span 2" }}
            /> */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Study Name"
              // onBlur={handleBlur}
              onChange={(e) => props.setStudyName(e.target.value)}
              value={props.studyName}
              name="studyName"
              // error={!!touched.firstName && !!errors.firstName}
              // helperText={touched.firstName && errors.firstName}
              sx={{ gridColumn: "span 2" }}
            />
            <DateTimePicker
              label="Study Start Date"
              // defaultValue={moment(new Date())}
              onChange={(e) => props.setStartDate(e)}
              value={props.startDate}
            />
            <DateTimePicker
              label="Study End Date"
              // defaultValue={moment(new Date())}
              onChange={(e) => props.setEndDate(e)}
              value={props.endDate}
            />
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSubmit}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const PartnerStudyUpload = () => {
  const navigate = useNavigate();
  const [nctNumber, setNctNumber] = useState("");
  const [studyName, setStudyName] = useState("");
  const [searchResult, setSearchResult] = useState();
  const [searchedNctNumber, setSearchedNctNumber] = useState();
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [studies, setStudies] = useState([]);
  const [studySearch] = useMutation(STUDY_SEARCH_MUTATION, {
    variables: { nctNumber },
  });

  const [addStudy] = useMutation(ADD_STUDY_MUTATION, {
    variables: {
      nctNumber: searchedNctNumber,
      startDate,
      endDate,
      name: studyName,
    },
    refetchQueries: [{ query: STUDY_QUERY }],
  });

  const { loading, error, data } = useQuery(STUDY_QUERY);

  const { is_authenticated, user, requestSignIn } = useRownd();

  const isMobile = useMediaQuery("(max-width:600px)");

  const onSearchClick = async () => {
    setSearchedNctNumber(nctNumber);
    const searchData = await studySearch();
    setSearchResult(searchData?.data?.studySearch);
    setStudyName(searchData?.data?.studySearch?.title);
  };

  useEffect(() => {
    if (!loading) {
      setStudies(data?.studies);
    }
  }, [loading, data]);

  useEffect(() => {
    if (!nctNumber) {
      setSearchResult(null);
    }
  }, [nctNumber]);

  return (
    <div className="App" style={{ padding: isMobile ? "0 10px" : "0 50px" }}>
      <PartnerHeader />
      <div
        className="App-body outerRow"
        style={{
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "flex-start",
        }}
      >
        <div
          className="container"
          style={{
            width: isMobile ? "100%" : "50%",
            margin: isMobile ? "20px 0" : "0",
          }}
        >
          <div className="flexColumn">
            <ConfirmationModal
              handleDelete={() => addStudy().then(() => setSearchResult())}
              show={show}
              setShow={setShow}
            />
            <StudyDetailsModal
              handleSubmit={() => setShow(true)}
              show={showDetails}
              setShow={setShowDetails}
              setEndDate={setEndDate}
              endDate={endDate}
              setStartDate={setStartDate}
              startDate={startDate}
              studyName={studyName}
              setStudyName={setStudyName}
            />

            <Container className="centerSelf" background={"#fff"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignSelf: "center",
                }}
              >
                {/* <Text>NCT# :</Text> */}
                {/* <Input
                  type={"text"}
                  value={nctNumber}
                  onChange={(e) => {
                    setNctNumber(e.target.value);
                  }}
                /> */}
                <TextField
                  value={nctNumber}
                  onChange={(e) => {
                    setNctNumber(e.target.value);
                  }}
                  label="Search By NCT#"
                  style={{ width: isMobile ? "100%" : "500px" }}
                />
                <Button
                  title="Search"
                  onClick={onSearchClick}
                  style={{ width: 80 }}
                >
                  Search
                </Button>
              </div>
            </Container>
            <div
              style={{
                overflowY: "scroll",
                maxHeight: 500,
              }}
            >
              {!searchResult &&
                !loading &&
                studies &&
                studies.map((item) => (
                  <ListItem key={item.nctNumber} item={item} />
                ))}

              {searchResult && (
                <Card
                  title={searchResult.title}
                  style={{ marginTop: 15, marginBottom: 15 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <p>NCT Number: {searchResult.nctNumber}</p>
                    {/* <p>Participants: {item.participants}</p> */}
                    <p>Active: {"No"}</p>
                    <Button
                      variant="success"
                      className="centerSelf"
                      onClick={() => setShowDetails(true)}
                    >
                      Add Study
                    </Button>
                  </div>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerStudyUpload;
