import { gql, useMutation, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbar
} from "@mui/x-data-grid";
import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import { PartnerHeader } from "../../components/Menus";
import { useUserContext } from "../../providers/UserContext";

const STUDY_SEARCH_MUTATION = gql`
  mutation GetStudies($nctNumber: String!) {
    studySearch(nctNumber: $nctNumber) {
      title
    }
  }
`;

const STUDY_QUERY = gql`
  query GetStudy($id: String! $nctNumber: String) {
    study(id: $id  nctNumber: $nctNumber) {
      id
      active
      title
      nctNumber
       formId {
        id
        name
        fields {
          id
          label
          type
          required
          options
        }
      }
      description
      approvalStatus
      campaignIds
      influencers
      participants
    }
  }
`;

const ADD_STUDY_MUTATION = gql`
  mutation AddStudy($nctNumber: String!) {
    addStudy(nctNumber: $nctNumber) {
      title
    }
  }
`;

const FETCH_STUDY_MUTATION = gql`
  mutation FetchStudy($nctNumber: String!) {
    fetchStudy(nctNumber: $nctNumber) {
      id
      title
      nctNumber
      formId
      description
      approvalStatus
    }
  }
`;

const REQUEST_STUDY_ACTIVATION_MUTATION = gql`
  mutation RequestStudyActivation($studyId: String!) {
    requestStudyActivation(studyId: $studyId) {
      id
      title
      nctNumber
      formId
      description
    }
  }
`;

const FERCH_REFERRALS = gql`
  query FetchReferrals ($formId: String){
    getFormResponses(formId: $formId){
      id
      createdAt
      enrolled
      correctlyAnswered
      formId{
        id}
      userId{
      id: _id
      firstName
      email
      phoneNumber
      }
    }
  }
`

const ENROLL_USER = gql`
  mutation EnrollUser($userId: String!, $formId: String!) {
    enrollUser(userId: $userId, formId: $formId) {
      id
      formId {
        id}
      userId {
        id: _id
        firstName
        email
        phoneNumber
      }
      enrolled
    }
  }
`;

const FETCH_CAMPAIGN_STATS = gql`
  query FetchCampaignStats($campaignId: ID!) {
    fetchCampaignStats(campaignId: $campaignId) {
      influencerViews
      engagementRate
      engagements
      influencersCount
      communityReach
}
}
`;
      

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 60px;
  width: 80%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  margin: 10px auto;
  align-self: center;
  width: 400px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   color: #fff;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Row = styled.div`
  display: flex;
  felx-diretion: row;
  justify-content: center;
`;

const items = [
  {
    name: "Asthma Trial",
    nctNumber: "12341231",
    participants: 10,
    active: true,
  },
  {
    name: "COVID Trial",
    nctNumber: "12341231",
    participants: 20,
    active: false,
  },
  {
    name: "Diabetes Trial",
    nctNumber: "12341231",
    participants: 30,
    active: true,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const TempTable = ({ data }) => {
  const [enrollUser] = useMutation(ENROLL_USER, {
    refetchQueries: [{ query: FERCH_REFERRALS, variables: { formId: data[0]?.formId.id } }],
  });
  const columns = [
    { field: "id", headerName: "ID",
      valueGetter: (params) => {
        return params && params.row.userId.id;
      }
     },
    {
      field: "firstName",
      headerName: "Participant Name",
      flex: 1,
      cellClassName: "name-column--cell",
      valueGetter: (params) => {
        return params && `${params.row.userId.firstName || ''}`;
      },
    },
    {
      field: "createdAt",
      headerName: "Date Referred",
      flex: 1,
      valueGetter: (value) => {
        return value && new Date(Number(value.value)).toDateString();
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      valueGetter: (params) => {
        console.log('params', params)
        return params && params.row.userId.email;
      },
    },
    {
      field: "correctlyAnswered",
      headerName: "Qualified",
      flex: 1,
      valueGetter: (params) => {
        return params && params.row.correctlyAnswered ? "Yes" : "No";
      },
    },
    // {
    //   field: "phone",
    //   headerName: "Phone Number",
    //   flex: 1,
    // },
    // {
    //   field: "bestTime",
    //   headerName: "Best Time To Reach",
    //   flex: 1,
    // },
    {
      field: "enrolled",
      headerName: "Enrolled",
      flex: 1,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log("params", params);
          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) =>
                (thisRow[c.field] = params.api.getCellValue(params.id, c.field))
            );

            console.log('thisRow', thisRow, params)

          return enrollUser({
            variables: {
              userId: thisRow.id,
              formId: params.row.formId.id,
              },
          });
        };

        return <Button disabled={params.row.enrolled}  onClick={onClick}>{params.row.enrolled? "Enrolled" :"Enroll"}</Button>;
      },
    },
  ];

  console.log('data', data)

  return (
    <Box m="20px">
      <Box
        m="40px 0 0 0"
        height="75vh"
        xl={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          // slots={{ toolbar: CustomToolbar }}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F8F9FC",
            },
          }}
        />
      </Box>
    </Box>
  );
};

function ListItem({ item, openModal, displayWarning, user, referrals, analytics }) {
  const navigate = useNavigate();
  console.log("item", item, user?.organizationId.subscription.status);
  console.log("analytics", analytics);
  const disabledCondition = false;
    // (
    //   item?.approvalStatus === "Approved" ||
    //   item?.approvalStatus === "Pending") &&
    // user?.organizationId?.subscription.status === "active";

    const handleCopyClick = () => {
      const nctNumber = item?.nctNumber;
      //need to modify link for each platform
      const link = `${window.location.origin}/patient/browse-studies/study/${nctNumber}`;
      navigator.clipboard.writeText(link);
      alert("Link copied to clipboard");
    };
    
  return (
    <Card
    title={
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", flex: 1 }}>
        <div style={{ flexGrow: 1, flexShrink: 1 }}>
          <b>{item?.title}</b>
        </div>
        <div style={{ flexGrow: 0, flexShrink: 0 }}>
          <p>
            <b>{item?.nctNumber}</b>
          </p>
        </div>
      </div>
    }
      style={{
        marginTop: 15,
        marginBottom: 15,
        textAlign: "left",
        borderRadius: 10,
      }}
      bordered
      className="custom-card"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: 25,
          }}
        >
          {/* <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Participants</p>
            <p>
              <b>{item?.participants ? item.participants : 0}</b>
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Influencers</p>
            <p>
              <b>{item?.influencers ? item.influencers : 0}</b>
            </p>
          </div> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Influencers</p>
            <p>
              <b>{analytics?.fetchCampaignStats.influencersCount}</b>
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Community Reach</p>
            <p>
            <b>{analytics?.fetchCampaignStats.communityReach}</b>
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Engagements</p>
            <p>
            <b>{analytics?.fetchCampaignStats.engagements}</b>
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Influencer Views</p>
            <p>
            <b>{analytics?.fetchCampaignStats.influencerViews}</b>
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Engagement Rate</p>
            <p>
            <b>{analytics?.fetchCampaignStats.engagementRate}%</b>
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>Referrals</p>
            <p>
              <b>{referrals.length}</b>
            </p>
          </div>
         
        </div>
        <p
          style={{ color: item?.active ? "red" : "green" }}
          onClick={() => null}
        ></p>
        {/* <Button
          variant="secondary"
          type="submit"
          style={{
            width: 200,
            alignSelf: "flex-end",
            marginBottom: 20,
            backgroundColor: item.active ? "red" : "green",
          }}
          disabled={disabledCondition}
          onClick={() => displayWarning()}
        >
          {item.active ? "Deactivate" : "Activate"}
        </Button> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: 10,
          }}
        >
          {/* <Box
            variant="secondary"
            type="submit"
            style={{
              backgroundColor: "rgba(0,158,197, .1)",
              width: 180,
              height: 40,
              borderRadius: 10,
              textAlign: "center",
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: "flex-end",
              display: "flex",
              flexDirection: "row", 
            }}
            disabled={true}
          >
            <p style={{ color: "#000", alignSelf: "center", marginBottom: 0, fontWeight: 'medium' }}>
            {"Engagements Left:"}
            </p>
            <b>
            <p style={{ color: "#009EC5", alignSelf: "center", marginBottom: 0, marginLeft: 5 }}>
              9
            </p>
            </b>
          </Box> */}
        {  item?.formId?.id &&  <Button
            variant="secondary"
            type="submit"
            style={{
              width: 140,
              alignSelf: "flex-end",
              backgroundColor: "#009EC5",
              borderColor: "#009EC5",
            }}
            // disabled={disabledCondition}
            onClick={() => handleCopyClick()}
          >
            {"Copy Form Link"}
          </Button>}
          <Button
            type="submit"
            disabled={disabledCondition}
            onClick={() => {
              if (item?.formId) {
                navigate(`/partner/studies/formbuilder/${item.id}`, {
                  state: {
                    formId: item?.formId?.id, //TODO: pass full form to reduce fetches
                    readOnly: item.approvalStatus === "Approved",
                    active: item.active,
                  },
                });
              } else {
                navigate(`/partner/studies/formbuilder/${item.id}`, {
                  state: {
                    formId: null,
                  },
                });
              }
            }}
            //   onClick={() => navigate(`/partner/studies/formbuilder/${item.id}`)}
            style={{
              width: !item?.formId?.id ? 180 : 140,
              alignSelf: "flex-end",
              borderColor: "#009EC5",
              backgroundColor: "#fff",
              color: "#009EC5",
            }}
          >
            {item?.formId?.id ? "View/Edit Form" : "Add PreScreen Form"}
          </Button>
          <Button
            // variant="primary"
            type="submit"
            disabled={disabledCondition}
            // onClick={() => openModal(item)}
            onClick={() =>
              navigate(`/partner/campaign-configuration/${item.campaignIds[0]}`)
            }
            style={{
              width: 180,
              alignSelf: "flex-end",
              color: "#fff",
              backgroundColor: "#009EC5",
              borderColor: "#009EC5",
            }}
          >
            Configure Campaign
          </Button>
        </div>
      </div>
    </Card>
  );
}

function WarningModal(props) {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  return (
    <>
      <Modal show={props.show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center", alignSelf: "center" }}>
            Request Study Activation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: 20 }}>
          Are you sure you want to activate this study? Once activated the study
          will be visible to the public. It will no longer be editable.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={props.handleActivate}>
            Request Activation
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ConfirmationModal(props) {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);
  const navigate = useNavigate();
  const onConfirm = () => {
    navigate(`/partner/studies/formbuilder/${props.item.id}`);
  };

  const handleDelete = () => {
    props.handleDelete();
    handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center", alignSelf: "center" }}>
            Add PreScreen Assessment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingBottom: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid black",
                borderRadius: 10,
                padding: 5,
                width: "45%",
              }}
            >
              <p>
                Use the form builder to create a prescreen form for this study
              </p>
              <Button
                variant="secondary"
                type="submit"
                onClick={() =>
                  navigate(`/partner/studies/formbuilder/${props.item.id}`, {
                    state: {
                      formId: null,
                    },
                  })
                }
                style={{ width: 200, alignSelf: "center" }}
              >
                Navigate to form builder
              </Button>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

const StudyDetails = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { id } = useParams();
  const location = useLocation();
  const state = location.state;

  console.log("param id", id);
  const [nctNumber, setNctNumber] = useState(id);
  const [searchResult, setSearchResult] = useState();
  const [searchedNctNumber, setSearchedNctNumber] = useState();
  const [show, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [item, setItem] = useState();
  const [studies, setStudies] = useState([]);
  const [referrals, setReferrals ] = useState([])
  const [studySearch] = useMutation(STUDY_SEARCH_MUTATION, {
    variables: { nctNumber },
  });
  const [fetchStudy] = useMutation(FETCH_STUDY_MUTATION, {
    variables: { nctNumber },
  });

  const [requestStudyActivation] = useMutation(
    REQUEST_STUDY_ACTIVATION_MUTATION,
    {
      variables: { studyId: id },
      refetchQueries: [{ query: STUDY_QUERY }],
    }
  );

  const [addStudy] = useMutation(ADD_STUDY_MUTATION, {
    variables: { nctNumber: searchedNctNumber },
    refetchQueries: [{ query: STUDY_QUERY }],
  });

  const { loading, error, data, refetch } = useQuery(STUDY_QUERY, {
  skip: !user,
    variables: {
      id: id,
      nctNumber: state?.nctNumber,
    }
  });

  const { loading: formResponseLoading, error: formResponseError, data: formResponseData } = useQuery(FERCH_REFERRALS, {
    skip: !user || !studies[0]?.formId?.id,
    variables: {
      formId: studies[0]?.formId?.id || '',
    },
  });



const { data: analyticsData, loading: analyticsLoading, error: analyticsError } = useQuery(FETCH_CAMPAIGN_STATS, {
  variables: { campaignId: studies[0]?.campaignIds[0] },
  skip: !user || !studies[0]?.campaignIds[0] 
});


  const onSearchClick = async () => {
    setSearchedNctNumber(nctNumber);
    const searchData = await studySearch();
    setSearchResult(searchData?.data?.studySearch);
  };

console.log("studies", studies, data?.study, state, id);
console.log("loaded form data", data?.study?.formId, studies);
console.log('formResponseData', formResponseData, studies[0]?.formId?.id )

  useEffect(() => {
    if (!loading && data) {
      setStudies([data?.study]);
    }
  }, [loading, data]);

  useEffect(() => {
    if (state && !data) {
      setStudies([state]);
    }
  }, [state, data]);
  
  useEffect(() => {
    if (formResponseData && !formResponseLoading) {
      setReferrals(formResponseData.getFormResponses);
    }
  }, [formResponseLoading, formResponseData]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch]);


  // useEffect(() => {
  //   if (id) {
  //     fetchStudy().then((res) => {
  //       setStudies([res.data.fetchStudy]);
  //     });
  //   }
  // }, [id]);

  const tempdata = [];

  const requestActivation = () => {
    requestStudyActivation();
    setShowWarning(false);
    toast.success("Study activation requested");
  };

  return (
    <div className="App">
      <PartnerHeader />
      <ToastContainer />

      <div className="App-body outerRow">
        <div className="container">
          <div className="flexColumn">
            <WarningModal
              handleActivate={requestActivation}
              show={showWarning}
              setShow={setShowWarning}
            />
            <ConfirmationModal
              //   handleDelete={() => addStudy().then(() => setSearchResult())}
              show={show}
              setShow={setShow}
              item={item}
            />

            <div style={{}}>
              {!searchResult &&
                !loading &&
                studies &&
                studies.map((item, index) => (
                  <ListItem
                    key={item?.nctNumber}
                    item={item}
                    displayWarning={() => setShowWarning(true)}
                    openModal={(item) => {
                      setShow(true);
                      setItem(item);
                    }}
                    user={user}
                    referrals={referrals}
                    analytics={analyticsData}
                  />
                ))}

              {/* <Button
                variant="secondary"
                type="submit"
                style={{ width: 200, alignSelf: "flex-end", marginBottom: 20 }}
              >
                <CSVLink
                  data={tempdata}
                  filename={"my-file.csv"}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Export CSV
                </CSVLink>
              </Button> */}
              {/* <ExampleTable data={tempdata} /> */}
              <TempTable data={referrals} />

              {/* <FormBuilder /> */}

              {searchResult && (
                <div
                  style={{
                    border: "1px solid black",
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  <h2>{searchResult.title}</h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <p>NCT Number: {nctNumber}</p>
                    {/* <p>Participants: {item.participants}</p> */}
                    <p>Active: {"No"}</p>
                    <Button
                      variant="success"
                      className="centerSelf"
                      onClick={() => setShow(true)}
                    >
                      Add Study
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyDetails;
