import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};
export const formatCurrencyNoDollar = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })
    .format(amount)
    .replace("$", "");
};
export const formatNumber = (amount) => {
  return new Intl.NumberFormat("en-US").format(amount);
};
export const formatNumberNoComma = (amount) => {
  return new Intl.NumberFormat("en-US").format(amount).replace(",", "");
};

export const formatNumberNoDecimal = (amount) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  }).format(amount);
};

export const formatNumberNoDecimalNoComma = (amount) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  })
    .format(amount)
    .replace(",", "");
};

export const formatNumberNoDecimalNoCommaNoDollar = (amount) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  })
    .format(amount)
    .replace(",", "")
    .replace("$", "");
};

export const formatNumberNoDecimalNoCommaNoDollarNoPercent = (amount) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  })
    .format(amount)
    .replace(",", "")
    .replace("$", "")
    .replace("%", "");
};

export const formatPercent = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(amount);
};

export const formatPercentNoDecimal = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 0,
  }).format(amount);
};

export const formatPercentNoDecimalNoPercent = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 0,
  })
    .format(amount)
    .replace("%", "");
};

export const formatPercentNoDecimalNoPercentNoComma = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 0,
  })
    .format(amount)
    .replace("%", "")
    .replace(",", "");
};

export const formatPercentNoDecimalNoPercentNoCommaNoDollar = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 0,
  })
    .format(amount)
    .replace("%", "")
    .replace(",", "")
    .replace("$", "");
};

export const formatLargeNumber = (number) => {
  if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + "B";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + "M";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1) + "K";
  } else {
    return number.toString();
  }
};

export const formatTimestamp = (timestamp) => {
  const now = new Date();

  const postDate = new Date(Number(timestamp));
  const diffInSeconds = Math.floor((now - postDate) / 1000);

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "week", seconds: 604800 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds);

    if (count >= 1) {
      return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
};

export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
}

export const htmlFrom = (htmlString) => {
  const cleanHtmlString = DOMPurify.sanitize(htmlString,
    { USE_PROFILES: { html: true } });
  const html = parse(cleanHtmlString);
  return html;
}
