import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useRownd } from "@rownd/react";
import React, { useCallback, useMemo } from "react";

const graphqlEndpoint =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_APOLO_GRAPHQL_ENDPOINT
    : "/graphql";

const CliniSpanApolloProvider = ({ children }) => {
  const { getAccessToken } = useRownd();

  const makeApolloClient = useCallback(() => {
    const httpLink = createHttpLink({
      uri: graphqlEndpoint,
    });

    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${await getAccessToken()}`,
        },
      };
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
  }, [getAccessToken]);

  const client = useMemo(() => {
    return makeApolloClient();
  }, [makeApolloClient]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default CliniSpanApolloProvider;

// const httpLink = createHttpLink({
//   uri: graphqlEndpoint,
// });

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: window.rownd?.auth.token() ? `Bearer ${window.rownd?.auth.token()}` : "",
//     },
//   };
// });

// export const client = new ApolloClient({
//   link: authLink.concat(httpLink),
//   cache: new InMemoryCache({
//     addTypename: false,
//   }),
// });

// export default <ApolloProvider client={client} />;
