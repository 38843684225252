import { Button, Container, Paper, Typography } from "@mui/material";
import AWS from "aws-sdk";
import React from "react";

// Configure AWS
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const cloudwatchlogs = new AWS.CloudWatchLogs({ apiVersion: "2014-03-28" });

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error("ErrorBoundary error:", error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    this.setState({ error: error, errorInfo: errorInfo });
    console.error("Uncaught error:", error, errorInfo);

    if (process.env.NODE_ENV === "production") {
      const params = {
        logGroupName: "csh-test-application-errors",
        logStreamName: "errors",
        logEvents: [
          {
            timestamp: new Date().getTime(),
            message: JSON.stringify({
              error: error.toString(),
              errorInfo: errorInfo.componentStack,
            }),
          },
        ],
      };

      // Send logs to CloudWatch
      cloudwatchlogs.putLogEvents(params, function (err, data) {
        if (err) console.log("Logging failed:", err);
        else console.log("Error logged to CloudWatch:", data);
      });
    }
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
    // Optionally, you can add retry logic or state reset logic here
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // Custom fallback UI
      return (
        <Container component="main" maxWidth="sm">
          <Paper
            elevation={3}
            style={{ padding: "20px", marginTop: "20px", textAlign: "center" }}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              Oops, something went wrong.
            </Typography>
            <Typography variant="body1">
              Please report this issue via our HelpScout Beacon so we can
              investigate and fix it as soon as possible. Thank you for your
              help!
            </Typography>
            {/* TODO: Add an image or svg */}
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleRetry}
              style={{ marginTop: "20px" }}
            >
              Try Again
            </Button>
          </Paper>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
