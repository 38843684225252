import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

// GraphQL queries and mutations
const GET_USERS_QUERY = gql`
  query GetUsers {
    users {
      id: _id
      firstName
      lastName
      email
      role
    }
  }
`;

const SEND_BULK_EMAIL_MUTATION = gql`
  mutation SendBulkEmail($userIds: [ID!]!, $subject: String!, $body: String!) {
    sendBulkEmail(userIds: $userIds, subject: $subject, body: $body) {
      success
      message
    }
  }
`;

// BulkEmailPage component
const BulkEmailPage = () => {
  const { loading, data } = useQuery(GET_USERS_QUERY);
  const [sendBulkEmail, { loading: sendingEmail }] = useMutation(
    SEND_BULK_EMAIL_MUTATION,
    {
      onCompleted: (data) => {
        setSelectedUsers([]);
        if (data.sendBulkEmail.success) {
          toast.success(data.sendBulkEmail.message);
        } else {
          toast.error(data.sendBulkEmail.message);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const handleRoleSelection = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleUserSelection = (userId) => {
    setSelectedUsers(
      selectedUsers.includes(userId)
        ? selectedUsers.filter((id) => id !== userId)
        : [...selectedUsers, userId]
    );
  };

  const handleSendEmail = () => {
    sendBulkEmail({
      variables: {
        userIds: selectedUsers,
        subject: emailSubject,
        body: emailBody,
      },
    });
  };

  if (loading) return <CircularProgress />;

  const test = Object.values(
    data?.users.reduce((groups, user) => {
      user.role.forEach((role) => {
        const roleStr = String(role);
        if (!groups[roleStr]) {
          groups[roleStr] = [];
        }
        groups[roleStr].push(user);
      });

      return groups;
    }, {})
  );

  console.log("testing organization", test[0]);

  return (
    <div style={{ padding: 30 }}>
      <ToastContainer />
      <Autocomplete
        multiple
        id="tags-outlined"
        options={Object.entries(
          data?.users
            .flatMap((user) =>
              user.role.map((role) => ({
                ...user,
                group: role,
              }))
            )
            .reduce((groups, user) => {
              if (!groups[user.group]) {
                groups[user.group] = [
                  {
                    firstName: "All ",
                    lastName: user.group + "s",
                    group: user.group,
                  },
                ];
              }
              groups[user.group].push(user);
              return groups;
            }, {})
        )
          .flatMap(([group, users]) => users)
          .sort((a, b) => {
            const groupComparison = a.group.localeCompare(b.group);
            if (groupComparison !== 0) return groupComparison;
            if (a?.firstName && a?.firstName.startsWith("All")) return -1;
            if (b?.firstName && b?.firstName.startsWith("All")) return 1;
            return 0;
          })}
        groupBy={(option) => option.group}
        getOptionLabel={(option) =>
          `${option.firstName} ${option.lastName} (${option.group})`
        }
        filterOptions={(options, params) => {
          const filtered = params.inputValue
            ? options.filter((option) =>
                `${option.firstName} (${option.group})`
                  .toLowerCase()
                  .includes(params.inputValue.toLowerCase())
              )
            : options;

          return filtered;
        }}
        onChange={(event, value) =>
          setSelectedUsers(value.map((user) => user.id))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Users"
            placeholder="Filter by name or role"
          />
        )}
      />
      {/* <FormControl
        variant="outlined"
        style={{ minWidth: 200, marginRight: 20 }}
      >
        <InputLabel id="role-select-label">Role</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          value={selectedRole}
          onChange={handleRoleSelection}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Admin">Admin</MenuItem>
          <MenuItem value="User">User</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ minWidth: 200 }}>
        <InputLabel id="demo-mutiple-chip-label">Users</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={selectedUsers}
          onChange={handleUserSelection}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    data?.users.find((user) => user.id === value)?.firstName
                  }
                />
              ))}
            </div>
          )}
        >
          {data?.users
            .filter((user) => !selectedRole || user.role === selectedRole)
            .map((user) => (
              <MenuItem key={user?.id} value={user?.id}>
                {`${user?.firstName} (${user?.role})`}
              </MenuItem>
            ))}
        </Select>
      </FormControl> */}
      <TextField
        label="Subject"
        fullWidth
        margin="normal"
        value={emailSubject}
        onChange={(e) => setEmailSubject(e.target.value)}
      />
      <TextField
        label="Body"
        fullWidth
        multiline
        rows={4}
        margin="normal"
        value={emailBody}
        onChange={(e) => setEmailBody(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSendEmail}
        disabled={sendingEmail || selectedUsers.length === 0}
      >
        {sendingEmail ? "Sending..." : "Send Emails"}
      </Button>
    </div>
  );
};

export default BulkEmailPage;
