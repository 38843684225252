// FormRenderer.js

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Card } from "antd";
import { Formik } from "formik";
import React from "react";
import * as yup from "yup";

const FormRenderer = ({
  fields,
  readOnly,
  bordered = false,
  setFormValues,
  formValues,
  handleFormSubmit,
}) => {
  // Fields to add:
  // 1. Number
  // 2. Date
  // 3. Time
  // 4. File Upload
  // 5. Image Upload
  // 6. Password
  // 7. Email
  // 8. Phone Number
  // 9. Address
  // 10. Country
  // 11. State
  // 12. City
  // 13. Zip
  // 14. Currency
  // 15. Select
  // 16. Radio
  // 17. Checkbox
  // 18. Textarea
  // 19. Text
  // 20. Range
  // 21. Color
  // 22. Switch
  // 23. Slider
  // 24. Autocomplete
  // 25. Rating
  // 26. Date Range
  // 27. Time Range
  // 28. Date Time Range
  // 29. Date Time
  // 30. Date Time Local
  // 31. Date Time Picker
  // 32. Date Picker
  // 33. Time Picker

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip: yup.string().required("Zip is required"),
    country: yup.string().required("Country is required"),
    phone: yup.string().required("Phone is required"),
    cardName: yup.string().required("Name on card is required"),
    cardNumber: yup.string().required("Card number is required"),
    expiry: yup.string().required("Expiry date is required"),
    cvv: yup.string().required("CVV is required"),
  });

  const _phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    cardName: "",
    cardNumber: "",
    expiry: "",
    cvv: "",
  };

  const onFieldChange = (field, value, index) => {
    const temp = { ...field };
    temp.value = value.currentTarget.value;
    setFormValues({ ...formValues, [index]: temp });
  };

  return (
    <Card style={{ marginBottom: 10 }} bordered={bordered}>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        <form>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            {fields.map((field, index) => (
              <div key={index}>
                {field.type === "text" ? (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {field.label}
                    </FormLabel>
                    <TextField
                      required={field.required}
                      id="outlined-required"
                      label={field.required ? "Required" : ""}
                      disabled={readOnly}
                      onChange={(val) => {
                        onFieldChange(field, val, index);
                      }}

                      // defaultValue="Hello World"
                    />
                  </FormControl>
                ) : field.type === "textarea" ? (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {field.label}
                    </FormLabel>
                    <TextField
                      required={field.required}
                      id="outlined-multiline-static"
                      label={field.required ? "Required" : ""}
                      multiline
                      rows={4}
                      onChange={(val) => {
                        onFieldChange(field, val, index);
                      }}
                      // defaultValue={}
                    />
                  </FormControl>
                ) : field.type === "select" ? (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {field.label}
                    </FormLabel>
                    <TextField
                      required={field.required}
                      id="outlined-required"
                      label={field.label}
                      select
                      onChange={(val) => {
                        onFieldChange(field, val, index);
                      }}

                      // defaultValue="Hello World"
                    >
                      {field.options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                ) : field.type === "radio" ? (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {field.label}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      required={field.required}
                      onChange={(val) => {
                        onFieldChange(field, val, index);
                      }}
                    >
                      {field.options.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                ) : field.type === "checkbox" ? (
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel id="demo-radio-buttons-group-label">
                      {field.label}
                    </FormLabel>
                    <FormGroup>
                      {field.options.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              value={option.value}
                              onChange={(val) => {
                                onFieldChange(field, val, index);
                              }}
                            />
                          }
                          label={option.label}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                ) : null}
              </div>
            ))}
          </Box>
        </form>
      </Formik>
    </Card>
  );
};

export default FormRenderer;
