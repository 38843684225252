import React from "react";
import { Roles } from "../constants/user";
import AdminPortalContainer from "./AdminContainer";
import DHIContainer from "./DHIContainer";
import PartnerContainer from "./PartnerContainer";
import UserPortalContainer from "./UserContainer";

const GeneralContainer = ({ userRole, portal, children }) => {
  return (
    <div>
      {userRole && userRole.includes(Roles.Admin) && portal === "admin" ? (
        <AdminPortalContainer>{children}</AdminPortalContainer>
      ) : userRole &&
        userRole.includes(Roles.Partner) &&
        portal === "partner" ? (
        <PartnerContainer>{children}</PartnerContainer>
      ) : userRole && userRole.includes(Roles.Dhi) && portal === "dhi" ? (
        <DHIContainer>{children}</DHIContainer>
      ) : (
        <UserPortalContainer>{children}</UserPortalContainer>
      )}
    </div>
  );
};

export default GeneralContainer;
