import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Card } from "antd";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const GET_USERS = gql`
  query GetUsers {
    users {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

const ADD_USER = gql`
  mutation AddUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: Role!
    $organizationName: String
  ) {
    addUser(
      userInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: $role
        organizationName: $organizationName
      }
    ) {
      _id
      firstName
      lastName
      email
      role
    }
  }
`;

const SEND_INVITATION = gql`
  mutation SendInvitationMutation($email: String!) {
    sendGroupInvitation(email: $email)
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  width: 80%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 10px;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  margin: 10px auto;
  align-self: center;
  width: 400px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   color: #fff;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Row = styled.div`
  display: flex;
  felx-diretion: row;
  justify-content: center;
`;

function AddUsers() {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [type, setType] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [sendInvitationMutation] = useMutation(SEND_INVITATION, {
    variables: {
      email: email,
    },
  });
  // const { loading, error, data } = useQuery(GET_USERS);

  const [addUser, { loading: mutationLoading, error: mutationError }] =
    useMutation(ADD_USER, {
      variables: { firstName, lastName, email, role, organizationName },
    });

  // const fetchUsers = useCallback(async () => {
  //     setLoading(true);
  //     setError(null);
  //     try {
  //         const response = await fetch("http://localhost:5000/api/users");
  //         if (!response.ok) {
  //             throw new Error("Something went wrong!");
  //         }
  //         const data = await response.json();
  //         const transformedUsers = data.users.map((user) => ({
  //             id: user.id,
  //             firstName: user.firstName,
  //             lastName: user.lastName,
  //             email: user.email,
  //             role: user.role,
  //         }));
  //         setUsers(transformedUsers);
  //     } catch (error) {
  //         setError(error.message);
  //     }
  //     setLoading(false);
  // }, []);

  // useEffect(() => {
  //     fetchUsers();
  // }, [fetchUsers]);

  const onAddUser = async () => {
    const res = await addUser();

    console.log("res", res);

    if (mutationError) {
      console.error("Error adding user", mutationError);
      toast.error("Error adding user");
    } else {
      toast.success("User added successfully");
    }
  };

  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <ToastContainer />
      <main className="content">
        <Topbar />
        <Box m="20px">
          <div className="App-body outerRow">
            <div className="container">
              <div className="flexColumn">
                <Text className="centerSelf">Add Users</Text>
                <Card>
                  {/* <Container className="centerSelf"> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignSelf: "center",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={role}
                      label="Role"
                      margin="normal"
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                      <MenuItem value={"Partner"}>Partner</MenuItem>
                    </Select>

                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      value={firstName}
                      margin="normal"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      margin="normal"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      margin="normal"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {role && role === "Partner" && (
                      <TextField
                        id="outlined-basic"
                        label="Organization Name"
                        variant="outlined"
                        margin="normal"
                        value={organizationName}
                        onChange={(e) => setOrganizationName(e.target.value)}
                      />
                    )}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10, alignSelf: "center" }}
                    onClick={() => {
                      // sendInvitationMutation();
                      onAddUser();
                      // navigate("/admin/users");
                    }}
                  >
                    Add User
                  </Button>
                  {/* </Container> */}
                </Card>
              </div>
            </div>
          </div>
        </Box>
      </main>
    </div>
  );
}

export default AddUsers;
