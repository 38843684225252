import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CliniSpanLogo from "../assets/logo.png";

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: grey;
  width: 500px;
  margin-top: 50px;
`;

const Body = styled.div`
  background-image: url("https://media.istockphoto.com/id/614211848/photo/united-through-their-diversity.jpg?s=612x612&w=0&k=20&c=RZtAIdEyA71ji9dse0W0CmChr6siM9MrPmWane2UZII=");
  background-repeat: no-repeat;
  background-size: cover;
`;

const Banner = styled.div`
  background-color: white;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  display: block;
  margin: 10px auto;
  align-self: center;
  width: 200px;
  height: 30px;
`;
const Button = styled.button`
  background-color: blue;
  border-radius: 10px;
  height: 50px;
  width: 200px;
  margin: 25px 0px;
`;
const Text = styled.p``;

const Link = styled.p`
  cursor: pointer;
  text-decoration: underline;
`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "DHI Forgot Password";
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {/* <h1>DHI/CHI Portal</h1> */}
        <Image src={CliniSpanLogo} alt="Logo" />
        {/* <Image src={require("../assets/logo.png")} /> */}
      </header>
      <Body className="App-body">
        <Banner>
          <Text style={{ color: "lightblue", margin: "0px auto" }}>
            Digital Health Influencer Password Reset
          </Text>
        </Banner>
        <Container>
          {/* <Text> Glad to have you back!</Text> */}
          <InnerContainer>
            <label>Email</label>
            <Input />
            {/* <label>Password</label>
            <Input /> */}
            <Button onClick={() => navigate("/dashboard")}>
              Send Reset Email
            </Button>
            {/* <div>
              <Link onClick={() => navigate("/forgotpassword")}>
                Forgot Password? <a> Click Here</a>
              </Link>
              <Link onClick={() => navigate("/signup")}> Signup</Link>
            </div> */}
          </InnerContainer>
        </Container>
      </Body>
    </div>
  );
};

export default ForgotPassword;
