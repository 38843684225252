import { Box, Typography } from "@mui/material";
import React from "react";

const TextHeader = ({ title, subtitle }) => {
  return (
    <Box mb="30px">
      <Typography
        variant="h2"
        color={"#000"}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={"#C3C5A2"}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default TextHeader;
