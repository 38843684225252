import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import exportCSV from "../../utilities/exportCsv";
import { gql, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import TextHeader from "../../components/TextHeader";
import Topbar from "../../components/Topbar";
import { useUserContext } from "../../providers/UserContext";

const PATIENTS_QUERY = gql`
  query GetPatients {
    getPatients {
      data {
        id: _id
        firstName
        lastName
        zipCode
        email
        city
        phoneNumber
        dateOfBirth
        createdAt
        gender
        raceEthnicity
        medications
      }
    }
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Text = styled.p`
  color: #000;
  text-align: left;
`;

const data = [
  {
    id: 1,
    name: "John Doe",
    email: "john.doe@example.com",
    city: "New York",
    phoneNumber: "555-1234",
    referred: "Jane Doe",
    birthday: "01/01/1990",
    medications: "None",
    gender: "Male",
    ethnicity: "Caucasian",
    memberSince: "01/01/2022",
  },
  {
    id: 2,
    name: "Jane Doe",
    email: "jane.doe@example.com",
    city: "Los Angeles",
    phoneNumber: "555-5678",
    referred: "John Doe",
    birthday: "02/02/1995",
    medications: "Aspirin",
    gender: "Female",
    ethnicity: "Asian",
    memberSince: "02/02/2021",
  },
];

const TempTable = ({ data }) => {
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "referred",
      headerName: "Referred Self to Study",
      flex: 1,
    },
    {
      field: "dateOfBirth",
      headerName: "Birthday",
      flex: 1,
    },
    {
      field: "medications",
      headerName: "Medications",
      flex: 1,
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
    },
    {
      field: "raceEthnicity",
      headerName: "Race/Ethnicity",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      // renderCell: ({ row: { access } }) => {
      //   return (
      //     <Box
      //       width="60%"
      //       m="0 auto"
      //       p="5px"
      //       display="flex"
      //       justifyContent="center"
      //       // backgroundColor={
      //       //   access === "admin"
      //       //     ? colors.greenAccent[600]
      //       //     : access === "manager"
      //       //     ? colors.greenAccent[700]
      //       //     : colors.greenAccent[700]
      //       // }
      //       borderRadius="4px"
      //     >
      //       {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
      //       {access === "manager" && <SecurityOutlinedIcon />}
      //       {access === "user" && <LockOpenOutlinedIcon />}
      //       <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
      //         {access}
      //       </Typography>
      //     </Box>
      //   );
      // },
    },
  ];

  return (
    <Box m="20px">
      <Box
        m="40px 0 0 0"
        height="75vh"
        xl={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          // "& .name-column--cell": {
          //   color: colors.greenAccent[300],
          // },
          // "& .MuiDataGrid-columnHeaders": {
          //   backgroundColor: colors.blueAccent[700],
          //   borderBottom: "none",
          // },
          // "& .MuiDataGrid-virtualScroller": {
          //   backgroundColor: colors.primary[400],
          // },
          // "& .MuiDataGrid-footerContainer": {
          //   borderTop: "none",
          //   backgroundColor: colors.blueAccent[700],
          // },
          // "& .MuiCheckbox-root": {
          //   color: `${colors.greenAccent[200]} !important`,
          // },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

const VolunteerData = () => {
  const [location, setLocation] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const [activeBar, setActiveBar] = useState(null);
  const [patients, setPatients] = useState(null);
  const { loading, error, data: patientSignupData } = useQuery(PATIENTS_QUERY);
  const { user } = useUserContext();

  useEffect(() => {
    document.title = "Admin Dashboard";
    if (!loading && patientSignupData) {
      setPatients(patientSignupData.getPatients.data);
    }
  }, [loading]);

  const handleBarMouseOver = (data, index) => {
    setActiveBar(data);
  };

  const handleBarMouseLeave = () => {
    setActiveBar(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // TODO: handle search query using the state values
  };

  function handleExportClick() {
    // exportCSV(data, "myData.csv");
  }

  return (
    <div className="app">
      <Sidebar
        isSidebar={true}
        name={user?.firstName ? user.firstName : user?.email}
        role={user?.role}
      />
      <main className="content">
        <Topbar />
        <Box m="20px">
          <TextHeader title="Volunteer Data" />
          <Button onClick={handleExportClick} variant="primary">
            Deactivate
          </Button>
          <TempTable data={patients || data} />
        </Box>
      </main>
    </div>
  );
};

export default VolunteerData;
