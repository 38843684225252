import { gql, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {
  Autocomplete,
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";

import { Card } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { usePlacesWidget } from "react-google-autocomplete";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import { useUserContext } from "../../providers/UserContext";

const GET_USER = gql`
  query getUser {
    getMyProfile {
      _id
      firstName
      email
    }
  }
`;

const AUTH_URL_QUERY = gql`
  query GetAuthUrl {
    InstagramAuthUrl
  }
`;

const CONVERT_TOKEN_MUTATION = gql`
  mutation ConvertToken($shortLivedToken: String!) {
    convertToken(shortLivedToken: $shortLivedToken) {
      longLivedToken
    }
  }
`;

const CONVERT_TOKEN_IG_MUTATION = gql`
  mutation ConvertTokenIG($shortLivedToken: String!) {
    convertTokenIG(shortLivedToken: $shortLivedToken) {
      longLivedToken
    }
  }
`;

const GET_INSTAGRAM_PROFILE = gql`
  query GetInstagramProfile {
    fetchInstagramProfileInfo {
      username
    }
  }
`;

//need to only fetch active conditions
const GET_CONDITIONS = gql`
  query GetConditions {
    conditions {
      id
      name
      active
    }
  }
`;

const GET_LOGIN_URL = gql`
  query GetLoginUrl {
    loginUrl
  }
`;

const GET_ACCESS_TOKEN = gql`
  mutation GetAccessToken($code: String!) {
    getAccessToken(code: $code)
  }
`;

const AUTHENTICATE_TWITTER = gql`
  mutation AuthenticateTwitter {
    authenticateTwitter
  }
`;

const UPDATE_USER = gql`
  mutation updateProfile(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String
    $zipCode: String
    $interestedConditions: [ConditionInput]
    $avatar: String
    $accountType: String
    $sex: String
    $dob: String
    $raceEthnicity: [String]
    $city: String
  ) {
    updateProfile(
      userInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phoneNumber: $phoneNumber
        zipCode: $zipCode
        interestedConditions: $interestedConditions
        avatar: $avatar
        dhiAccountType: $accountType
        gender: $sex
        dateOfBirth: $dob
        raceEthnicity: $raceEthnicity
        city: $city
      }
    ) {
      firstName
      lastName
      email
      phoneNumber
      zipCode
      city
      interestedConditions {
        id
        name
        active
      }
    }
  }
`;

const POST_MUTATION = gql`
  mutation PostMutation(
    $accessToken: String!
    $userId: String!
    $content: String
  ) {
    testPost(
      postInput: {
        accessToken: $accessToken
        userId: $userId
        content: $content
      }
    ) {
      response
    }
  }
`;

const SEND_INVITATION = gql`
  mutation SendInvitationMutation($email: String!) {
    sendGroupInvitation(email: $email)
  }
`;

const Container = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
`;
const InnerContainer = styled.div`
  justify-content: center;
  // background-color: lightblue;
  margin: 30px;
  height: 60px;
  align-content: center;
  align-items: center;
  display: flex;
`;

const Grid = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: auto auto;
  align-self: center;
  margin: auto 100px;
  border: 1px solid black;
`;
const InnerGrid = styled.div`
  display: inline-grid;
  justify-content: center;
  padding: 5px;
`;
// const Input = styled.input`
//   display: block;
//   margin: 10px auto;
//   align-self: center;
//   width: 300px;
//   height: 30px;
// `;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   margin-top: 50px;
// `;
const Text = styled.p`
  align-self: center;
  color: black;
  display: flex;
`;

const VerifiedText = styled.p`
  align-self: center;
  color: green;
  display: flex;
`;

const NotVerifiedText = styled.p`
  align-self: center;
  color: red;
  display: flex;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const races = [
  "American Indian/Alaska Native",
  "Asian",
  "Black or African American",
  "Hispanic or Latinx",
  "Native Hawaiian or other Pacific Islander",
  "White",
];

const ProfileSkeleton = () => {
  return (
    <Box>
      <Container>
        {/* Skeleton for the header */}
        <Skeleton variant="text" width={500} height={50} />

        {/* Skeleton for the avatar */}
        <Skeleton variant="circular" width={100} height={100} />

        {/* Skeleton for the input sections */}
        <Container>
          <Skeleton variant="text" width={250} height={40} />
          <Skeleton variant="text" width={250} height={40} />
          <Skeleton variant="text" width={250} height={40} />
        </Container>
        <Container>
          <Skeleton variant="text" width={250} height={40} />
          <Skeleton variant="text" width={250} height={40} />
          <Skeleton variant="text" width={250} height={40} />
        </Container>
        <Container>
          <Skeleton variant="text" width={250} height={40} />
          <Skeleton variant="text" width={250} height={40} />
          <Skeleton variant="text" width={250} height={40} />
        </Container>
      </Container>
    </Box>
  );
};

const ManageProfile = () => {
  const inputRef = useRef(null);
  const [country, setCountry] = useState("us");

  const {
    user,
    instaProfileData,
    setInstaProfileData,
    facebookUserAccessToken,
    setFacebookUserAccessToken,
  } = useUserContext();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [invitee, setInvitee] = useState("");
  const [accountType, setAccountType] = useState(user?.dhiAccountType || null);
  const [interestedConditions, setInterestedConditions] = useState(
    user?.interestedConditions
  );
  const [city, setCity] = useState(user?.city || "");
  const [conditions, setConditions] = useState([]);
  const [dob, setDob] = useState(user?.dateOfBirth || "");
  const [sex, setSex] = useState(user?.gender || "");
  const [raceEthnicity, setRaceEthnicity] = useState(user?.raceEthnicity || []);
  const [image, setImage] = useState(user?.avatar || null);
  const [imageFile, setImageFile] = useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [showSuggestButton, setShowSuggestButton] = React.useState(false);

  const [imageUrl, setImageUrl] = useState("");
  const [postCaption, setPostCaption] = useState("");
  const [isSharingPost, setIsSharingPost] = useState(false);
  const [convertToken] = useMutation(CONVERT_TOKEN_MUTATION);
  const [convertTokenIG] = useMutation(CONVERT_TOKEN_IG_MUTATION, {
    refetchQueries: [{ query: GET_INSTAGRAM_PROFILE }],
  });
  // const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("");

  /* --------------------------------------------------------
   *                      FACEBOOK LOGIN
   * --------------------------------------------------------
   */

  // Check if the user is authenticated with Facebook
  useEffect(() => {
    window.FB.getLoginStatus((response) => {
      setFacebookUserAccessToken(response.authResponse?.accessToken);
    });
  }, []);

  const logInToFB = () => {
    window.FB.login(
      (response) => {
        const shortLivedToken = response.authResponse?.accessToken;
        console.log("fb login response", response);

        if (shortLivedToken) {
          // Send the short-lived token to the backend to get a long-lived token
          convertToken({ variables: { shortLivedToken } })
            .then(({ data }) => {
              const longLivedToken = data.convertToken.longLivedToken;
              console.log("longLivedToken", longLivedToken);

              // Save the long-lived token to state
              setFacebookUserAccessToken(longLivedToken);
            })
            .catch((error) => {
              console.error("Error converting token:", error);
            });
        }
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope: "instagram_basic,pages_show_list",
      }
    );
  };

  const logInToIG = () => {
    window.FB.login(
      (response) => {
        const shortLivedToken = response.authResponse?.accessToken;
        console.log("fb login response", response);

        if (shortLivedToken) {
          // Send the short-lived token to the backend to get a long-lived token
          convertTokenIG({ variables: { shortLivedToken } })
            .then(({ data }) => {
              const longLivedToken = data.convertToken.longLivedToken;
              console.log("longLivedToken", longLivedToken);

              // Save the long-lived token to state
              // setFacebookUserAccessToken(longLivedToken);
            })
            .catch((error) => {
              console.error("Error converting token:", error);
            });
        }
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope: "instagram_basic,pages_show_list",
      }
    );
  };

  const logOutOfFB = () => {
    window.FB.logout(() => {
      setFacebookUserAccessToken(undefined);
    });
  };

  /* --------------------------------------------------------
   *             INSTAGRAM AND FACEBOOK GRAPH APIs
   * --------------------------------------------------------
   */

  const getFacebookPages = () => {
    return new Promise((resolve) => {
      window.FB.api(
        "me/accounts",
        { access_token: facebookUserAccessToken },
        (response) => {
          resolve(response.data);
        }
      );
    });
  };

  const getInstagramAccountId = (facebookPageId) => {
    return new Promise((resolve) => {
      window.FB.api(
        facebookPageId,
        {
          access_token: facebookUserAccessToken,
          fields: "instagram_business_account",
        },
        (response) => {
          resolve(response.instagram_business_account.id);
        }
      );
    });
  };

  const createMediaObjectContainer = (instagramAccountId) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          image_url: imageUrl,
          caption: postCaption,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  };

  const publishMediaObjectContainer = (
    instagramAccountId,
    mediaObjectContainerId
  ) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media_publish`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          creation_id: mediaObjectContainerId,
        },
        (response) => {
          resolve(response.id);
        }
      );
    });
  };

  const shareInstagramPost = async () => {
    setIsSharingPost(true);
    const facebookPages = await getFacebookPages();
    const instagramAccountId = await getInstagramAccountId(facebookPages[0].id);
    const mediaObjectContainerId = await createMediaObjectContainer(
      instagramAccountId
    );

    console.log("fb pages", facebookPages);
    console.log("instagram account id", instagramAccountId);
    console.log("media object container id", mediaObjectContainerId);

    await publishMediaObjectContainer(
      instagramAccountId,
      mediaObjectContainerId
    );

    setIsSharingPost(false);

    // Reset the form state
    setImageUrl("");
    setPostCaption("");
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE,
    onPlaceSelected: (place) => {
      console.log("place", place);
      setCity(place.formatted_address);
    },
    inputAutocompleteValue: "country",
    options: {
      componentRestrictions: { country },
    },
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImage(null);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "dob":
        setDob(value);
        break;
      case "sex":
        setSex(value);
        break;
      case "raceEthnicity":
        setRaceEthnicity(value);
        break;
      case "city":
        setCity(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (user) {
      setInterestedConditions(user.interestedConditions);
    } else {
      setInterestedConditions([]);
    }
  }, [user]);

  useEffect(() => {
    if (user && user?.avatar) {
      setImage(user.avatar);
    }
  }, [user]);

  const {
    data: instagramAuthData,
    loading: instagramAuthLoading,
    error: instagramAuthError,
  } = useQuery(AUTH_URL_QUERY, {
    skip: !user,
  });

  const { data: loginData } = useQuery(GET_LOGIN_URL, {
    skip: !user,
  });
  const [getAccessToken] = useMutation(GET_ACCESS_TOKEN, {
    skip: !user,
  });

  const { data: conditionsData } = useQuery(GET_CONDITIONS, {
    skip: !user,
  });

  const { data: instagramProfileData } = useQuery(GET_INSTAGRAM_PROFILE, {
    skip:
      !user && !user?.socialAuth.find((auth) => auth.platform === "Instagram"),
  });

  const [updateUser] = useMutation(UPDATE_USER);

  const [postMutation] = useMutation(POST_MUTATION, {
    variables: {
      userId: "100000000000000",
      accessToken: "EAADZBZCZBZBZBZA",
      content: "Hello World",
    },
  });

  const [sendInvitationMutation] = useMutation(SEND_INVITATION, {
    variables: {
      email: invitee,
    },
  });

  const [authenticateTwitter] = useMutation(AUTHENTICATE_TWITTER);

  const handleTikTokLogin = async () => {
    // Redirect user to TikTok login URL (obtained from GraphQL query)
    window.location.href = loginData.loginUrl;
  };

  const handleTikTokCallback = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      const { data } = await getAccessToken({ variables: { code } });
      // Store the access token securely
    }
  };

  const handleTwitterLogin = () => {
    authenticateTwitter().then((response) => {
      window.location.href = `${response.data.authenticateTwitter}?id=${user._id}`;
    });
  };

  console.log("instagramAuthData", instagramAuthData);
  const handleInstagramLogin = () => {
    window.location.href = instagramAuthData.InstagramAuthUrl;
  };

  const handleFBLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          try {
            const accessToken = response.authResponse.accessToken;

            postMutation({
              variables: {
                accessToken: accessToken,
                userId: user._id,
                content: "Hello World",
              },
            });
          } catch (e) {
            console.error("error in fb login", e);
          }
        }
      },
      { scope: "email,publish_actions", return_scopes: true }
    );
  };

  const onSelectedOptionsChange = (e) => {
    // console.log(
    //   "selected",
    //   JSON.parse(e.target.value),
    //   e.target.selectedOptions
    // );
    setAccountType(e.target.value);
  };

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setZipCode(user.zipCode);
      setSex(user?.gender);
      setDob(user?.dateOfBirth);
      setAccountType(user?.dhiAccountType);
      setRaceEthnicity(user?.raceEthnicity || []);
    }
  }, [user]);

  useEffect(() => {
    if (user) handleTikTokCallback();
  }, [user]);

  useEffect(() => {
    if (instagramProfileData) {
      setInstaProfileData(instagramProfileData?.fetchInstagramProfileInfo);
    }
  }, [instagramProfileData]);

  useEffect(() => {
    if (conditionsData) {
      setConditions(conditionsData.conditions);
    }
  }, [conditionsData]);

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("file", imageFile);

    const avatarUrl =
      process.env.NODE_ENV === "production"
        ? "/avatar"
        : "http://localhost:3001/avatar";
    try {
      const response = await axios.post(avatarUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      //fetch latest files and switch tabs

      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const saveProfile = async () => {
    if (!firstName || !lastName || !dob || !sex || !raceEthnicity.length) {
      toast.error("Please complete all required fields.");
      return;
    }

    console.log("raceEthnicity", raceEthnicity);
    if (imageFile) {
      const file = await uploadFile();

      if (file) {
        const res = await updateUser({
          variables: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            zipCode: zipCode,
            interestedConditions: interestedConditions,
            avatar: file,
            accountType: accountType,
            sex: sex,
            dob: dob,
            raceEthnicity: raceEthnicity,
            city: city,
          },
        });

        if (res.data.updateProfile) {
          toast.success("Profile updated successfully");
        } else {
          toast.error("Profile update failed");
        }
      }
    } else {
      const res = await updateUser({
        variables: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          zipCode: zipCode,
          interestedConditions: interestedConditions,
          accountType: accountType,
          sex: sex,
          dob: dob,
          raceEthnicity: raceEthnicity,
          city: city,
        },
      });

      if (res.data.updateProfile) {
        toast.success("Profile updated successfully");
      } else {
        toast.error("Profile update failed");
      }
    }
  };

  const handleMultiSelectChange = (event) => {
    const value = event.target.value;
    setRaceEthnicity(value);
  };

  const TiktokVerified = user?.socialAuth.find(
    (auth) => auth.platform === "Tiktok" && auth.verified
  );
  const InstagramVerified = user?.socialAuth.find(
    (auth) => auth.platform === "Instagram" && auth.verified
  );
  const TwitterVerified = user?.socialAuth.find(
    (auth) => auth.platform === "Twitter" && auth.verified
  );

  const handleConditionChange = (newInputValue) => {
    // Check if the new value exists in the conditions
    const exists = conditions.some(
      (condition) => condition.name === newInputValue
    );

    // If it doesn't exist, set a state variable to show the suggest button
    if (!exists) {
      setShowSuggestButton(true);
    } else {
      setShowSuggestButton(false);
      setInterestedConditions([...interestedConditions, newInputValue]);
    }

    // Update the state with the new value
  };

  const suggestCondition = () => {
    // Call the mutation to suggest the condition
    // This will be implemented in the next step
  };

  console.log(
    "instagram profile res",
    instagramProfileData?.fetchInstagramProfileInfo
  );

  if (!user) {
    return <ProfileSkeleton />;
  }

  return (
    <div className="App">
      <ToastContainer />
      <div
        className="App-body"
        style={{
          backgroundColor: "#fff",
          color: "#000",
          minHeight: "80vh",
        }}
      >
        <Card
          style={{
            width: "85%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <Container style={{ marginLeft: 150, height: 100 }}>
            <InnerContainer>
              <label>Invitee</label>
              <Input
                type={"text"}
                value={invitee}
                onChange={(e) => {
                  setInvitee(e.target.value);
                }}
              ></Input>
              <Input
                type={"button"}
                value="Send Invitation"
                onClick={sendInvitationMutation}
              ></Input>
            </InnerContainer>
          </Container> */}
            <Container>
              <div>
                <input
                  accept="image/*"
                  type="file"
                  id="icon-button-file"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    {image ? (
                      <Avatar
                        src={image}
                        alt="Profile"
                        style={{ width: 100, height: 100 }}
                      />
                    ) : (
                      <PersonOutlinedIcon
                        style={{
                          width: 100,
                          height: 100,
                        }}
                      />
                    )}
                  </IconButton>
                </label>
              </div>
              <Container className="row-container">
                <TextField
                  required
                  error={!firstName}
                  helperText={!firstName ? "First Name is required" : ""}
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  style={{ flex: 1 }}
                />

                <TextField
                  required
                  error={!lastName}
                  helperText={!lastName ? "Last Name is required" : ""}
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  style={{ flex: 1 }}
                />

                {/* <TextField
                id="outlined-basic"
                label="email"
                variant="outlined"
                value={email}
                disabled
                style={{ marginLeft: 20, width: "250px" }}

                // value={lastName}
                // onChange={(e) => {
                //   setLastName(e.target.value);
                // }}
              /> */}
              </Container>
              <Container className="row-container">
                <TextField
                  required
                  error={!dob}
                  helperText={!dob ? "Date of Birth is required" : ""}
                  id="outlined-basic"
                  label="Date of Birth"
                  type="date"
                  name="dob"
                  variant="outlined"
                  value={dob}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ flex: 1 }}
                />

                <TextField
                  required
                  error={!sex}
                  helperText={!sex ? "Sex is required" : ""}
                  id="outlined-basic"
                  label="Sex (Assigned at birth)"
                  select
                  name="sex"
                  variant="outlined"
                  value={sex}
                  onChange={handleInputChange}
                  SelectProps={{
                    native: true,
                  }}
                  style={{ flex: 1 }}
                >
                  <option value="">Select...</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </TextField>
              </Container>
              <Container className="row-container">
                <Form
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <FormControl
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      width: "100%",
                    }}
                  >
                    <InputLabel id="race-label" style={{ marginLeft: 20 }}>
                      Race/Ethnicity
                    </InputLabel>
                    <Select
                      required
                      error={!raceEthnicity?.length}
                      helperText={
                        !raceEthnicity?.length
                          ? "Race/Ethnicity is required"
                          : ""
                      }
                      labelId="race-label"
                      id="race"
                      variant="outlined"
                      multiple
                      value={raceEthnicity}
                      onChange={handleMultiSelectChange}
                      name="raceEthnicity"
                    >
                      {races.map((race) => (
                        <MenuItem key={race} value={race}>
                          {race}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Form>

                <TextField
                  fullWidth
                  name="city"
                  label="City"
                  color="secondary"
                  variant="outlined"
                  value={city}
                  inputRef={materialRef}
                  onChange={handleInputChange}
                  style={{ flex: 1 }}
                />
              </Container>

              <InputLabel id="account-type-label">Account Type</InputLabel>
              <Select
                fullWidth
                labelId="account-type-label"
                value={accountType}
                onChange={onSelectedOptionsChange}
                disabled={user?.dhiAccountType ? true : false}
              >
                <MenuItem value={"Individual"}>Individual</MenuItem>
                <MenuItem value={"Organization"}>Organization</MenuItem>
              </Select>

              {/* <label> Interested Conditions</label>
            <Typeahead
              conditions={conditions}
              interestedConditions={interestedConditions}
              setInterestedConditions={setInterestedConditions}
            /> */}
              <Autocomplete
                freeSolo
                multiple
                id="tags-outlined"
                options={conditions}
                value={interestedConditions}
                style={{ marginTop: 20 }}
                getOptionLabel={(option) => `${option.name}`}
                filterOptions={(options, params) => {
                  const filtered = params.inputValue
                    ? options.filter((option) =>
                        `${option.name}`
                          .toLowerCase()
                          .includes(params.inputValue.toLowerCase())
                      )
                    : options;

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Interested Conditions"
                    name="interestedConditions"
                    onChange={(event) => handleInputChange(event)}
                  />
                )}
                onChange={(event, newInputValue) => {
                  handleConditionChange(newInputValue);
                }}
              />

              {showSuggestButton && (
                <Button onClick={suggestCondition}>Suggest Condition</Button>
              )}
              {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {conditions &&
                conditions.map((condition, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <Input
                        type="checkbox"
                        id={condition.id}
                        name={condition.name}
                        value={condition.name}
                        // className="inline"
                        // style={{ margin: "0 0.5rem 0 0" }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setInterestedConditions([
                              ...interestedConditions,
                              e.target.value,
                            ]);
                          } else {
                            setInterestedConditions(
                              interestedConditions.filter(
                                (condition) => condition !== e.target.value
                              )
                            );
                          }
                        }}
                      />
                      <label htmlFor={condition.id}> {condition.name}</label>
                    </div>
                  );
                })}
            </div> */}
              <InnerContainer>
                <label
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  Social Verification
                </label>
              </InnerContainer>
              <InnerGrid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="social-login-container"
                    style={{
                      opacity: disabled ? 0.5 : 1,
                      pointerEvents: disabled ? "none" : "auto",
                    }}
                    onClick={disabled ? null : logInToFB}
                  >
                    <div
                      style={{
                        padding: "20px",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                        backgroundColor: "#3b5998",
                        marginRight: "20px",
                        borderRadius: 25,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={["fab", "facebook"]}
                        style={{
                          alignSelf: "center",
                          fontSize: "45px",
                          color: "#fff",
                        }}
                      />
                    </div>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Text>Facebook</Text>
                      <Text>
                        Connect your Facebook account to publish posts
                      </Text>
                    </Box>
                    <Button
                      style={
                        facebookUserAccessToken
                          ? { backgroundColor: "#08875D", color: "white" }
                          : { backgroundColor: "#009EC5", color: "white" }
                      }
                      onClick={logInToFB}
                    >
                      {facebookUserAccessToken ? "Connected " : "Connect"}
                    </Button>
                  </div>
                  {facebookUserAccessToken ? (
                    <VerifiedText></VerifiedText>
                  ) : (
                    <NotVerifiedText></NotVerifiedText>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="social-login-container"
                    onClick={handleTikTokLogin}
                  >
                    <div
                      style={{
                        padding: "20px",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                        backgroundColor: "#000",
                        marginRight: "20px",
                        borderRadius: 25,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={["fab", "tiktok"]}
                        style={{
                          alignSelf: "center",
                          fontSize: "45px",
                          color: "#fff",
                        }}
                      />
                    </div>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Text>TikTok</Text>
                      <Text>Connect your TikTok account to publish posts</Text>
                    </Box>
                    <Button
                      style={
                        TiktokVerified
                          ? { backgroundColor: "#08875D", color: "white" }
                          : { backgroundColor: "#009EC5", color: "white" }
                      }
                      onClick={handleTikTokLogin}
                    >
                      {TiktokVerified ? "Connected " : "Connect"}
                    </Button>
                  </div>
                  {TiktokVerified ? (
                    <VerifiedText></VerifiedText>
                  ) : (
                    <NotVerifiedText></NotVerifiedText>
                  )}
                </div>
              </InnerGrid>
              <InnerGrid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="social-login-container"
                    onClick={handleTwitterLogin}
                  >
                    <div
                      style={{
                        padding: "20px",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                        backgroundColor: "#1DA1F2",
                        marginRight: "20px",
                        borderRadius: 25,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={["fab", "twitter"]}
                        style={{
                          alignSelf: "center",
                          fontSize: "45px",
                          color: "#fff",
                        }}
                      />
                    </div>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Text>Twitter</Text>
                      <Text>
                        Connect your Twitter account to publish tweets
                      </Text>
                    </Box>
                    <Button
                      style={
                        TwitterVerified
                          ? { backgroundColor: "#08875D", color: "white" }
                          : { backgroundColor: "#009EC5", color: "white" }
                      }
                      onClick={handleTwitterLogin}
                    >
                      {TwitterVerified ? "Connected " : "Connect"}
                    </Button>
                  </div>
                  {TwitterVerified ? (
                    <VerifiedText></VerifiedText>
                  ) : (
                    <NotVerifiedText></NotVerifiedText>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="social-login-container"
                    // onClick={handleInstagramLogin}
                    // onClick={logInToIG}
                  >
                    <div
                      style={{
                        padding: "20px",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                        background:
                          "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                        marginRight: "20px",
                        borderRadius: 25,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={["fab", "instagram"]}
                        style={{
                          alignSelf: "center",
                          fontSize: "45px",
                          color: "#fff",
                        }}
                      />
                    </div>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Text>Instagram</Text>
                      <Text>
                        {InstagramVerified && instaProfileData
                          ? `${instaProfileData.username} is connected to publish posts.`
                          : "Connect your Instagram Business account to publish posts"}
                      </Text>
                    </Box>
                    <Button
                      style={
                        InstagramVerified
                          ? { backgroundColor: "#08875D", color: "white" }
                          : { backgroundColor: "#009EC5", color: "white" }
                      }
                      // onClick={handleInstagramLogin}
                      onClick={logInToIG}
                    >
                      {InstagramVerified ? "Connected " : "Connect"}
                    </Button>
                  </div>
                  {InstagramVerified ? (
                    <VerifiedText></VerifiedText>
                  ) : (
                    <NotVerifiedText></NotVerifiedText>
                  )}
                </div>
              </InnerGrid>
            </Container>
            <Button
              style={{
                marginTop: 20,
                width: 150,
                backgroundColor: "#009EC5",
                borderColor: "#009EC5",
              }}
              onClick={() => {
                saveProfile();
              }}
            >
              Save Profile
            </Button>

            {/* <main id="app-main">
              <section className="app-section">
                <h3>1. Log in with Facebook</h3>
                {facebookUserAccessToken ? (
                  <button onClick={logOutOfFB} className="btn action-btn">
                    Log out of Facebook
                  </button>
                ) : (
                  <button onClick={logInToFB} className="btn action-btn">
                    Login with Facebook
                  </button>
                )}
              </section>
              {facebookUserAccessToken ? (
                <section className="app-section">
                  <h3>2. Send a post to Instagram</h3>
                  <input
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    placeholder="Enter a JPEG image url..."
                  />
                  <textarea
                    value={postCaption}
                    onChange={(e) => setPostCaption(e.target.value)}
                    placeholder="Write a caption..."
                    rows="10"
                  />
                  <button
                    onClick={shareInstagramPost}
                    className="btn action-btn"
                    disabled={isSharingPost || !imageUrl}
                  >
                    {isSharingPost ? "Sharing..." : "Share"}
                  </button>
                </section>
              ) : null}
            </main> */}
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default ManageProfile;
