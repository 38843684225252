import React from "react";
import { PartnerHeader } from "./Menus";

const PartnerContainer = ({ children }) => {
  return (
    <div className="partner-container">
      <PartnerHeader />
      {/* Add your content here */}
      {children}
    </div>
  );
};

export default PartnerContainer;
