import React from "react";
import ReactPlayer from "react-player";
// import OnboardingChecklist from "./OnboardingChecklist";
// Import other layout components like Header, Footer, etc.
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import { useUserContext } from "../providers/UserContext";
import { DHIHeader } from "./Menus";

const questions = [
  {
    question:
      "What actions and content are deemed unaccepted? (Select all that apply)",
    options: [
      "Vulgar content (Images, Language, Handles, and comments)",
      "Explicit content",
      "Illegal activities or implied illegal actions",
      "Nudity or sexual content",
      "Behaving in a manner that elicits reprimand from the platform in use",
      "Sharing content that may be slander or libel",
      "Sharing false information under the guise of fact",
      "None of the above",
    ],
  },
  {
    question:
      "What actions will NOT result in a breach of contract? (Select all that apply)",
    options: [
      "Allowing CliniSpan Health Inc. to post on agreed upon platforms on your behalf.",
      "Allowing analytics reviews on an ongoing basis.",
      "Adhering to the signed NDA with CliniSpan Health Inc.",
      "Adhering to the HIPAA laws.",
      "None of the above.",
    ],
  },
  {
    question:
      "What are potential consequences of breaches in contract compliance? (Select all that apply)?",
    options: [
      "An immediate performance review with the CliniSpan Health compliance team.",
      "A mandatory compliance course with the CliniSpan Health compliance team.",
      "A suspension from the influencer’s active campaign(s).",
      "Immediate termination of the influencer’s contract with CliniSpan Health Inc.",
      "Fines imposed on the influencer for the cost of legal proceedings resulting from their infraction.",
      "A freeze on the influencer’s financial account until breaches are resolved.",
      "None of the above",
    ],
  },
];

const TermsModal = ({ open, setOpen, setTermsAgreed, user }) => {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const text = `Independent Contractor Agreement
  BY AND BETWEEN
  CliniSpan Health, Inc. AND ${user?.firstName} ${user?.lastName} “Contractor”\n
  
  
  This Independent Contractor Agreement dated ${new Date().toDateString()} as amended (the “Contractor Agreement”), by and between CliniSpan Health, Inc. and ${
    user?.firstName
  } ${
    user?.lastName
  } (“Contractor”) is made as of this ${new Date().toDateString()}. \n
  
  Definitions and Interpretation \n
  
  In this Agreement, unless the context otherwise requires:\n
  
   Agreement" means this contract, including all its Schedules, Exhibits, and Attachments, which are incorporated herein by reference
  "Party" and "Parties" mean the Business and the Contractor, individually or collectively, as the context requires. 
  CliniSpan Health Inc may be referred to as CliniSpan Health as the context requires. 
  "Confidential Information" includes all information disclosed by the Parties in connection with this Agreement, whether in writing, orally, or by inspection of tangible objects.
  "Deliverables" means the products, services, and any other outcomes to be delivered by the Contractor to the Business as described within this contract.
  "Intellectual Property Rights" means patents, trademarks, service marks, copyrights, trade secrets, and any other proprietary rights recognized by law.\n
  
  
  Recitals \n
  
    Whereas, the parties will enter into this Contractor Agreement, which establishes an agreement between each party with respect to services performed by Contractor for CliniSpan Health, Inc. 
  
    Now, therefore, the parties agree as follows:\n
  
  
  
  
  In Witness Whereof, the parties have executed this agreement by their representative duly authorized officers. 
  Contractor Agreement\n
  
  
  SERVICES PROVIDED\n
  
  Contractor will be working with the CliniSpan Health Marketing Team to produce and promote social media content via their personal and business social media accounts.
  Contractor will create and recreate content, as well as assist with any content creation needed by CliniSpan Health Marketing Team for a specific campaign the contractor may have selected and/or activated.
  Provide access for management, auditing and analytics for all social media content posted through the CliniSpan Health platform.\n
  
  TIMELINE\n
  The services will be performed in accordance with the timeline and delivery schedule outlined in the campaign(s) briefing.\n
  
  PAYMENT AND FEES\n
  Delivery: Payment will be made on the last day of each month with the exception of the last day falling on a weekend or holiday, in which payment will be received the Friday before. All payments will be delivered via direct deposit using Gusto.
  Pay rate per view: $.01.\n
  
  TERM\n
  
  This agreement shall be effective on the date of signing this Agreement (hereinafter referred to as the “Effective Date”. It will end at the discretion of either CliniSpan Health or the Contractor. A written notice of contract termination must be received within seven days of the termination, however termination can be effective immediately.\n
  
  OWNERSHIP \n
  
  The parties agree that all products created by the contractor for the purposes of any campaign operated on for CliniSpan Health will remain the exclusive property of CliniSpan Health, Inc. \n
  
  INTELLECTUAL PROPERTY\n
  
  The contractor agrees that any intellectual property provided to him/her by the CliniSpan Health Inc. will remain the sole property of CliniSpan Health Inc., including (but not limited to) ideas, concepts, techniques, inventions, processes, works of authorship, confidential information, or trade secrets.
  The contractor will refrain from using such intellectual property upon the termination of this agreement.\n
  
  INDEMNIFICATION\n
  
  Contractor agrees to hold harmless and indemnify CliniSpan Health from any and all liability arising out of Contractor's negligence, whether it be sole or in concert with others, in connection with performance of the services described herein.

  `;

  const escapedText = text.replace(/"/g, '\\"').replace(/'/g, "\\'");

  const htmlText = text.replace(/\n/g, "<br />");

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Terms and Conditions"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br />") }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button
            onClick={() => {
              setTermsAgreed(true);
              setOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const VideoModal = ({ open, setOpen, setVideoWatched }) => {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Watch Video"}</DialogTitle>
        <DialogContent>
          <ReactPlayer
            url="https://csh-content-library.s3.amazonaws.com/platformUploads/dhi+training"
            playing
            controls
            onStart={() => {
              console.log("Video started");
            }}
            config={{
              youtube: {
                playerVars: { autoplay: 0, rel: 0 },
              },
            }}
            onEnded={() => {
              console.log("Video ended");
              setVideoWatched(true);
              //update checklist step
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const QuizModal = ({
  open,
  setOpen,
  quizAnswers,
  setQuizAnswers,
  submitQuiz,
}) => {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (event, questionIndex, optionIndex) => {
    setQuizAnswers((prevAnswers) => {
      // Copy the previous answers
      const newAnswers = { ...prevAnswers };

      // Initialize the options array for the question if it doesn't exist
      if (!newAnswers[questionIndex]) {
        newAnswers[questionIndex] = {
          options: [],
          question: questions[questionIndex].question,
        };
      }

      const optionText = questions[questionIndex].options[optionIndex];

      console.log(
        "optionText",
        optionText,
        questions,
        questions[questionIndex]
      );

      // If the checkbox is checked, add the option to the options array
      if (event.target.checked) {
        newAnswers[questionIndex].options =
          newAnswers[questionIndex].options.concat(optionText);
      } else {
        // If the checkbox is not checked, remove the option from the options array
        newAnswers[questionIndex].options = newAnswers[
          questionIndex
        ].options.filter((option) => option !== optionText);
      }

      return newAnswers;
    });
  };

  console.log("quizAnswers", quizAnswers);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Take a Quiz"}</DialogTitle>
        <DialogContent>
          {questions.map((question, questionIndex) => (
            <div key={questionIndex}>
              <DialogContentText id="alert-dialog-description">
                {question.question}
              </DialogContentText>
              {question.options.map((option, optionIndex) => (
                <FormControlLabel
                  key={optionIndex}
                  control={
                    <Checkbox
                      checked={
                        quizAnswers[questionIndex]
                          ? quizAnswers[questionIndex].options.includes(option)
                          : false
                      }
                      onChange={(event) =>
                        handleCheckboxChange(event, questionIndex, optionIndex)
                      }
                    />
                  }
                  label={option}
                />
              ))}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              const quizOptionsArray = Object.keys(quizAnswers).map((key) => ({
                question: quizAnswers[key].question,
                options: quizAnswers[key].options,
              }));
              submitQuiz(quizOptionsArray);
              setOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Layout = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);
  const [videoWatched, setVideoWatched] = React.useState(false);
  const [termsAgreed, setTermsAgreed] = React.useState(false);
  const [openQuiz, setOpenQuiz] = React.useState(false);
  const [quizAnswers, setQuizAnswers] = React.useState([]);
  const [quizSubmitted, setQuizSubmitted] = React.useState(false);
  const [onboardingProgress, setOnboardingProgress] = React.useState({
    completed: false,
    steps: [],
  });

  const { user, updateUserOnboardingProgress } = useUserContext();

  React.useEffect(() => {
    if (user && user.onboardingProgress) {
      setOnboardingProgress(user.onboardingProgress);
    }
  }, [user]);

  React.useEffect(() => {
    if (termsAgreed) {
      updateUserOnboardingProgress({
        variables: {
          termsAgreed: termsAgreed,
        },
      });
    }
  }, [termsAgreed]);

  React.useEffect(() => {
    if (videoWatched) {
      updateUserOnboardingProgress({
        variables: {
          videoWatched: videoWatched,
        },
      });
    }
  }, [videoWatched]);

  // When the user completes an onboarding step
  // updateUserOnboardingProgress({
  //   variables: {
  //     videoWatched: videoWatched, // or false
  //     termsAgreed: termsAgreed, // or false
  //     quizAnswers: ["answer1", "answer2", "answer3"], // replace with the actual answers
  //   },
  // });

  const submitQuiz = (answers) => {
    updateUserOnboardingProgress({
      variables: {
        quizAnswers: answers,
      },
    });
  };

  return (
    <div>
      <DHIHeader />
      <TermsModal
        open={open}
        setOpen={setOpen}
        setTermsAgreed={setTermsAgreed}
        setOnboardingProgress={setOnboardingProgress}
        user={user}
      />
      <VideoModal
        open={openVideo}
        setOpen={setOpenVideo}
        setVideoWatched={setVideoWatched}
        setOnboardingProgress={setOnboardingProgress}
      />
      <QuizModal
        open={openQuiz}
        setOpen={setOpenQuiz}
        setQuizAnswers={setQuizAnswers}
        quizAnswers={quizAnswers}
        setOnboardingProgress={setOnboardingProgress}
        submitQuiz={submitQuiz}
      />
      {children}

      {/* <OnboardingChecklist
        openTerms={open}
        setOpenTerms={setOpen}
        openVideo={openVideo}
        setOpenVideo={setOpenVideo}
        openQuiz={openQuiz}
        setOpenQuiz={setOpenQuiz}
        onboardingProgress={onboardingProgress}
      /> */}
    </div>
  );
};

export default Layout;
