import { gql, useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import "../../App.css";
import studyImg from "../../assets/study.png";
import { PatientHeader } from "../../components/Menus";
import { useUserContext } from "../../providers/UserContext";
import { truncateText } from "../../utilities/format";

const Container = styled.div`
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

const STUDY_QUERY = gql`
  query GetStudies($filter: String, $sortBy: String, $limit: Int, $offset: Int) {
    studies(filter: $filter, sortBy: $sortBy, limit: $limit, offset: $offset) {
      title
      nctNumber
      condition
      intervention
      location {
        facility
        city
        state
        country
      }
      description
      startDate
      studyType
      eligibilityCriteria
      formId {
        name
        id
      }
    }
    totalCount
  }
`;

const STUDIES_PER_PAGE = 6;

function BrowseStudies() {
  const { user } = useUserContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [studies, setStudies] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { loading, error, data } = useQuery(STUDY_QUERY, {
    variables: { filter, sortBy, limit: STUDIES_PER_PAGE, offset: (currentPage - 1) * STUDIES_PER_PAGE },
    skip: !user,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && data) {
      setStudies(data.studies);
      setTotalCount(data.totalCount);
    }
  }, [loading, data]);

  const totalPages = Math.ceil(totalCount / STUDIES_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="App">
      <PatientHeader title="Browse Studies" />
      <div
        style={{
          backgroundColor: "#fff",
          minHeight: "90vh",
          paddingTop: 10,
          marginRight: 50,
          marginLeft: 50,
        }}
      >
        <FloatingLabel
          controlId="floatingInput"
          label="Search"
          className="mb-3"
          style={{ width: "70%", margin: "10px auto" }}
          onChange={(e) => setFilter(e.target.value)}
        >
          <Form.Control placeholder="" />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Sort"
          className="mb-3"
          style={{ width: "70%", margin: "10px auto" }}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <Form.Control placeholder="" />
        </FloatingLabel>

        <div className="study-grid">
          {studies.map((study, index) => (
            <Container
              key={index}
              className="study-card"
              onClick={() => {
                navigate("study/" + study.nctNumber, {
                  state: { study: study },
                });
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                }}
              >
                <img
                  src={studyImg}
                  alt={study.title}
                  style={{ height: "100%", width: "100%" }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                  padding: 10,
                  justifyContent: "center",
                  textAlign: "left",
                }}
              >
                <h6 style={{ color: "#032E5E" }}>{study.title}</h6>
                <p style={{ color: "#032E5E" }}>{`${study?.location?.city || ""}, ${
                  study?.location?.state || study?.location?.country || ""
                }`}</p>
                <p style={{ color: "#032E5E" }}>{study.description && truncateText(study.description, 250)}</p>
              </Box>
            </Container>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} >
              First
            </Pagination.First>
            <Pagination.Prev
              onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
            >
              Prev
            </Pagination.Prev>
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                handlePageChange(Math.min(totalPages, currentPage + 1))
              }
            >
              Next
            </Pagination.Next>
            <Pagination.Last onClick={() => handlePageChange(totalPages)} >
              Last
            </Pagination.Last>
          </Pagination>
        </div>
      </div>
    </div>
  );
}

export default BrowseStudies;