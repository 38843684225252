import { gql, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Braintree, HostedField } from "react-braintree-fields";

const SUBSCRIPTION_TIERS = [
  {
    id: 1,
    name: "Basic",
    price: 0,
    pay: { views: 0.01, submissions: 10 },
    features: [],
  },
  {
    id: 2,
    name: "Premium",
    price: 30,
    pay: { views: 0.012, submissions: 15 },
    features: [
      "Get added to newsletter and other distribution channels",
      "Get access to educational content",
    ],
  },
  // ... other tiers
];

const CREATE_PAYMENT_TOKEN_MUTATION = gql`
  mutation {
    createPaymentToken
  }
`;

const PROCESS_PAYMENT_MUTATION = gql`
  mutation ProcessPayment($nonce: String!, $amount: Float!) {
    processPayment(nonce: $nonce, amount: $amount)
  }
`;

export default function Subscription() {
  const [selectedTier, setSelectedTier] = useState(null);
  const [clientToken, setClientToken] = useState(null);

  // GraphQL mutations
  const [createToken] = useMutation(CREATE_PAYMENT_TOKEN_MUTATION);
  const [processPayment] = useMutation(PROCESS_PAYMENT_MUTATION);

  useEffect(() => {
    // Fetch the client token on component mount
    const fetchClientToken = async () => {
      const { data } = await createToken();
      setClientToken(data.createPaymentToken);
    };
    fetchClientToken();
  }, [createToken]);

  const handlePaymentMethod = async (nonce) => {
    // Process the payment with the selected subscription price
    await processPayment({ variables: { nonce, amount: selectedTier.price } });
    // Handle post-payment logic (e.g., update user's subscription status)
  };

  console.log("clientToken", clientToken);

  const getTierColor = (tierName) => {
    switch (tierName.toLowerCase()) {
      case "basic":
        return "Blue";
      case "silver":
        return "#aaa9ad";
      case "gold":
        return "#A0B2C6";
      default:
        return "Red";
    }
  };

  const selectTier = (tier) => {
    setSelectedTier(tier);
  };

  return (
    <div>
      <div className="tier-cards" style={{ marginTop: 20 }}>
        {SUBSCRIPTION_TIERS.map((tier, index) => (
          <div
            key={index}
            className={`tier-card ${
              selectedTier?.name === tier.name ? "selected" : ""
            }`}
            onClick={() => selectTier(tier)}
            style={{ borderColor: getTierColor(tier.name) }}
          >
            <h3 style={{ backgroundColor: getTierColor(tier.name) }}>
              {`${tier.name} \n Tier`}
            </h3>
            <hr />
            <p>Price: ${tier.price}/month</p>
            <hr />
            <p>${tier.pay.views * 100}/100 Views</p>
            <p> ${tier.pay.submissions * 100} /100 Submissions</p>
            <hr />
            <ul>
              {tier.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "90%" }}>
          {clientToken && selectedTier && selectedTier.id !== 1 && (
            <Braintree
              authorization={clientToken}
              onPaymentMethodReceived={handlePaymentMethod}
              styles={{
                input: {
                  "font-size": "inherit",
                },
                ":focus": {
                  color: "blue",
                },
              }}
            >
              <>
                Number:
                <HostedField type="number" prefill="4111 1111 1111 1111" />
                Namme:
                <HostedField
                  type="cardholderName"
                  // className={'cardholderName' === focusedFieldName ? 'focused' : ''}
                  // onBlur={onFieldBlur}
                  // onFocus={onFieldFocus}
                  placeholder="Name on Card"
                  // ref={cardholderNameField}
                />
                Expiration Date:
                <HostedField type="expirationDate" />
                Cvv:
                <HostedField type="cvv" />
                <button type="submit">Pay ${selectedTier.price}</button>
              </>
            </Braintree>
          )}
        </div>
      </div>
    </div>
  );
}
