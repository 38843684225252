import { gql, useMutation, useQuery } from "@apollo/client";
import { Add, Visibility, Pause } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Card } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PartnerHeader } from "../../components/Menus";
import TablePaginationActions from "../../components/TablePaginationActions";
import { useUserContext } from "../../providers/UserContext";

const HiddenInput = styled("input")({
  display: "none",
});

const rows = [
  // Add sample data rows here
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Denied",
    participants: 146,
  },
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Approved",
    participants: "--",
  },
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Pending",
    participants: "--",
  },
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Created",
    participants: "--",
  },
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Pending",
    participants: "--",
  },
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Ended",
    participants: "--",
  },
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Approved",
    participants: "--",
  },
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Denied",
    participants: "--",
  },
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Pending",
    participants: 146,
  },
  {
    id: "NCT06429293",
    title: "Impact of Cognitive Behavioral Therapy on PTSD-CVD Link",
    approvalStatus: "Created",
    participants: 146,
  },
];

const statusColors = {
  Denied: "error",
  Approved: "success",
  Pending: "warning",
  Created: "default",
  Ended: "error",
};

const STUDY_SEARCH_MUTATION = gql`
  mutation GetStudies($nctNumber: String!) {
    studySearch(nctNumber: $nctNumber) {
      title
      nctNumber
      description
      approvalStatus
    }
  }
`;

const STUDY_QUERY = gql`
  query GetStudies {
    myStudies {
      id
      title
      nctNumber
      active
      approvalStatus
      campaignIds
      formId {
        id
        name
        fields {
          id
          label
          type
          required
          options
        }
      }
      participants
      influencers
    }
  }
`;

const CLOSE_CAMPAIGN_MUTATION = gql`
  mutation CloseCampaign($id: ID!) {
    closeCampaign(id: $id) {
      id
      status
    }
  }
`;

const ADD_STUDY_MUTATION = gql`
  mutation AddStudy(
    $nctNumber: String
    $name: String!
    $startDate: String!
    $endDate: String!
    $description: String
  ) {
    addStudy(
      nctNumber: $nctNumber
      startDate: $startDate
      endDate: $endDate
      name: $name
      description: $description
    ) {
      title
    }
  }
`;

const FETCH_REMAINING_INTERACTIONS = gql`
  query FetchRemainingInteractions {
    remainingInteractions
  }
`;

const Image = styled.img`
  height: 100px;
  width: 300px;
  margin-top: 5px;
`;

const Container = styled.div`
  background-color: ${(props) => props.background || "grey"};
  height: 60px;
  width: 80%;
  margin: 10px 0;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;
const InnerContainer = styled.div`
  margin-top: 80px;
  justify-content: center;
`;
const Input = styled.input`
  margin: 10px auto;
  align-self: center;
  width: 400px;
  height: 30px;
`;
// const Button = styled.button`
//   background-color: blue;
//   border-radius: 10px;
//   height: 50px;
//   width: 100px;
//   color: #fff;
// `;
const Text = styled.p`
  color: #000;
  text-align: left;
`;

const StyledLink = styled(NavLink)`
  margin-right: 15px;
  margin-left: 15px;
  color: #fff;
  text-decoration: none;
  &.${(props) => props.activeClassName} {
    color: blue;
  }
  &:hover {
    color: lightblue;
  }
`;

const Row = styled.div`
  display: flex;
  felx-diretion: row;
  justify-content: space-between;
`;

const items = [
  {
    name: "Asthma Trial",
    nctNumber: "12341231",
    participants: 10,
    active: true,
  },
  {
    name: "COVID Trial",
    nctNumber: "12341231",
    participants: 20,
    active: false,
  },
  {
    name: "Diabetes Trial",
    nctNumber: "12341231",
    participants: 30,
    active: true,
  },
];

function ListItem({ item, setStudies }) {
  const navigate = useNavigate();

  const [closeCampaign, { loading, error }] = useMutation(
    CLOSE_CAMPAIGN_MUTATION
  );

  // const [toggleActive] = useMutation(gql`
  //   mutation ToggleActive($id: ID!) {
  //     toggleActive(id: $id) {
  //       id
  //       title
  //       nctNumber
  //       active
  //     }
  //   }
  // `);

  const toggleActive = (item) => {
    // toggleActive({ variables: { id: item.id } });
    let temp = { ...item };
    temp.active = !item.active;
    setStudies([temp]);
  };

  const handleCampaignClose = async (id) => {
    const response = await closeCampaign({ variables: { id: id } });
    console.log("response", response);
    if (response?.data?.closeCampaign?.status === "Completed") {
      // display success toast
    } else {
      // display error toast
    }
  };

  console.log("item", item);

  return (
    <Card title={item.title} style={{ marginTop: 15, marginBottom: 15 }}>
      {/* Show more info if the study has an active campaign */}
      {/* otherwise show a create campagin button that prompts the user to create a form and configure their campaign */}
      {item?.approvalStatus === "Approved" && (
        <Button variant="warning" onClick={() => handleCampaignClose(item.id)}>
          End Campaign
        </Button>
      )}
      <Button
        variant="info"
        style={{ marginLeft: 700 }}
        onClick={() =>
          navigate(`details/${item.id}`, {
            state: item,
          })
        }
      >
        More Info
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <p>NCT Number: {item.nctNumber}</p>
        <p>Participants: {item.participants || 0}</p>
        <p
          style={{ color: item.active ? "green" : "red" }}
          // onClick={() => toggleActive(item)}
          onClick={() => null}
        >
          {item.active ? "Active" : "Inactive"}
        </p>
      </div>
    </Card>
  );
}

function ConfirmationModal(props) {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleDelete = () => {
    props.handleDelete();
    handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Addition</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to add this study?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleDelete}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function UploadModal(props) {
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleDelete = () => {
    props.handleDelete();
    handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Study Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Search bar and search button on the same row */}
          {/* if there is a search result, display the results */}
          {/* if there is no search result, display a message saying no results found */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <TextField
              value={props.nctNumber}
              onChange={(e) => {
                props.setNctNumber(e.target.value);
              }}
              label="Search By NCT#"
              style={{
                width: props.isMobile ? "100%" : "500px",
                backgroundColor: "#F8F9FC",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                title="Search"
                onClick={props.onSearchClick}
                style={{
                  width: 100,
                  backgroundColor: "#009EC5",
                  borderColor: "#009EC5",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                Search
              </Button>
              <Button
                style={{ backgroundColor: "#009EC5", borderColor: "#009EC5" }}
                onClick={() => props.handleDelete()}
              >
                <Add />
              </Button>
            </Box>
          </div>
          {props.searchResult ? (
            <Card
              // title={props.searchResult.title}
              style={{
                marginTop: 15,
                marginBottom: 15,
                backgroundColor: "#F8F9FC",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                  }}
                >
                  <p>
                    <b> {props.nctNumber} </b>
                  </p>
                  <p> {props.searchResult.title}</p>
                  <Chip
                    label="Inactive"
                    color="default"
                    sx={{
                      backgroundColor: "rgba(224, 45, 60, 0.1)",
                      color: "#E02D3C",
                      borderRadius: "16px",
                      padding: "0 8px",
                      fontWeight: "bold",
                      width: "50%",
                    }}
                  />
                </div>
                <Button
                  className="centerSelf"
                  style={{
                    backgroundColor: "rgba(0, 158, 197, 0.1)",
                    color: "#009EC5",
                  }}
                  onClick={() => props.handleDelete()}
                >
                  Add Study
                </Button>
              </div>
            </Card>
          ) : (
            <p></p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

function StudyDetailsModal(props) {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleClose = () => props.setShow(false);
  const handleShow = () => props.setShow(true);

  const handleSubmit = () => {
    props.handleSubmit();
    handleClose();
  };

  const handleButtonClick = () => {
    document.getElementById("upload-button").click();
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Provide Study Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box
            display="grid"
            gap="30px"
            // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {/* <TextField
              fullWidth
              variant="filled"
              type="text"
              label="First Name"
              // onBlur={handleBlur}
              // onChange={handleChange}
              value={"test"}
              name="firstName"
              // error={!!touched.firstName && !!errors.firstName}
              // helperText={touched.firstName && errors.firstName}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Last Name"
              // onBlur={handleBlur}
              // onChange={handleChange}
              value={"test2"}
              name="firstName"
              // error={!!touched.firstName && !!errors.firstName}
              // helperText={touched.firstName && errors.firstName}
              sx={{ gridColumn: "span 2" }}
            /> */}
            <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Study Name"
              // onBlur={handleBlur}
              onChange={(e) => props.setStudyName(e.target.value)}
              value={props.studyName}
              name="studyName"
              // error={!!touched.firstName && !!errors.firstName}
              // helperText={touched.firstName && errors.firstName}
              sx={{ gridColumn: "span 2" }}
            />
            <DateTimePicker
              label="Study Start Date"
              // defaultValue={moment(new Date())}
              onChange={(e) => props.setStartDate(e)}
              value={props.startDate}
            />
            <DateTimePicker
              label="Study End Date"
              // defaultValue={moment(new Date())}
              onChange={(e) => props.setEndDate(e)}
              value={props.endDate}
            />
            <TextField
              fullWidth
              variant="filled"
              type="text"
              multiline
              rows={4}
              label="Study Description"
              // onBlur={handleBlur}
              onChange={(e) => props.setStudyDescription(e.target.value)}
              value={props.studyDescription}
              name="studyDescription"
              // error={!!touched.firstName && !!errors.firstName}
              // helperText={touched.firstName && errors.firstName}
              sx={{ gridColumn: "span 2" }}
            />
            {/* <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{ width: "100%", gridColumn: "span 2" }}
            >
              <Typography variant="h6" marginBottom="16px">
                Upload Image
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding="20px"
                border="1px dashed #00B4D8"
                borderRadius="10px"
                width="100%"
                textAlign="center"
              >
                {props.image ? (
                  <img
                    src={props.image}
                    alt="Uploaded"
                    style={{
                      width: "100%",
                      height: "auto",
                      marginBottom: "16px",
                    }}
                  />
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="200px"
                    color="textSecondary"
                  >
                    <CloudUploadIcon
                      style={{ fontSize: 40, marginBottom: "16px" }}
                    />
                    <Typography variant="body2">
                      Select a file or drag and drop here
                    </Typography>
                    <Typography variant="body2">
                      JPG, PNG or PDF, file size no more than 10MB
                    </Typography>
                  </Box>
                )}
                <HiddenInput
                  accept="image/*"
                  id="upload-button"
                  type="file"
                  onChange={props.handleImageChange}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleButtonClick}
                >
                  SELECT FILE
                </Button>
              </Box>
            </Box> */}
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSubmit}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const PartnerStudies = () => {
  const navigate = useNavigate();
  const { user, getRemainingInteractions } = useUserContext();
  const [nctNumber, setNctNumber] = useState("");
  const [searchResult, setSearchResult] = useState();
  const [searchedNctNumber, setSearchedNctNumber] = useState();
  const [show, setShow] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [studies, setStudies] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [studyName, setStudyName] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [studyDescription, setStudyDescription] = useState("");
  const [studyImage, setStudyImage] = useState("");
  const [endDate, setEndDate] = useState(moment(new Date()));
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const [tabs, setTabs] = useState([
  //   { name: "Created", link: "/partner/studies/created" },
  //   { name: "Pending", link: "/partner/studies/pending" },
  //   { name: "Approved", link: "/partner/studies/approved" },
  //   { name: "Denied", link: "/partner/studies/denied" },
  // ]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabs = ["All", "Created", "Pending", "Approved", "Denied"];

  const [studySearch] = useMutation(STUDY_SEARCH_MUTATION, {
    variables: { nctNumber },
  });

  const [addStudy] = useMutation(ADD_STUDY_MUTATION, {
    variables: {
      nctNumber: searchedNctNumber,
      name: studyName,
      startDate,
      endDate,
      description: studyDescription,
      image: studyImage,
    },
    refetchQueries: [{ query: STUDY_QUERY }],
  });

  const { loading, error, data, refetch } = useQuery(STUDY_QUERY, {
    variables: {
      filter: {
        // approvalStatus: tabs[activeTab],
      },
      sortBy: "",
    },
    skip: !user,
  });

  const onSearchClick = async () => {
    setSearchedNctNumber(nctNumber);
    const searchData = await studySearch();
    setSearchResult(searchData?.data?.studySearch);
    if (searchData?.data?.studySearch) {
      // setShowDetails(true);
      setStudyDescription(searchData?.data?.studySearch?.description);
      setStudyName(searchData?.data?.studySearch?.title);
    }
  };

  useEffect(() => {
    if (!loading && data?.myStudies) {
      console.log("returned data", data);
      const currentTabStudies = data?.myStudies.filter((study) => {
        console.log("study", study.approvalStatus, tabs[activeTab]);
        if (activeTab === 0) return study;
        return study.approvalStatus === tabs[activeTab];
      });
      console.log("currentTabStudies", currentTabStudies);
      setStudies(currentTabStudies);
      // setStudies([...data.myStudies]);
    }
  }, [loading, data, activeTab]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch]);

  return (
    <div className="App">
      <PartnerHeader />
      <div className="App-body outerRow">
        <div className="container">
          <div className="flexColumn">
            <ConfirmationModal
              handleDelete={() => addStudy().then(() => setSearchResult())}
              show={show}
              setShow={setShow}
            />
            <UploadModal
              nctNumber={nctNumber}
              setNctNumber={setNctNumber}
              isMobile={isMobile}
              onSearchClick={onSearchClick}
              searchResult={searchResult}
              show={showUploadModal}
              setShow={setShowUploadModal}
              handleDelete={() => {
                setShowUploadModal(false);
                setShowDetails(true);
              }}
            />
            <StudyDetailsModal
              handleSubmit={() => setShow(true)}
              show={showDetails}
              setShow={setShowDetails}
              setEndDate={setEndDate}
              endDate={endDate}
              setStartDate={setStartDate}
              startDate={startDate}
              studyName={studyName}
              setStudyName={setStudyName}
              studyDescription={studyDescription}
              setStudyDescription={setStudyDescription}
              studyImage={studyImage}
              setStudyImage={setStudyImage}
            />
            <Row>
              <Typography variant="h5">Studies</Typography>
              <Box style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
               {
              user && user.roles.includes("Partner") && (
                <Box style={{
                  borderRadius: 10,
                  padding: 10,
                  backgroundColor: 'rgba(0,158,197, .1)',
                  marginRight: 10,
                  height: 40,
                  alignSelf: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  display: 'flex'
                }}>
                  <Typography style={{fontSize: 16, marginRight: 3}} variant="p">Interactions Remaining:</Typography>
                  <Typography variant="p" color={"#009EC5"} style={{fontSize: 16, fontWeight: 'bold'}}> {getRemainingInteractions()}</Typography>
                  </Box>
              )
            }
              <Button
                style={{ backgroundColor: "#009EC5" }}
                onClick={() => setShowUploadModal(true)}
              >
                Study Upload
              </Button>
              </Box>
            </Row>
            <Box sx={{ width: "45%", marginTop: 2, marginBottom: 2 }}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                variant="fullWidth"
                textColor="inherit"
                indicatorColor="primary"
                sx={{
                  backgroundColor: "rgba(3, 46, 94, 0.06)",
                  borderRadius: "20px",
                  textDecoration: "none",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab}
                    sx={{
                      color: "#000",
                      "&.Mui-selected": {
                        color: "#fff",
                        backgroundColor: "#009EC5",
                        textDecoration: "none",
                      },
                      borderRadius: "20px",
                    }}
                  />
                ))}
              </Tabs>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Paper>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead style={{ backgroundColor: "#F8F9FC" }}>
                      <TableRow>
                        {/* <TableCell padding="checkbox">
                          <Checkbox />
                        </TableCell> */}
                        <TableCell>ID</TableCell>
                        <TableCell>Study Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Participants</TableCell>
                        <TableCell>Pause Campaign</TableCell>
                        <TableCell>View</TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studies &&
                        studies
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <TableRow key={row.id}>
                              {/* <TableCell padding="checkbox">
                                <Checkbox />
                              </TableCell> */}
                              <TableCell>{row.id}</TableCell>
                              <TableCell>{row.title}</TableCell>
                              <TableCell>
                                {console.log("row", row, row.approvalStatus)}
                                <Chip
                                  label={row.approvalStatus}
                                  color={statusColors[row.approvalStatus]}
                                />
                              </TableCell>
                              <TableCell>{row.participants || 0}</TableCell>
                              {row.approvalStatus && row.approvalStatus === 'Approved' ? (<TableCell>
                                <IconButton>
                                  <Pause />
                                </IconButton>
                              </TableCell>): (
                                <TableCell></TableCell>
                              )}
                              <TableCell>
                                <IconButton
                                  onClick={() =>
                                    navigate(`details/${row.id}`, {
                                      state: row,
                                    })
                                  }
                                >
                                  <Visibility />
                                </IconButton>
                                {/* <IconButton>
                                <Delete />
                              </IconButton> */}
                              </TableCell>
                              
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {studies && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    padding="16px"
                  >
                    <Typography variant="body2">
                      Page {page + 1} of{" "}
                      {Math.ceil(studies.length / rowsPerPage)}
                    </Typography>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={studies.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions} // Use the custom pagination actions
                    />
                  </Box>
                )}
              </Paper>
            </Box>
            {/* <Container className="centerSelf" background={"lightblue"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignSelf: "center",
                }}
              >
                <Text>NCT# :</Text>
                <Input
                  type={"text"}
                  value={nctNumber}
                  onChange={(e) => {
                    setNctNumber(e.target.value);
                  }}
                />
                <Button
                  title="Search"
                  onClick={onSearchClick}
                  style={{ width: 100 }}
                >
                  Search
                </Button>
              </div>
            </Container> */}
            {/* {
              <div
                style={{
                  overflowY: "scroll",
                  maxHeight: 500,
                }}
              >
                {!searchResult &&
                  !loading &&
                  studies &&
                  studies.map((item) => (
                    <ListItem
                      key={item.nctNumber}
                      item={item}
                      setStudies={setStudies}
                    />
                  ))}

                {searchResult && (
                  <Card
                    title={searchResult.title}
                    style={{ marginTop: 15, marginBottom: 15 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <p>NCT Number: {nctNumber}</p>
                      <p>Participants: {item.participants}</p>
                      <p>Active: {"No"}</p>
                      <Button
                        variant="success"
                        className="centerSelf"
                        onClick={() => setShow(true)}
                      >
                        Add Study
                      </Button>
                    </div>
                  </Card>
                )}
              </div>
            } */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerStudies;
